import { ROUTES } from "controller/routes/routes";
import React from "react";
import { Link } from "react-router-dom";
import { WEEK_DAYS } from "scripts/Constants";
import SwipperComponent from "./SwipperComponent";
import { SwiperSlide } from "swiper/react";
import { unslug } from "scripts/utils/Utils";

function ScheduleSlider({ box, schedule }) {
  
  return (
    
    <div className="schedule-container">
      <SwipperComponent>
          {WEEK_DAYS.map((day, index) => (
             <SwiperSlide key={index}>
              <div className="schedule-group">
                  <h4 className="schedule-group--title">{day}</h4>
                  {schedule[day] &&
                      schedule[day].map((item, itemIndex) => (
                        item.box === box.toLowerCase() && item.lesson ? (<Link to={`${ROUTES.LESSONS}/${item.lesson}`} className="schedule-item" key={itemIndex}>
                            <div className="schedule-item--lesson-name">{unslug(item.lesson)}</div>
                            <div className="schedule-item--lesson-timeslot"><span>{item.startTime}</span> <span className="divider">-</span> <span>{item.endTime}</span></div>
                        </Link>) : null
                    ))}
              </div>
            </SwiperSlide>
          ))}
        </SwipperComponent>
    </div>
  );
}

export default ScheduleSlider;
