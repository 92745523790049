import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "controller/redux/ProfileSlice";
import { getLocalData } from "scripts/utils/Utils";
import { LOCAL_STORAGE_ROUTES } from "scripts/Constants";

function TrainingPlansComponent({ training_plans }) {

  const { currentProfile } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(currentProfile).length === 0) {
      dispatch(fetchProfile({ token: getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) }));
    }
  }, [dispatch]);

  return (
    <>
      {training_plans.map((training_plan, index) => {

        if (training_plan.tier === 'plano-zero' || training_plan.tier === currentProfile.online_plan || currentProfile.online_plan === 'plano-pro') {
          return (
            <Link to={`${ROUTES.TRAINING_PLANS}/${training_plan.slug}`} className="lesson-item" key={index}>
              <h4>{training_plan.tier}</h4>
              <figure>
                <div className="bg-gray image-container" ratio="2:3">
                  <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/lessons/sthenos-fit-lesson-${training_plan.slug}.jpg`} alt='' />
                </div>
                <figcaption>
                  <h4>{training_plan.title}</h4>
                </figcaption>
              </figure>
              <div className="lesson-item--cta-container">
                <div className="lesson-item--cta-arrow-container">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 247.47 15.39" }} version="1.1" viewBox="0 0 247.47 15.39">
                    <path d="M218.41 7.7 40.45 7.62" />
                    <path d="m33.37 7.64-16.4-.01" />
                    <path d="M6.96 7.64H.44" />
                    <path className="arrow-head" d="M247.47 8.1 236.08.42l2.18 7.36-2.68 7.19 11.89-6.87z" />
                  </svg>
                </div>
                <p className="text-white-offset">Ver detalhes sobre o treino</p>
              </div>
            </Link>
          );
        }
        // Plano LOCKED
        return (
          <div className="lesson-item" key={index}>
            <h4>PLANO BLOQUEADO</h4>
            <figure>
              <div className="bg-gray image-container" ratio="2:3">
                <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/lessons/sthenos-fit-lesson-${training_plan.slug}.jpg`} alt='' />
              </div>
              <figcaption>
                <h4>PLANO BLOQUEADO</h4>
              </figcaption>
            </figure>
            <div className="lesson-item--cta-container">
              <div className="lesson-item--cta-arrow-container">
                <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 247.47 15.39" }} version="1.1" viewBox="0 0 247.47 15.39">
                  <path d="M218.41 7.7 40.45 7.62" />
                  <path d="m33.37 7.64-16.4-.01" />
                  <path d="M6.96 7.64H.44" />
                  <path className="arrow-head" d="M247.47 8.1 236.08.42l2.18 7.36-2.68 7.19 11.89-6.87z" />
                </svg>
              </div>
              <p className="text-white-offset">Ver detalhes sobre o treino</p>
            </div>
          </div>
        );
      })}
    </>
  );

}

export default TrainingPlansComponent;