import React, { useState, createContext, useContext, useRef, useEffect } from 'react';
import CartModal from 'render/views/modals/CartModal';
import ExternalPageModal from 'render/views/modals/ExternalPageModal';
import FilterModal from 'render/views/modals/FilterModal';
import LoginModal from 'render/views/modals/LoginModal';
import ProfileModal from 'render/views/modals/ProfileModal';
import TrainerModal from 'render/views/modals/TrainerModal';
import BoxSelectorModal from 'render/views/modals/BoxSelectorModal';
import PopupModal from 'render/views/modals/PopupModal';

export const MODAL_TYPES = {
  TRAINER: 'TRAINER',
  FILTER: 'FILTER',
  SHOPPING_CART: 'SHOPPING_CART',
  PROFILE: 'PROFILE',
  LOGIN: 'LOGIN',
  EXTERNAL_SITE: 'EXTERNAL_SITE',
  BOX_SELECTOR: 'BOX_SELECTOR',
  POPUP: 'POPUP',
};

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const modalRef = useRef(null);
  const [lockOnScroll, setLockOnScroll] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && event.target.classList.contains('modal-component')) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [modalRef]);

  const scrollHandlerRef = useRef(null); // Declare scrollHandlerRef here

  useEffect(() => {
    const handleScroll = (e) => {
      if (lockOnScroll) {
        e.preventDefault();
      }
    };

    scrollHandlerRef.current = handleScroll;

    if (lockOnScroll) {
      window.addEventListener('wheel', scrollHandlerRef.current, { passive: false });
      document.body.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('wheel', scrollHandlerRef.current);
      document.body.style.overflowY = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, [lockOnScroll]);

  const handleClose = () => {
    let dimmer = document.querySelector('.standard-modal-box-dimmer');
    let holder = document.querySelector('.standard-modal-box-holder');

    if (dimmer && holder) {
      holder.classList.add("removing");
      dimmer.classList.remove("active");
      holder.classList.remove("active");
      setTimeout(function () {
        dimmer.style.display = 'none';
        holder.style.display = 'none';
        setLockOnScroll(false)
        setModal(null);
      }, 675);
    }
  };

  const handleShow = (type, props) => {

    if (type === MODAL_TYPES.PROFILE || type === MODAL_TYPES.SHOPPING_CART) {
      setLockOnScroll(false)
    } else {
      setLockOnScroll(true)
    }

    setTimeout(function () {
      let dimmer = document.querySelector('.standard-modal-box-dimmer');
      let holder = document.querySelector('.standard-modal-box-holder');

      dimmer.style.display = "flex";
      holder.style.display = "flex";
      setTimeout(function () {
        dimmer.classList.add("active");
        holder.classList.add("active");
        holder.setAttribute('tabindex', '-1');
        holder.focus();
      }, 25);
    }, 25);
    setModal({ type, props });
  };

  return (
    <ModalContext.Provider value={{ modal, handleShow, handleClose, modalRef }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export const GlobalModal = () => {
  const { modal, handleClose, modalRef } = useModal();

  if (!modal) {
    return null;
  }

  const renderModalContent = () => {
    switch (modal.type) {
      case MODAL_TYPES.TRAINER:
        return <TrainerModal props={modal.props}></TrainerModal>
      case MODAL_TYPES.FILTER:
        return <FilterModal props={modal.props}></FilterModal>
      case MODAL_TYPES.SHOPPING_CART:
        return <CartModal props={modal.props}></CartModal>
      case MODAL_TYPES.PROFILE:
        return <ProfileModal props={modal.props}></ProfileModal>
      case MODAL_TYPES.LOGIN:
        return <LoginModal props={modal.props}></LoginModal>
      case MODAL_TYPES.EXTERNAL_SITE:
        return <ExternalPageModal props={modal.props}></ExternalPageModal>
      case MODAL_TYPES.BOX_SELECTOR:
        return <BoxSelectorModal props={modal.props}></BoxSelectorModal>
      case MODAL_TYPES.POPUP:
        return <PopupModal props={modal.props}></PopupModal>
      default:
        return null;
    }
  };

  let modalId;
  let showCloseButton = true;
  let wrapContent = true;
  let wrapClass = false;

  if (modal.type === MODAL_TYPES.SHOPPING_CART) {
    modalId = "cart-sidebar-modal";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = false;
  } else if (modal.type === MODAL_TYPES.PROFILE) {
    modalId = "profile-modal";
    showCloseButton = false;
    wrapContent = false;
    wrapClass = false;
  } else if (modal.type === MODAL_TYPES.LOGIN && modal.type === MODAL_TYPES.POPUP) {
    modalId = "login-modal";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = false;
  } else if (modal.type === MODAL_TYPES.FILTER) {
    modalId = "filters-modal";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = "width-xl";
  } else if (modal.type === MODAL_TYPES.TRAINER) {
    modalId = "trainer-modal";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = "width-l";
  } else if (modal.type === MODAL_TYPES.EXTERNAL_SITE) {
    showCloseButton = false;
    wrapContent = false;
  } else if (modal.type === MODAL_TYPES.BOX_SELECTOR) {
    modalId = "selector-modal";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = false;
  } else if (modal.type === MODAL_TYPES.POPUP) {
    modalId = "pop-up";
    showCloseButton = true;
    wrapContent = false;
    wrapClass = false;
  }

  return (
    modal.type === MODAL_TYPES.LOGIN ?
      <div className="modal-component-container">
        <div className="standard-modal-box-dimmer"></div>
        <div className="standard-modal-box-holder">
          <div className="modal-component" ref={modalRef}>
            {renderModalContent()}
          </div>
        </div>
      </div>
      :
      <div className="modal-component-container">
        <div className="standard-modal-box-dimmer"></div>
        <div className="standard-modal-box-holder">
          <div className="modal-component" ref={modalRef}>
            <div className={`standard-modal-box ${wrapClass}`} id={modalId}>
              {showCloseButton && <button type='button' className="btn-close-modal" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 24 24" }} version="1.1" viewBox="0 0 24 24"><path d="M4.93 19.07 19.07 4.93M19.07 19.07 4.93 4.93" /></svg></button>}
              {wrapContent ? <div className="content">{renderModalContent()}</div> : renderModalContent()}
            </div>
          </div>
        </div>
      </div>
  );

};