import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateFetchActionReducers, generateExtraReducers, fetchProfileData } from 'scripts/utils/Utils'
import * as api from 'api/SthenosAPI';
import { toast } from 'react-toastify';
import axios from 'axios';

const initialState = {
    currentProfile: {},
    isProfileDataFetched: false,
    status: 'idle',
    error: null,
};

const PROFILE_ACTIONS = {
    FETCH_PROFILE: 'profile/fetchProfile',
    UPDATE_PROFILE: 'profile/updateProfile',
};

export const fetchProfile = createAsyncThunk(
    PROFILE_ACTIONS.FETCH_PROFILE,
    async ({ token, profile_data = null }) => {
        if (profile_data) {
            return await fetchProfileData(api.API_ENDPOINTS.FETCH_PROFILE, token, api, profile_data);
        } else {
            return await fetchProfileData(api.API_ENDPOINTS.FETCH_PROFILE, token);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async ({ profileData }, { rejectWithValue }) => {
        const formData = new FormData();

        for (const key in profileData) {
            if (profileData.hasOwnProperty(key) && key !== 'addresses') { // Skip 'addresses'
                if (typeof profileData[key] === 'object' && key !== 'photo') {
                    // For objects (excluding 'photo'), stringify
                    formData.append(key, JSON.stringify(profileData[key]));
                } else {
                    // Directly append all other properties, including 'photo'
                    formData.append(key, profileData[key]);
                }
            }
        }

        try {
            await axios.put(api.API_ENDPOINTS.CREATE_PROFILE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success("Perfil atualizado com sucesso!", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000,
            });

        } catch (error) {
            toast.error("Erro ao atualizar o perfil. Tente mais tarde!", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000,
            });

            return rejectWithValue(error.response.data);
        }
    }
);

const fetchActions = [
    { action: fetchProfile, reducers: generateFetchActionReducers('currentProfile') },
    { action: updateProfile, reducers: generateFetchActionReducers('currentProfile') },
];

const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileDataFetched: (state, action) => {
            state.isProfileDataFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setProfileDataFetched } = ProfileSlice.actions;

export default ProfileSlice.reducer;