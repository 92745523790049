import React, { useState } from 'react';
import { Link } from "react-router-dom";

import * as api from 'api/SthenosAPI'

import { ROUTES } from 'controller/routes/routes';
import { useLoading } from 'controller/hooks/hooks';
import { setLoggedIn, setToken } from 'controller/redux/AuthSlice';

import { useDispatch } from "react-redux"
import { fetchProfile, setProfileDataFetched } from 'controller/redux/ProfileSlice';

import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';

import { auth } from 'firebase-config';
import { createProfile } from 'scripts/utils/Utils';

const LoginModal = ({ props }) => {

    const { handleClose, onLoginSuccess } = props;

    const [isRegister, setRegister] = useState(false);

    const [loading, setLoading] = useLoading();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const dispatch = useDispatch();
    const buttonText = isRegister ? 'Registar' : 'Login';

    const closeModal = () => {
        handleClose();
        setEmail('')
        setFullName('')
        setPassword('')
        setConfirmPassword('')
    };

    function doLogin(user) {
        const token = user.uid;
        const profile_data = {
            "name": user.displayName,
            "email": user.email,
            "token": token,
            "photo": null
        }
        dispatch(setLoggedIn(true));
        dispatch(setToken(token))
        Promise.all([
            dispatch(fetchProfile({ token, profile_data }))
        ]).then(() => {
            dispatch(setProfileDataFetched(true));
        })
        if (onLoginSuccess) {
            onLoginSuccess();
        }
        closeModal();
    }

    const ERROR_CODES = {
        INVALID_EMAIL_FORMAT: "400",
        EMAIL_NOT_FOUND: "401",
        PASSWORDS_NOT_MATCHING: "402",
        PASSWORD_TOO_SHORT: "405",
        EMAIL_ALREADY_IN_USE: "406",
        CREDENTIALS_MISMATCH: "404",
    };

    // Simplify error display logic
    function displayError(errorCode) {
        let messagesContainer = document.getElementById('modal-login--error-messages-container');
        let messages = messagesContainer.querySelectorAll('[for-code]');
        // Hide all messages
        messages.forEach(message => {
            message.classList.add('hide');
        });
        // Show only the relevant message
        let messageToShow = messagesContainer.querySelector(`[for-code="${errorCode}"]`);
        if (messageToShow) {
            messageToShow.classList.remove('hide');
        }
    }

    function mapFirebaseErrorToErrorCode(firebaseErrorCode) {
        // Map Firebase error codes to your custom error codes
        // This is an example, adapt it based on actual Firebase error codes
        switch (firebaseErrorCode) {
            case "auth/invalid-email":
                return ERROR_CODES.INVALID_EMAIL_FORMAT;
            case "auth/email-already-in-use":
                return ERROR_CODES.EMAIL_ALREADY_IN_USE;
            case "auth/weak-password":
                return ERROR_CODES.PASSWORD_TOO_SHORT;
            default:
                return ERROR_CODES.CREDENTIALS_MISMATCH;
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        let modal = document.getElementById('login-modal');
        let emailInput = modal.querySelector('input[type="email"]');
        let passwordInput = modal.querySelector('input[type="password"]');

        setLoading(true);

        // Reset input error states
        emailInput.classList.toggle("error", email === '');
        passwordInput.classList.toggle("error", password === '');

        if (email === '' || password === '') {
            displayError(ERROR_CODES.INVALID_EMAIL_FORMAT); // Choose an appropriate error code
            setLoading(false);
            return false;
        }

        try {
            if (!isRegister) {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                doLogin(userCredential.user);
            } else {
                console.log(password + "|" + confirm_password);
                if (password !== confirm_password) {
                    displayError(ERROR_CODES.PASSWORDS_NOT_MATCHING);
                    return;
                }

                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const profile_data = {
                    name: fullName,
                    email: email,
                    token: userCredential.user.uid,
                    photo: null
                };

                await createProfile(api, profile_data);
                setRegister(false);
                doLogin(userCredential.user);
            }
        } catch (error) {
            console.error("Error processing request", error);
            let errorCode = error.code ? mapFirebaseErrorToErrorCode(error.code) : ERROR_CODES.EMAIL_ALREADY_IN_USE;
            displayError(errorCode);
        } finally {
            setLoading(false);
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            doLogin(user);
        } catch (error) {
            console.error("Error with Google sign in", error);
        }
    };

    const signInWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            doLogin(user);
        } catch (error) {
            console.error("Error with Facebook sign in", error);
        }
    };

    return (
        <>
            <form className='standard-modal-box' id="login-modal" onSubmit={handleSubmit}>
                <button type='button' className="btn-close-modal" onClick={closeModal}><svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 24 24" }} version="1.1" viewBox="0 0 24 24"><path d="M4.93 19.07 19.07 4.93M19.07 19.07 4.93 4.93" /></svg></button>
                <div className="image">
                    <div className="bg-gray image-container">
                        <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-login-image.jpg`} alt="" />
                    </div>
                </div>
                <div className="content">
                    <div>
                        <div className="flex-column">
                            <p className="text-gray">Vamos a isso! É só entrar.</p>
                            {isRegister ? (
                                <div className="flex-column">
                                    <p className="text-gray-dark text-xs">Já tens conta? <span className="inline-link text-gray" onClick={() => setRegister(false)}>Faz login</span></p>
                                </div>
                            ) : (
                                <div className="flex-column">
                                    <p className="text-gray-dark text-xs">Se ainda não tens conta, <span className="inline-link text-gray" onClick={() => setRegister(true)}>Regista-te</span></p>
                                </div>
                            )}
                        </div>
                        {isRegister ? (
                            <div className="input-wrapper">
                                <i className="icon-email"></i>
                                <input
                                    type="name"
                                    placeholder="Nome"
                                    value={fullName}
                                    onChange={e => setFullName(e.target.value)}
                                />
                            </div>
                        ) : null}
                        <div className="input-wrapper">
                            <i className="icon-email"></i>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-wrapper">
                            <i className="icon-lock"></i>
                            <input
                                type="password"
                                placeholder="Palavra-passe"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        {isRegister ? (
                            <div className="input-wrapper">
                                <i className="icon-lock"></i>
                                <input
                                    type="password"
                                    placeholder="Confirmar Palavra-passe"
                                    value={confirm_password}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        ) : null}
                        <div className="flex-column gap-xxs">
                            <div id="modal-login--error-messages-container">
                                <p for-code="400" className="hide text-red text-xs">O email inserido tem um formato inválido.</p>
                                <p for-code="401" className="hide text-red text-xs">O email inserido não tem um registo associado.</p>
                                <p for-code="402" className="hide text-red text-xs">As passwords inseridas não são iguais.</p>
                                <p for-code="405" className="hide text-red text-xs">A password deve ter pelo menos 6 caracteres.</p>
                                <p for-code="406" className="hide text-red text-xs">O email inserido já a ser usado.</p>
                                <div for-code="404" className="hide flex-column gap-xxs">
                                    <p className="text-red text-xs">O email e palavra-passe inseridos não coincidem com os nossos registos.</p>
                                    <p className="text-gray-dark text-xs"><Link to={ROUTES.SUBSCRIBE} className="inline-link">Esqueci-me da palavra-passe</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="hr-xxs" />
                    <div className="flex-row gap-xxs text-s">
                        <span className="text-gray-dark">Se preferires: </span>
                        <button type="button" onClick={signInWithGoogle} className="inline-link text-gray">Login com Google</button>
                        <span className="text-gray-dark">ou</span>
                        <button type="button" onClick={signInWithFacebook} className="inline-link text-gray">Login com Facebook</button>
                    </div>

                    <hr className="hr-m" />

                    <div className="modal-footer">

                        <button className="btn inline-link text-gray-dark" type="reset" onClick={closeModal}>Cancelar</button>
                        <button className='btn btn-primary-fill' type="submit" state={loading ? "processing" : "idle"} disabled={loading}><span>{loading ? 'Processing' : buttonText}</span></button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default LoginModal;
