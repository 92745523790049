import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet';

import LoadingPageComponent from 'render/views/components/LoadingPageComponent';

import { useSelector, useDispatch } from 'react-redux';
import { fetchEbooks } from "controller/redux/HomeSlice";
import FilterComponent from "render/views/components/FilterComponent";
import EbookComponent from "./EbookComponent";

function EbooksPage() {

  const { ebooks, isDataFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();
  const status = useSelector((state) => state.home.status);

  const [filteredEbooks, setFilteredEbooks] = useState(ebooks)

  function handleFilter(filtered) {
    setFilteredEbooks(filtered)
  }

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchEbooks());
    }
  }, [dispatch, isDataFetched]);

  if (status === 'loading') {
    return (
      <LoadingPageComponent />
    )
  }

  return (
    <div id="page-container" page="ebooks-list">
      <Helmet>
        <title>Sthenos | Ebooks </title>
        
        <meta name="description" content="Vem conhecer as nossas aulas!" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container-m p-0">
        <section className="container-m">
          <h1 className="page-title">Ebooks</h1>
          <hr className="hr-m" />
        </section>
        <section className="container-xs">
          <p className="text-l text-white-offset text-center">
            Texto de descrição dos Ebooks
          </p>
        </section>
        <hr className="hr-xxl" />



        <section className="container-m">
          {ebooks &&
            <FilterComponent
              list={ebooks}
              onFilter={handleFilter}
              type="store"
              label="Pesquisar Ebook..."
            />
          }
          <hr className="hr-l" />
          {ebooks &&
            <EbookComponent ebooks={filteredEbooks} />
          }
        </section>

        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default EbooksPage;