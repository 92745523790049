import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { clearLocalData } from 'scripts/utils/Utils';

import { ROUTES } from 'controller/routes/routes';

import * as api from 'api/SthenosAPI';
import { useLoading } from 'controller/hooks/hooks';

import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux"
import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";

import { useLocation } from "react-router-dom";
import { auth } from 'firebase-config';


function HeaderComponent() {


  const location = useLocation();

  const { handleShow, handleClose } = useModal();

  const [scrollPos, setScrollPos] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const shoppingCart = useSelector(state => state.store.shoppingCart);

  useEffect(() => {
    const onScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = scrollPos > currentScrollPos || currentScrollPos === 0;

      setScrollPos(currentScrollPos);
      setShowHeader(visible);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollPos]);

  const [loading, setLoading] = useLoading();

  const onLogout = async () => {
    try {
      await auth.signOut();
      clearLocalData();
    } catch (error) {
      console.error(error);
    }
  }

  let headerClass = '';
  if (!showHeader) {
    headerClass = 'hasScrolled';
  } else if (scrollPos !== 0) {
    headerClass = 'viewing';
  }

  const handleMobileClick = () => {
    const mobileHeader = document.getElementById('mobile-header');
    const mainHeader = document.getElementById('main-header');

    if (mobileHeader.classList.contains('has-mobile-menu-open')) {
      mobileHeader.classList.remove('has-mobile-menu-open');
      mainHeader.classList.remove('has-mobile-menu-open');
      setTimeout(() => {
        mobileHeader.style.display = 'none';
      }, 500);
    } else {
      mobileHeader.style.display = 'flex';
      mainHeader.classList.add('has-mobile-menu-open');
      setTimeout(() => {
        mobileHeader.classList.add('has-mobile-menu-open');
      }, 100);
    }
  };

  useEffect(() => {
    const mobileHeader = document.getElementById('mobile-header');
    if (mobileHeader.classList.contains('has-mobile-menu-open')) {
      handleMobileClick()
    }
  }, [location]);


  return (
    <>
      <header id="main-header" className={headerClass} location={location.pathname}>
        <Link to={ROUTES.HOME} className="logo-container">
          <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/brand/sthenos-logo-dourado.svg`} alt='Sthenos Fit Logo' />
          <img version="dark" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/brand/sthenos-logo.svg`} alt='Sthenos Fit Logo' />
        </Link>
        <div className="nav-container">
          <nav id="main-nav">
            <Link to={`${ROUTES.BOX}`} onClick={() => handleMobileClick()}><span>Box</span></Link>
            <Link to={ROUTES.TRAINING}><span>Treinar</span></Link>
            <Link to={ROUTES.STORE} new="true"><span>Loja</span></Link>
          </nav>
          <nav id="secondary-nav">
            {!isLoggedIn ? (
              <div className="header-cta-container">
                <label onClick={() => handleShow(MODAL_TYPES.LOGIN, { handleClose })}>
                  Acede à <button type="button" className="text-white inline-link">área de cliente</button>
                </label>
                <Link to={ROUTES.SUBSCRIBE}>
                  <span className="btn btn-primary-ghost">Inscreve-te já</span>
                </Link>
              </div>
            ) : (null)
            }

            {isLoggedIn ? (
              <button type="button" id="btn-profile" className="btn btn-primary-fill btn-square" onClick={() => handleShow(MODAL_TYPES.PROFILE, { handleClose })}>
                <span className="text-white"><i className="icon-profile"></i></span>
              </button>
            ) : null
            }

            <button type="button" id="btn-cart" className="btn btn-primary-fill btn-square" onClick={() => handleShow(MODAL_TYPES.SHOPPING_CART, handleClose)}>
              <span className="text-white position-relative">
                <i className="icon-shopping-bag"></i>
                {shoppingCart ?
                  shoppingCart.reduce((total, item) => total + item.quantity, 0) > 0 &&
                  <span id="cart-quantiy-indicator">{shoppingCart.reduce((total, item) => total + item.quantity, 0)}</span>
                  : null}
              </span>
            </button>
            <button type="button" id="btn-mobile-menu-toggle" className="btn btn-primary-ghost btn-square" onClick={handleMobileClick}><i className="icon-menu"></i></button>
          </nav>
        </div>
      </header>

      <header id="mobile-header">
        <nav>
          <Link to={ROUTES.HOME}><span>Início</span></Link>
          <Link to={`${ROUTES.BOX}`}><span>Box</span></Link>
          <Link to={ROUTES.TRAINING}><span>Treinar</span></Link>
          <Link to={ROUTES.STORE} new="true"><span>Loja</span></Link>
        </nav>
        <nav>
          {
            !isLoggedIn ? (
              <>
                <button type="button" className="btn btn-primary-fill" onClick={() => handleShow(MODAL_TYPES.LOGIN, { handleClose, onLoginSuccess: handleMobileClick })}>
                  <span>Iniciar Sessão</span>
                </button>
                <Link to={ROUTES.SUBSCRIBE} className="btn btn-primary-ghost">
                  <span>Torna-te Membro</span>
                </Link>
              </>
            ) : (
              <>
                <Link to={ROUTES.PROFILE} className='btn-s btn-primary-fill' onClick={handleClose}><span>Ver perfil</span></Link>
                <button className='btn btn-primary-ghost' state={loading ? "processing" : "idle"} disabled={loading} onClick={onLogout}><span>{loading ? 'Processing' : 'Logout'}</span></button>
              </>
            )
          }
        </nav>
      </header>
    </>
  );
};

export default HeaderComponent;
