import { useEffect } from 'react';
import { clearShoppingCart } from 'controller/redux/StoreSlice';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ROUTES } from "controller/routes/routes";
import { Link } from "react-router-dom";

export default function CheckoutForm() {
    const dispatch = useDispatch();

    const handleClearShoppingCart = () => {
        dispatch(clearShoppingCart());
    };

    useEffect(() => {
        handleClearShoppingCart();
    }, []);

    return (
        <div id="page-container" page="purchase-complete">
            <Helmet>
                <title>Sthenos | Está encomendado</title>
                
                
                
            </Helmet>

            <div className="fullheight">
                <section className="container-m">
                    <div className="page-header-no-image text-center">
                        <h1 className="uppercase text-accent text-outline">Encomendado!</h1>
                    </div>
                </section>

                <hr className="hr-m" />

                <section className="container-s text-center">
                    <p className="text-l text-accent">Vamos preparar tudo e assim que a encomenda estiver a caminho, nós avisamos</p>

                    <hr className="hr-m" />

                    <div className="container-xxxs p-0">
                        <img width="100%" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/celebration.jpg`} alt="" />
                    </div>

                    <hr className="hr-m" />

                    <Link to={ROUTES.STORE} className="btn btn-primary-ghost">Voltar à Loja</Link>
                </section>
            </div>

            <hr className="hr-xxl" />
        </div>
    );

    return (
        <h1>ARROTASTE COM A GUITA CHAVAL</h1>
    );
};