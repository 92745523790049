import React from "react";

function DifficultyComponent({ label,rating }) {

  return (
    <div className="difficulty-item">
      <p className="difficulty-item--label">{label}</p>
      <div className="difficulty-item--rating" rating={rating}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default DifficultyComponent;