import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ src, options, delay = 100 }) => {
    const videoNode = useRef(null);
    const [player, setPlayer] = useState(null);
    const [playerReady, setPlayerReady] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldRender(true);
        }, delay);

        return () => {
            clearTimeout(timer);
        }

    }, [src, delay]);

    useEffect(() => {
        if (!shouldRender) {
            return;
        }
        const defaultOptions = {
            autoplay: true,
            controls: false,
            responsive: true,
            fluid: true,
            loop: true,
            muted: true,
        };

        const mergedOptions = { ...defaultOptions, ...options };

        if (videoNode.current) {
            const vjsPlayer = videojs(videoNode.current, mergedOptions, function onPlayerReady() {
                this.el().style.width = 'auto';
                this.el().style.height = 'auto';
                this.src({ src, type: 'video/mp4' });
                setPlayerReady(true);
            });
            vjsPlayer.on('error', function (e) {
                console.log('VIDEOJS ERROR:', e);
            });
            setPlayer(vjsPlayer);
        }

        return () => {
            if (player) {
                setPlayerReady(false);
                player.dispose();
            }
        };
    }, [shouldRender]);

    useEffect(() => {
        if (playerReady) {
            player.src({ src, type: 'video/mp4' });
            if (player.el().classList.contains('vjs-playing')) {
                player.el().classList.remove('vjs-playing');
            }
        }
        
    }, [src, playerReady]);

    if (!shouldRender) {
        return null;
    }

    return (
        <div className="video-positioner">
            <div className="video-container">
                <video ref={videoNode} className="video-js vjs-big-play-centered sthenos-video-player" />
            </div>
        </div>
    );
};

export default VideoPlayer;
