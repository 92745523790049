import React, { useEffect } from "react"
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import CourseComponent from "render/views/pages/Courses/CourseComponent";
import LoadingPageComponent from 'render/views/components/LoadingPageComponent';
import { getPriceRangeFilter } from "scripts/utils/Utils";

import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses } from "controller/redux/HomeSlice";
import FilterComponent from "render/views/components/FilterComponent";

function CoursesPage() {

  const { courses, isDataFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();
  const status = useSelector((state) => state.home.status);

  const { t } = useTranslation();
  const filteredCourses = courses;

  function handleFilter(filtered) {
    this.filteredCourses = filtered;
  }

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchCourses());
    }
  }, [dispatch, isDataFetched]);

  if (status === 'loading') {
    return (
      <LoadingPageComponent />
    )
  }

  return (
    <div id="page-container" page="course-list">
      <Helmet>
        <title>Sthenos | Cursos</title>
        
        
        
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container-m">
        <h1 className="page-title">{t('courses_page_label')}</h1>
        <hr className="hr-m" />
        <section className="container-xs p-0">
          <p className="text-l text-white-offset text-center">
            {t('courses_page_desc')}
          </p>
        </section>
        <div className="flex-column gap-m">
          <CourseComponent courses={filteredCourses} />
        </div>

        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default CoursesPage;