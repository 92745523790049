import React, { useEffect } from "react"

import { Helmet } from 'react-helmet';
import CourseModuleComponent from "render/views/pages/Courses/CourseModuleComponent";

import { fetchCourse } from "controller/redux/HomeSlice";
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux"
import { addItemToCart } from "controller/redux/StoreSlice";
import { fetchProfile } from "controller/redux/ProfileSlice";
import VideoPlayer from "render/views/components/VideoPlayer";

import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";

import _ from 'lodash';
import ErrorPage from "../ErrorPage";
import { useDelayedLoading } from "controller/hooks/hooks";
import { getLocalData } from "scripts/utils/Utils";
import { LOCAL_STORAGE_ROUTES } from "scripts/Constants";

const CourseDetails = () => {
  const { slug } = useParams();
  const { course } = useSelector(
    (state) => state.home
  );
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { currentProfile } = useSelector((state) => state.profile);

  const { handleShow, handleClose } = useModal();
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    handleShow(MODAL_TYPES.EXTERNAL_SITE, { handleClose, url: course.url })
  };

  useEffect(() => {
    if (Object.keys(course).length === 0) {
      dispatch(fetchCourse(slug));
    } else if (course.slug != slug) {
      dispatch(fetchCourse(slug));
    }
    if (Object.keys(currentProfile).length === 0) {
      dispatch(fetchProfile({ token: getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) }));
    }
  }, [dispatch]);

  const handleBuy = () => {
    //dispatch(setCurrentValue(course.price*100))
  };

  return useDelayedLoading(course)(() => {
    if ((course && course.slug == slug) && Object.keys(currentProfile).length !== 0) {
      const selectedCourse = currentProfile.courses.find((course) => course.slug === slug);
      if (selectedCourse) {

        return (
          <div id="page-container" page="course">
            <Helmet>
              <title>Sthenos | {String(course.title)}</title>
              <meta name="description" content="As nossos cursos" />
              <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
            </Helmet>

            <section id="course-sidebar">
              <div className="flex-column gap-xs">
                <div className="flex-column gap-xxs">
                  <h4 id="course-sidebar--title">{course.title}</h4>
                  <p className="text-gray">{course.shortDescription_pt}</p>
                  <hr className="hr-s" />
                </div>
                <div className="flex-column gap-xxs">
                  {course.modules && course.modules.map((module, index) => (
                    <CourseModuleComponent module={module.name} key={index}
                      state={
                        selectedCourse.modules.find((mod) => mod.title === module.name)?.state
                      }
                      onClick={() => {
                        console.log(module.name); // replace this with your action
                      }} />
                  ))}
                </div>
              </div>
            </section>

            <section id="course-unlocked">
              <section id="course-detail">
                <div className="container-m">
                  <div className="page-header-no-image">
                    {/* <h3 className="section-title text-accent justify-start initialcase">{course.modules[0].name}</h3> */}
                    <h3 className="section-title text-accent justify-start initialcase">{course.title}</h3>
                  </div>

                  <hr className="hr-l" />

                  <div id="course-detail--main">
                    {course.preview_url && <VideoPlayer src={course.preview_url} />}
                  </div>
                </div>
              </section>

              <section className="bg-white-offset" id="course-detail-content">
                <div className="container-m">
                  <hr className="hr-l" />
                  <p className="text-l">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam volutpat pellentesque leo vulputate pellentesque. Ut libero ex, vestibulum sit amet scelerisque in, mollis in purus. Etiam et porta nibh. Fusce interdum massa magna, eu tempus massa porta nec. Maecenas vel nisi ut ex dignissim tristique. Aenean nisi elit, egestas tristique gravida ut, iaculis eu eros.</p>
                  <hr className="hr-xxl" />
                </div>
              </section>
            </section>
          </div>
        );
      } else {
        if (Object.keys(course).length !== 0) {
          return (
            <CourseDetailComponent
              course={course}
              handleAddToCart={handleAddToCart}
              isLoggedIn={isLoggedIn}
            />
          );
        } else {
          return <ErrorPage></ErrorPage>
        }
      }
    } else {
      if (Object.keys(course).length !== 0) {
        return (
          <CourseDetailComponent
            course={course}
            handleAddToCart={handleAddToCart}
            isLoggedIn={isLoggedIn}
          />
        );
      } else {
        return <ErrorPage></ErrorPage>
      }
    }
  });
};

export default CourseDetails;

const CourseDetailComponent = ({ course, handleAddToCart, isLoggedIn }) => {

  const totalDuration = _.reduce(course.modules, (sum, module) => sum + Number(module.duration), 0);

  return (
    <div id="page-container" page="course">
      <Helmet>
        <title>Sthenos | {String(course.title)}</title>
        
        
        
      </Helmet>

      <section id="course-detail">
        <div className="container-m">
          <div className="page-header-no-image">
            <h2 className="section-title justify-start">Curso</h2>
          </div>

          <div id="course-detail--main">
            <VideoPlayer src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/courses/${course.slug}/sthenos-fit-course-${course.slug}.mp4`} options={{ autoplay: false, controls: true, muted: false, loop: false }}
            />
            <div id="course-info">
              <div id="course-info--details">
                <div className="flex-column gap-s">
                  <h2 id="course-info--title">{course.title}</h2>
                  <h4 id="course-info--price">{course.price}€</h4>
                  <hr className="hr-xxs" />
                  <p>{course.shortDescription_pt}</p>
                </div>
                <div className="mt-auto">
                  <button onClick={handleAddToCart} type="button" className="btn-l btn-primary-fill">
                    <span>Comprar Curso</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="hr-l" />

      <section className="bg-white-offset" id="course-detail-content">
        <div className="container-m">
          {/* <div className="flex-column gap-xs">
            <p>Com este curso, terás acesso a:</p>
            <ul className="feature-items-container" feature-look="alt">
              <li className="feature-item"><i className="icon-check-circle"></i><span>{totalDuration} horas de vídeo</span></li>
              <li className="feature-item"><i className="icon-check-circle"></i><span>{course.modules.length} aulas</span></li>
              <li className="feature-item"><i className="icon-check-circle"></i><span>Conteúdo teórico e prático</span></li>
              <li className="feature-item"><i className="icon-check-circle"></i><span>Acesso vitalício</span></li>
            </ul>
          </div> */}

          <hr className="hr-xl" />
          <div id="course-detail-content--info">

            <div>
              <div className="flex-row justify-between align-end gap-xl">
                <h3 className="text-accent">Descrição</h3>
              </div>
              <hr className="hr-s" />
              <p>{course.description_pt}</p>

              <hr className="hr-l" />
              <div className="flex-column gap-xs">
                <p className="text-accent">Com este curso, terás acesso a:</p>
                <ul className="bullet-list">
                  <li>{totalDuration} horas de vídeo</li>
                  <li>{course.modules.length} aulas</li>
                  <li>Conteúdo teórico e prático</li>
                  <li>Acesso vitalício</li>
                </ul>
              </div>
            </div>

            <div>
              <div className="flex-row justify-between align-end gap-xl">
                <h3 className="text-accent">Módulos</h3>
                <p><b>{totalDuration} Horas</b></p>
              </div>

              <hr className="hr-s" />

              <div className="flex-column gap-xxs">
                {course.modules && course.modules.map((module, index) => (
                  <CourseModuleComponent module={module} key={index} isLoggedIn={isLoggedIn} />
                ))}
              </div>
            </div>
          </div>

        </div>

        <hr className="hr-xxl" />
      </section>
    </div>
  );

}