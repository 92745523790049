import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import * as api from 'api/SthenosAPI'

function VerificationPage() {
  const [token, setToken] = useState('');

  useEffect(() => {
    async function fetchData() {
      const queryString = window.location.search;
      const myToken = queryString.split('=')[1];

      if (myToken) {
        setToken(myToken);
      }


      await api.getData(api.API_ENDPOINTS.EMAIL_CONFIRM_NEWSLETTER + '?token=' + myToken, null,
        (response) => {

        },
        (error) => {
          console.error(error);
        }
      );
    }

    fetchData();
  }, []);

  return (
    <div id="page-container" page="subscribe">
      <Helmet>
        <title>Sthenos | Subscreve </title>
        <meta name="description" content="A melhor forma de treinar Calistenia em Portugal" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>
      <section className="container p-0">
        <section className="container">
          <h1 className="page-title">Verificação Concluída</h1>
        </section>
        <hr className="hr-xl hr-l-on-breakpoint-m" />
        <section className="container-xs">
          <p className="text-l text-primary text-center">
            Já está! Podes fechar esta janela.
          </p>

          <hr className="hr-xl hr-l-on-breakpoint-m" />
        </section>
      </section>
    </div>
  );
}

export default VerificationPage;
