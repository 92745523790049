import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import HomeSlice from './HomeSlice';
import StoreSlice from './StoreSlice';
import ProfileSlice from './ProfileSlice';

const store = configureStore({
  reducer: {
    home: HomeSlice,
    store: StoreSlice,
    auth: AuthSlice,
    profile: ProfileSlice,
  },
});
export default store;