import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from 'controller/routes/routes';
import { clearShoppingCart, increaseQuantity, decreaseQuantity, deleteItem } from 'controller/redux/StoreSlice';
import { toast } from 'react-toastify';
import { getColorValue } from 'scripts/utils/Utils';

const CartModal = ({ props }) => {

    const {
        handleClose
    } = props;

    const shoppingCart = useSelector(state => state.store.shoppingCart);
    const dispatch = useDispatch();

    console.log(shoppingCart)

    const handleCheckout = () => {
        handleClose();
    };

    const handleIncrease = (item) => {
        dispatch(increaseQuantity(item));
    };

    const handleDecrease = (item) => {
        dispatch(decreaseQuantity(item));
    };

    const handleDeleteItem = (item) => {
        dispatch(deleteItem(item));
    };

    return (
        <>
            {shoppingCart.length === 0 ? (
                <div className="empty-state">
                    <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/illustrations/sthenos-fit-empty-bag.svg`} />
                    <h5 className="text-accent">Sem artigos</h5>
                    <p className="text-gray-lightest text-s">
                        Quando adicionares algum artigo da loja, vê-lo-às aqui.
                    </p>
                </div>
            ) : (
                <>
                    <h5>As tuas compras</h5>
                    <ul className="cart-item-list">
                        {shoppingCart.map((item, index) => (
                            <li className="cart-item" key={index}>
                                {item.storeItem.duration ? ( // For Course Item (relying on duration since this parameter doesn't exist on StoreItem)
                                    <>
                                        <img width="100%" height="auto" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/courses/sthenos-fit-course-${item.storeItem.slug}`} alt={`${item.storeItem.slug}`} />
                                        <div className="cart-item-details">
                                            <div className="flex-row align-center justify-between">
                                                <span className="cart-item--title">{item.storeItem.title}</span>
                                                <span className="cart-item--price">{item.storeItem.price - item.storeItem.discount} €</span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img
                                            width="100%"
                                            height="auto"
                                            src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${item.storeItem.inventory.find(invItem =>
                                                invItem.color === item.storeItem.selectedColor &&
                                                invItem.size === item.storeItem.selectedSize.toLowerCase())?.photo || item.storeItem.photo
                                                }`}
                                            alt={`Product ${item.storeItem.slug}`}
                                        />
                                        <div className="flex-column gap-m">
                                            <div className="flex-row justify-between">
                                                <div className="flex-column gap-xxs">
                                                    <h6 className="cart-item--title">{item.storeItem.name}</h6>
                                                    <div className="flex-row align-center gap-xxxs">
                                                        {/* <span className="text-gray">{item.storeItem.selectedColor}</span> */}
                                                        {item.storeItem.selectedSize !== '' && (
                                                            <span className="cart-item--selected-option">{item.storeItem.selectedSize}</span>
                                                        )}
                                                        {item.storeItem.selectedColor !== '' && (
                                                            <span className="cart-item--selected-option" style={{ backgroundColor: getColorValue(item.storeItem.selectedColor) }}></span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex-column align-end">
                                                    <h6 className="cart-item--price">{(item.storeItem.price - item.storeItem.discount) * item.quantity} €</h6>
                                                    {item.quantity > 1 && (
                                                        <span className="cart-item--price-unit">{item.storeItem.price - item.storeItem.discount}€ / unid.</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="justify-self-stretch mt-auto flex-row justify-between">
                                                {/* Isto (Available Stock) é para tirar so para checkar a condição no controller  */}
                                                {/* <p className="cart-item-price">Available Stock:{item.storeItem.selectedStock}</p> */}
                                                <div className="cart-item--quantity">
                                                    <button onClick={() => handleDecrease(item.storeItem)} > - </button>
                                                    {item.quantity}
                                                    <button onClick={() => {
                                                        if (item.quantity < item.storeItem.selectedStock) {
                                                            handleIncrease(item.storeItem);
                                                        } else {
                                                            toast.error("Lamentamos, mas parece que o stock deste artigo esgotou", {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 5000,
                                                            });
                                                        }
                                                    }}> + </button>
                                                </div>
                                                <button type="button" className="cart-item--delete" onClick={() => handleDeleteItem(item.storeItem)}><i className="icon-delete text-l"></i></button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </li>

                        ))}

                    </ul>

                    <div className="cart-total-container">
                        <div className="flex-row justify-between">
                            <p>Total</p>
                            <h5>{shoppingCart.reduce((acc, item) => acc + ((item.storeItem.price - item.storeItem.discount) * item.quantity), 0).toFixed(2)} €</h5>
                        </div>
                        <Link to={ROUTES.SHOPPING_CART} className="btn-l btn-primary-fill" onClick={handleCheckout} >
                            <span>Checkout</span>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};

export default CartModal;
