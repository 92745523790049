import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'controller/hooks/hooks';
import { useLocation } from 'react-router-dom';
import pako from 'pako';
import { handleOpenExternalPage } from 'scripts/utils/Utils';

// import IframeResizer from 'iframe-resizer-react';

const PacksPage = () => {

  const location = useLocation();
  const base64Data = decodeURIComponent(new URLSearchParams(location.search).get('data'));

  const byteArray = new Uint8Array(atob(base64Data).split('').map(char => char.charCodeAt(0)));
  const decompressedData = pako.inflate(byteArray, { to: 'string' });

  const { url, box, description } = JSON.parse(decompressedData);

  const dimensions = useWindowDimensions();

  const [adjustedIframeHeight, setAdjustedIframeHeight] = useState('480px');

  const [popupClosed, setPopupClosed] = useState(false);

  useEffect(() => {
    if (dimensions.width < 400) {
      setAdjustedIframeHeight("1000px");
    } else if (dimensions.width < 450) {
      setAdjustedIframeHeight("990px");
    } else if (dimensions.width < 600) {
      setAdjustedIframeHeight("940px");
    } else if (dimensions.width < 700) {
      setAdjustedIframeHeight("800px");
    } else if (dimensions.width < 800) {
      setAdjustedIframeHeight("720px");
    } else if (dimensions.width < 950) {
      setAdjustedIframeHeight("570px");
    } else {
      setAdjustedIframeHeight("480px");
    }
  }, [dimensions]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleOpenExternalPage(url, setPopupClosed);
    }, 3500);

    return () => clearTimeout(timer);
  }, [url]);

  return (
    <div id="page-container" page="box-list">

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      {/* <section className="container p-0">
          <h1 className="page-title">Adere</h1>
          <hr className="hr-m" />
          <section className="container-m">
            <h2 className="uppercase text-accent text-outline-thicker text-center">Formulário de Inscrição</h2>
            <hr className="hr-s"/>
            <h2 className="uppercase text-accent text-center">{description}</h2>
            <h3 className="uppercase text-center text-gray-dark">Box de {box}</h3>
          </section>
          <hr className="hr-l"/>
          <section className="container-sm">
            <p className="text-l text-white-offset text-center">
              Preenche os dados abaixo para escolheres o pack que queres e passares a ser da família das barras!
              <br/>
              Podes ver as instruções todas logo abaixo do formulário
            </p>
          </section>

          <hr className="hr-xl hr-l-on-breakpoint-m" />

          <section className="container-s">
            {url && 
              <>
                <input type="hidden" className="class_regy" id="src_regy6" value={url} />
                <iframe name="frame_regy6" id="frame_regy6" width="100%" height="0px" frameBorder="0" scrolling="no"></iframe>
              </>
              // <iframe src={url} width="100%" height={adjustedIframeHeight}></iframe>
              // <IframeResizer
              //     src={url}
              //     style={{ width:"100%", minWIdth:"1px",height:"540px" }}
              //     frameBorder="0"
              // />
            }

            <hr className="hr-l"/>

            <h3 className="uppercase text-gray-dark">Instruções</h3>
            <hr className="hr-m"/>
            <p className="text-s text-white-offset">
              Depois de submeteres, vais receber um e-mail com instruções sobre como acederes à tua conta do STHENOS Carnaxide na aplicação da Regybox. Podes aceder através de <a href="https://www.regibox.pt/app/app_nova" target="_blank" className="inline-link">https://www.regibox.pt/app/app_nova</a> ou fazendo o download da aplicação para o teu smartphone! 
              Finaliza a tua inscrição enviado um email para sthenosbenfica@calisteniaportugal.fit com o teu nome de registo, o comprovativo de transferencia - IBAN: PT50 0010 0000 6233 5410 0016 9 - e o pack que pretendes adquirir!
              No assunto do email deves indicar o tema INSCRIÇÃO, a box a que se refere, o primeiro e ultimo nome e o pack a que diz respeito) (ex: Inscrição STHENOS FIT BENFICA - Ana Paula Henriques Pack Livre trânsito ilimitado).
              A 1ª prestação da mensalidade é feita através de transferência bancária. As restantes mensalidades devem ser pagas através de Débito Direto ou diretamente na app via mbway ou entidade e referência! A documentação referente ao débito deve ser solicitada e preenchida ao balcão da box com ajudas dos coachs! 
              Todos os packs são válidos de dia 1 até ao último dia do mês!
              <br/>
              Valor da inscrição: 25€ + OFERTA DE AVALIAÇÃO INICIAL C/ COACH
            </p>
          </section>
  
          <hr className="hr-xl" />
          <hr className="hr-xxl" />
        </section> */}

      <section className="container p-0">
        <h1 className="page-title">Adere</h1>
        <hr className="hr-m" />
        <section className="container-m">
          <h2 className="uppercase text-accent text-outline-thicker text-center">Formulário de Inscrição</h2>
          <hr className="hr-s" />
          <h2 className="uppercase text-accent text-center">{description}</h2>
          <h3 className="uppercase text-center text-gray-dark">Box de {box}</h3>
        </section>
        <hr className="hr-l" />
        <section className="container-xs">
          <p className="text-l text-white-offset text-center">
            Preenche o formulário através do nosso parceiro para escolheres o pack que queres e passares a fazer parte da família das barras!
          </p>
        </section>

          {popupClosed &&
            <>
              <section className="container">
                <hr className="hr-l" />
                <section className="flex-column text-center align-center">
                  <i className="icon-check-circle text-accent text-xxxl"></i>
                  <hr className="hr-s" />
                  <h1 className="text-white-offset">Registo processado</h1>
                  <hr className="hr-m" />
                  <h2 className="text-gray">Segue as instruções do email enviado</h2>
                </section>
                
                <hr className="hr-l" />

                <section className="container-s p-0">
                  <p className="text-s text-white-offset">
                    Depois de submeteres o formulário, vais receber um e-mail com instruções sobre como acederes à tua conta do STHENOS Carnaxide na aplicação da Regybox. Podes aceder através de <a href="https://www.regibox.pt/app/app_nova" target="_blank" className="inline-link">https://www.regibox.pt/app/app_nova</a> ou fazendo o download da aplicação para o teu smartphone!
                    Finaliza a tua inscrição enviado um email para sthenosbenfica@calisteniaportugal.fit com o teu nome de registo, o comprovativo de transferencia - IBAN: PT50 0010 0000 6233 5410 0016 9 - e o pack que pretendes adquirir!
                    No assunto do email deves indicar o tema INSCRIÇÃO, a box a que se refere, o primeiro e ultimo nome e o pack a que diz respeito) (ex: Inscrição STHENOS FIT BENFICA - Ana Paula Henriques Pack Livre trânsito ilimitado).
                    A 1ª prestação da mensalidade é feita através de transferência bancária. As restantes mensalidades devem ser pagas através de Débito Direto ou diretamente na app via mbway ou entidade e referência! A documentação referente ao débito deve ser solicitada e preenchida ao balcão da box com ajudas dos coachs!
                    Todos os packs são válidos de dia 1 até ao último dia do mês!
                    <br />
                    Valor da inscrição: 25€ + OFERTA DE AVALIAÇÃO INICIAL C/ COACH
                  </p>
                </section>
              </section>
            </>
          }

          {!popupClosed &&
            <section className="container-xs">
              <hr className="hr-xl hr-l-on-breakpoint-m" />
              <div className="flex-column align-center gap-m justify-center text-center text-white-offset">
                <p>
                  Deverá abrir uma janela pop-up dentro de segundos. Se não abrir, por favor verifica que tens as definições de popup aceites.
                  Para completares o registo, é necessário completar os campos de formulário dentro da janela pop-up. Podes depois fechar a janela.
                </p>
                {/* <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/popup.png`} /> */}
              </div>
            </section>
          }

        <hr className="hr-xl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default PacksPage;
