import React, { useState, useEffect } from 'react';
import BodyPartsComponent from './BodyPartsComponent';
import { BODYPARTS_MAP, BODYPARTS_GROUPS } from 'scripts/Constants';

import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";

function FilterComponent({ list, onFilter, type, label }) {

    const { handleShow, handleClose } = useModal();

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState(list);

    const [selectedBodyPartGroups, setSelectedBodyPartGroups] = useState([]);
    const [selectedDifficulties, setSelectedDifficulties] = useState([]);
    const [selectedGears, setSelectedGears] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const appliedFilters = [
        ...selectedBodyPartGroups.map(group => ({
            type: 'bodyPartGroup',
            value: group,
            clear: () => handleFilterClick(setSelectedBodyPartGroups, group),
        })),
        ...selectedDifficulties.map(difficulty => ({
            type: 'difficulty',
            value: difficulty,
            clear: () => handleFilterClick(setSelectedDifficulties, difficulty),
        })),
        ...selectedGears.map(gear => ({
            type: 'gear',
            value: gear,
            clear: () => handleFilterClick(setSelectedGears, gear),
        })),
        ...selectedCategories.map(category => ({
            type: 'category',
            value: category,
            clear: () => handleFilterClick(setSelectedCategories, category),
        })),
    ];

    const getAllBodyPartsFromSelectedFilters = () => {
        let allBodyParts = [];
        for (const group of selectedBodyPartGroups) {
            if (BODYPARTS_GROUPS[group]) {
                for (const bodyPart of BODYPARTS_GROUPS[group]) {
                    const slug = Object.keys(BODYPARTS_MAP).find(key => BODYPARTS_MAP[key] === bodyPart);
                    if (slug) {
                        allBodyParts.push({
                            slug: slug,
                            name: group,
                        });
                    }
                }
            }
        }
        return allBodyParts;
    };
    const clearAllFilters = () => {
        setSearchTerm('');
        if (type !== 'store') {
            setSelectedBodyPartGroups([]);
            setSelectedDifficulties([]);
            setSelectedGears([]);
            setSelectedCategories([]);
        }
        handleClose();
    };

    const handleFilterClick = (setSelected, item) => {
        setSelected(prevItems =>
            prevItems.includes(item)
                ? prevItems.filter(i => i !== item)
                : [...prevItems, item]
        );
    }

    const isFilterApplied = () => {
        return [searchTerm].some(Boolean) ||
            selectedBodyPartGroups.length > 0 ||
            selectedDifficulties.length > 0 ||
            selectedGears.length > 0 ||
            selectedCategories.length > 0;
    };

    useEffect(() => {
        let newFilteredList = list;

        if (searchTerm) {
            newFilteredList = newFilteredList.filter(item =>
                (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
        if (type !== 'store') {
            if (selectedBodyPartGroups.length > 0) {
                newFilteredList = newFilteredList.filter(item =>
                    selectedBodyPartGroups.some(group =>
                        item.bodyParts.some(part => BODYPARTS_GROUPS[group].includes(part.name))
                    )
                );
            }

            if (type === 'exercise' && selectedDifficulties.length > 0) {
                newFilteredList = newFilteredList.filter(item =>
                    selectedDifficulties.includes(item.difficulty)
                );
            }

            if (type === 'exercise' && selectedGears.length > 0) {
                newFilteredList = newFilteredList.filter(item =>
                    selectedGears.every(gear_item =>
                        item.gear.includes(gear_item)
                    )
                );
            }

            if (type === 'exercise' && selectedCategories.length > 0) {
                newFilteredList = newFilteredList.filter(item =>
                    selectedCategories.includes(item.category)
                );
            }
        }
        setFilteredList(newFilteredList);
    }, [list, searchTerm, selectedGears, selectedCategories, selectedBodyPartGroups, selectedDifficulties, type]);

    useEffect(() => {
        onFilter(filteredList);
    }, [filteredList]);

    const clearSearch = () => {
        setSearchTerm('');
    };

    return (
        <>
            <div className={type !== 'store' ? `filter-area ${selectedBodyPartGroups.length > 0 ? "has-filtered-muscles" : ""}` : ""}>
                <div className="w-100">
                    <div className="filter-search-container">
                        {type !== 'store' && (
                            <button className="btn-l btn-secondary-ghost" onClick={() =>
                                handleShow(MODAL_TYPES.FILTER, {
                                    selectedBodyPartGroups, setSelectedBodyPartGroups,
                                    selectedDifficulties, setSelectedDifficulties,
                                    selectedGears, setSelectedGears,
                                    selectedCategories, setSelectedCategories,
                                    type, clearAllFilters, isFilterApplied, handleClose, list
                                })}>
                                <i className="icon-filter"></i>
                                <span>{type === 'exercise' ? `Filtrar exercícios` : `Filtrar aulas`}</span>
                            </button>
                        )}
                        <div className="btn-secondary-ghost search-input-wrapper w-100">
                            <i className="icon-search"></i>
                            <input className="btn-l"
                                type="search"
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                                placeholder={type === 'exercise' ? `Pesquisar exercícios...` : (type === 'store' ? label : `Pesquisar aulas...`)}
                            />
                            {searchTerm && <button className="btn-l btn-close" onClick={clearSearch}><svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 24 24" }} version="1.1" viewBox="0 0 24 24"><path d="M4.93 19.07 19.07 4.93M19.07 19.07 4.93 4.93" /></svg></button>}
                        </div>
                    </div>

                    <div className="filter-tags-container">
                        {selectedCategories.length > 0 ? (
                            <div>
                                <p className="text-gray">Categorias filtradas:</p>
                                <hr className="hr-xxs" />
                                <div className="filter-tag-group">
                                    {appliedFilters
                                        .filter(filter => filter.type === 'category')
                                        .map((filter, index) => (
                                            <button className="btn-s btn-primary-ghost" key={index} onClick={filter.clear}>
                                                <span>{filter.value}</span>
                                                <div className="btn-close"><i className="icon-close"></i></div>
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>) : null}
                        {selectedGears.length > 0 ? (
                            <div>
                                <p className="text-gray">Equipamentos:</p>
                                <hr className="hr-xxs" />
                                <div className="filter-tag-group">
                                    {appliedFilters
                                        .filter(filter => filter.type === 'gear')
                                        .map((filter, index) => (
                                            <button className="btn-s btn-primary-ghost" key={index} onClick={filter.clear}>
                                                <span>{filter.value}</span>
                                                <div className="btn-close"><i className="icon-close"></i></div>
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>) : null}
                        {selectedBodyPartGroups.length > 0 ? (
                            <div>
                                <p className="text-gray">Grupos musculares:</p>
                                <hr className="hr-xxs" />
                                <div className="filter-tag-group">
                                    {appliedFilters
                                        .filter(filter => filter.type === 'bodyPartGroup')
                                        .map((filter, index) => (
                                            <button className="btn-s btn-primary-ghost" key={index} onClick={filter.clear}>
                                                <span>{filter.value}</span>
                                                <div className="btn-close"><i className="icon-close"></i></div>
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>) : null}
                        {selectedDifficulties.length > 0 ? (
                            <div>
                                <p className="text-gray">Dificuldade:</p>
                                <hr className="hr-xxs" />
                                <div className="filter-tag-group">
                                    {appliedFilters
                                        .filter(filter => filter.type === 'difficulty')
                                        .map((filter, index) => (
                                            <button className="btn-s btn-primary-ghost" key={index} onClick={filter.clear}>
                                                <span>{filter.value}</span>
                                                <div className="btn-close"><i className="icon-close"></i></div>
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>) : null}
                    </div>
                </div>

                {selectedBodyPartGroups.length > 0 ? (
                    <BodyPartsComponent bodyParts={getAllBodyPartsFromSelectedFilters()} details={false} />
                ) : null}
            </div>

            {filteredList.length === 0 && (
                <div className="empty-list">
                    <hr className="hr-xl" />
                    <h2 className="text-accent text-center">Não encontrámos resultados nos parâmetros que procuras.</h2>
                </div>
            )}
        </>
    );
}

export default FilterComponent;
