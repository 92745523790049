import React, { useState } from 'react';
import * as api from 'api/SthenosAPI'
import { useLoading } from 'controller/hooks/hooks';

function SthenosAI() {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useLoading();

  const sendMessage = async () => {
    await api.postData(api.API_ENDPOINTS.STHENOS_AI,{ prompt: "Hello world!" },setLoading,
      (response) => {
          setMessages([...messages, { prompt: "user", content: "Hello world!" }, { role: "bot", content: response }]);
          setInputValue("");
      },
      (error) => {
          console.error(error);
      }
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh" }}>
      <div style={{ flex: "1", overflowY: "scroll", padding: "1rem", width: "80%", maxWidth: "600px" }}>
        {messages.map((message, index) => (
          <div key={index} style={{ textAlign: message.role === "user" ? "right" : "left", margin: "1rem 0" }}>
            <div style={{ display: "inline-block", backgroundColor: message.role === "user" ? "#0084ff" : "#e6e6e6", color: message.role === "user" ? "#fff" : "#333", borderRadius: "1rem", padding: "0.5rem 1rem" }}>{message.content}</div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", width: "80%", maxWidth: "600px", marginTop: "1rem" }}>
        <input style={{ flex: "1", padding: "0.5rem", height: "2rem" }} type="text" value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} />
        <button style={{ padding: "0.5rem 1rem", backgroundColor: "#0084ff", color: "#fff", border: "none", borderRadius: "0.25rem", marginLeft: "1rem" }} onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default SthenosAI