import React, { useState, useEffect } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export function GalleryComponent({ pictures, basePath, initialIndex = 0, isOpen, onClose }) {
    const [pictureIndex, setPictureIndex] = useState(initialIndex);

    const slides = pictures.map(pic => {
        return {
            src: `${basePath}/${pic}`,
            description: pic.split('.')[0]
        };
    });

    useEffect(() => {
        setPictureIndex(initialIndex);
    }, [initialIndex]);

    return (
        <>
            {isOpen && 
                <Lightbox
                    open={isOpen}
                    close={onClose}
                    slides={slides}
                    index={pictureIndex}
                    plugins={[Captions, Thumbnails]}
                    captions={{ descriptionTextAlign: 'center' }}
                />
            }
        </>
    );
}
