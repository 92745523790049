import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import SwipperComponent from "render/views/components/SwipperComponent";
import { SwiperSlide } from "swiper/react";

function LessonsSlider({ lessons }) {
  
  return (
    
    <SwipperComponent>
      {lessons.map((lesson,index) => (
        <SwiperSlide key={index}>
          <Link to={`${ROUTES.LESSONS}/${lesson.slug}`} className="component-item lesson-item" featured={lesson.featured.toString()}>
            <figure>
              <div className="bg-gray image-container" ratio="2:3">
                <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/lessons/${lesson.slug}/sthenos-fit-lesson-${lesson.slug}.jpg`} alt='' />
              </div>
              <figcaption>
                <h4>{lesson.title}</h4>
              </figcaption>
            </figure>
            <div className="component-item--cta-container">
              <div className="component-item--cta-arrow-container">
                <span></span><span></span><span></span>
                <svg className="arrow-head" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 10 15" }} viewBox="0 0 10 15">
                  <path d="M3.3.2.4 3.1l4.3 4.4-4.3 4.4 2.9 2.9 6.3-6.3v-2z"/>
                </svg>
              </div>
              <p className="text-white-offset">Ver detalhes sobre a aula</p>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </SwipperComponent>
  );
}

export default LessonsSlider;
