import React from "react";

import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import SwipperComponent from "render/views/components/SwipperComponent";
import { SwiperSlide } from "swiper/react";

function ExercisesSlider({ exercises }) {
  
  return (
    
    <SwipperComponent>
      {exercises.map((exercise,index) => (
        <SwiperSlide key={index}>
          <Link to={`${ROUTES.EXERCISES}/${exercise.slug}`} className="exercise-item">
            <figure>
              <div className="bg-gray image-container" ratio="2:3">
                <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/exercises/${exercise.slug}/sthenos-fit-exercise-${exercise.slug}.jpg`} alt='' />
                <div className="exercise-image-info-overlay">
                  <p className="exercise-image-info-overlay--difficulty">{exercise.difficulty}</p>
                  <span></span>
                  <p className="exercise-image-info-overlay--cta"><span><i className="icon-close"></i></span></p>
                </div>
              </div>
              <figcaption>
                <p className="text-white-offset text-xs">{exercise.category}</p>
                <h5>{exercise.title}</h5>
              </figcaption>
            </figure>
          </Link>
        </SwiperSlide>
      ))}
    </SwipperComponent>
  );
}

export default ExercisesSlider;
