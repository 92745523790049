import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';

function CourseComponent({ courses }) {

  return (
    <div className="grid-2 gap-l gap-m-on-breakpoint-ml">
      {courses.map((course, index) => (
        <Link to={`${ROUTES.COURSES}/${course.slug}`} className="componente-item course-item" key={index}>
          <figure>
            <div className="bg-gray image-container" ratio="1:1">
              <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/courses/${course.slug}/sthenos-fit-course-${course.slug}.jpg`} alt='' />
            </div>
            <figcaption>
              <h3>{course.title}</h3>
              <p>{course.short_description}</p>
            </figcaption>
          </figure>
          <div className="component-item--cta-container">
            <div className="component-item--cta-arrow-container">
              <span></span><span></span><span></span>
              <svg className="arrow-head" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 10 15" }} viewBox="0 0 10 15">
                <path d="M3.3.2.4 3.1l4.3 4.4-4.3 4.4 2.9 2.9 6.3-6.3v-2z"/>
              </svg>
            </div>
            <p className="text-white-offset">Ver detalhes sobre o curso</p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default CourseComponent;