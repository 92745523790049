import React from "react"
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';

function ErrorPage() {
  return (
  <div id="page-container" page="404">
      <Helmet>
        <title>Sthenos | Erro </title>
      </Helmet>
      <section>
        <video src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-rings.mp4`} loop={true} muted={true} autoPlay playsInline />
        <div className="container-m">
          <h1 className="text-accent">Oopaa...</h1>
          <p className="text-accent">Temos páginas tão interessantes e calhaste logo nesta.<br/>Sem querer ser desmancha prazeres, parece que esta página não existe.</p>
          <Link to={ROUTES.HOME} className="btn btn-primary-ghost">Voltar ao início</Link>
        </div>
      </section>
  </div>
  );
};

export default ErrorPage
