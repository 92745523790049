import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getDataBySlug } from "scripts/utils/Utils";

import { useSelector, useDispatch } from "react-redux";
import { fetchBoxes } from "controller/redux/HomeSlice";
import BoxFeaturesComponent from "./BoxFeaturesComponent";

import { GalleryComponent } from "render/views/components/GaleryComponent";
import { useDelayedLoading } from "controller/hooks/hooks";
import SwipperComponent from "render/views/components/SwipperComponent";
import { SwiperSlide } from "swiper/react";

function BoxDetailPage() {
  const { slug } = useParams();

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const handleImageClick = (index) => {
    setGalleryOpen(true);
    setGalleryIndex(index);
  };

  const handleCloseGallery = () => {
    setGalleryOpen(false);
  };

  const { boxes, isDataFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();

  const box = getDataBySlug(boxes, slug)

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchBoxes())
    }
  }, [dispatch, isDataFetched]);

  return useDelayedLoading(box)(() => {
    return (
      <div id="page-container" page="box">
        <Helmet>
          <title>Sthenos | {slug.charAt(0).toUpperCase() + slug.slice(1)} </title>
          <meta name="description" content="Vem conhecer a nossa Box!" />
          <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
        </Helmet>
        {box && Object.keys(box).length > 0 ? (
          <div>
            <div className="page-header-image-l">
              <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
            </div>

            <section id="box-detail">
              <div className="container-m">
                <h1 className="page-title text-xxxl">{box.name}</h1>
                <div id="box-detail--video-container">
                  <video src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/boxes/${box.slug}/sthenos-fit-boxs-${box.slug}.mp4`} loop={true} muted={true} autoPlay playsInline />
                </div>

                <hr className="hr-l" />

                <div id="box-detail--info">
                  <p className="text-description-xxl text-accent">{box.description_pt}</p>
                  <div>
                    <div className="box-detail-info-item">
                      <h6 className="box-detail-info-item--label">Localização</h6>
                      <p>
                        <a className="inline-link underline-on-hover-only" href={box.addressMap}>
                          {box.address}
                          <br />
                          {box.addressZipCode}
                        </a>
                      </p>
                    </div>
                    <div className="box-detail-info-item">
                      <h6 className="box-detail-info-item--label">Horário</h6>
                      <p>{box.scheduleDays}<br />{box.scheduleTime}</p>
                    </div>
                    <div className="box-detail-info-item">
                      <h6 className="box-detail-info-item--label">Contactos</h6>
                      <p>
                        <a className="inline-link underline-on-hover-only" href={`tel:${box.phone}`}>
                          {box.phone}
                        </a>
                        <br />
                        <a className="inline-link underline-on-hover-only" href={`mailto:${box.email}`}>
                          {box.email}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="hr-xxl" />

                <ul id="box-detail--features">
                  <BoxFeaturesComponent zones={box.zones}></BoxFeaturesComponent>
                </ul>

                <hr className="hr-xxl" />


                <section id="box-detail--gallery">
                  <section className="container-m">
                    <h2 className="section-title light-bg text-center">Espaço para dares tudo</h2>
                  </section>
                  <section className="container">
                    <SwipperComponent>
                      {box.pictures.map((picture, index) => (
                        <SwiperSlide key={index}>
                          <div className="box-image-item">
                            <img onClick={() => handleImageClick(index)} src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/boxes/${slug}/${picture}`} alt='' />
                          </div>
                        </SwiperSlide>
                      ))}
                    </SwipperComponent>
                  </section>
                </section>
                <GalleryComponent
                  pictures={box.pictures}
                  basePath={`${process.env.REACT_APP_MEDIA_BASE_PATH}/boxes/${slug}`}
                  initialIndex={galleryIndex}
                  isOpen={isGalleryOpen}
                  onClose={handleCloseGallery}
                />
              </div>
            </section>
          </div>
        ) : null
        }

      </div>
    );
  });
}

export default BoxDetailPage