import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function SwipperComponent({ slides, children, component: Component, propName, settings, props }) {

    const defaultSettings = {
        modules: [Autoplay, Navigation, Pagination, A11y],
        spaceBetween: 10,
        slidesPerView: 'auto',
        // centeredSlides: true,
        loop: false,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
        },
        navigation: true,
        pagination: { clickable: true },
        scrollbar: { draggable: true },
    };

    const combinedSettings = {
        ...defaultSettings,
        ...settings,
    };

    return (
        <Swiper {...combinedSettings}>
            {slides ? slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    {Component ? <Component {...{ [propName]: slide }} {...props} /> : children}
                </SwiperSlide>
            )) : children}
        </Swiper>
    );
};

export default SwipperComponent;
