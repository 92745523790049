import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LAZY_LOADING_ITEMS } from "scripts/Constants";

import InfiniteLoaderComponent from "render/views/components/InfiniteLoaderComponent";

function EbookComponent({ ebooks }) {
  const [count, setCount] = useState(LAZY_LOADING_ITEMS);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setCount(count => count + LAZY_LOADING_ITEMS);
      setIsLoading(false);
    }, 500);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={count}
        next={loadMore}
        scrollThreshold={0.5}
        hasMore={count < ebooks.length}
        style={{ overflow: 'hidden' }}
      >
        <div className="grid-2 gap-l-on-breakpoint-l gap-s-on-breakpoint-xs grid-1-on-breakpoint-xxs gap-l-on-breakpoint-xxs">
          {ebooks.map((ebook, index) => (
            <Link to={`${ROUTES.EBOOKS}/${ebook.slug}`} className={`component-item ${ebook.slug === "planche" ? "box-item disabled" : "ebook-item"}`} key={index}>
              <figure>
                <div className="bg-gray image-container" ratio="1:1">
                  <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/ebooks/sthenos-fit-ebook-${ebook.slug}.jpg`} alt='' />
                </div>
                <figcaption>
                  <h4>{ebook.name}</h4>
                  {ebook.slug === "planche" && (
                    <h6 className="text-white-offset">Brevemente Disponivel</h6>
                  )}
                </figcaption>
              </figure>
              {ebook.slug != "planche" && (
                <div className="component-item--cta-container">
                  <div className="component-item--cta-arrow-container">
                    <span></span><span></span><span></span>
                    <svg className="arrow-head" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 10 15" }} viewBox="0 0 10 15">
                      <path d="M3.3.2.4 3.1l4.3 4.4-4.3 4.4 2.9 2.9 6.3-6.3v-2z" />
                    </svg>
                  </div>
                  <p className="text-white-offset">Ver detalhes sobre o Ebook</p>
                </div>
              )}
            </Link>
          ))
          }
        </div>
      </InfiniteScroll>
      {isLoading && (
        <InfiniteLoaderComponent></InfiniteLoaderComponent>)
      }
    </>
  );
}

export default EbookComponent;