import React from "react";

function InfiniteLoaderComponent() {
  return (
    <div className="infinite-loader-container">
          <div className="infinite-loader">
            <span>a carregar mais<span className="loading-dots">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span></span>
            <div className="loading--animation">
            </div>
          </div>
      </div>
  );
}

export default InfiniteLoaderComponent;