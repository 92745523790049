import React, { useEffect } from "react"
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import { useWindowDimensions, useDelayedLoading } from 'controller/hooks/hooks';

import BodyPartsComponent from "render/views/components/BodyPartsComponent";
import { getDataBySlug, getRelated } from "scripts/utils/Utils";
import ExerciseComponent from 'render/views/pages/Exercises/ExerciseComponent';

import { useSelector, useDispatch } from "react-redux"
import { fetchExercises } from "controller/redux/HomeSlice";
import VideoPlayer from "render/views/components/VideoPlayer";

function ExerciseDetails() {
  const dimensions = useWindowDimensions();

  const { slug } = useParams();
  const { exercises, isDataFetched } = useSelector((state) => state.home)
  const error = useSelector((state) => state.home.error);

  const dispatch = useDispatch();

  const exercise = getDataBySlug(exercises, slug);

  const relatedExercises = getRelated(exercises, exercise, 5);

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchExercises())
    }
  }, [dispatch, isDataFetched]);

  return useDelayedLoading(exercise)(() => {
    return (
      <div id="page-container" page="exercise-list">
        <Helmet>
          <title>Sthenos | {slug.charAt(0).toUpperCase() + slug.slice(1)}</title>
          
          
          
        </Helmet>
        {((exercise && exercise.slug === slug) && Object.keys(exercise).length !== 0 && exercises && exercises.length !== 0) ? (
          <div>
            <section id="exercise-detail">
              <div className="container">
                <div className="page-header-no-image">
                  {/* <h2 className="section-title justify-start">{exercise.name}</h2> */}
                  <Link to={ROUTES.EXERCISES} className="btn inline-link has-icon text-gray"><i className="icon-ml icon-chevron-left"></i><span>Voltar a todos os exercícios</span></Link>
                  <hr className="hr-s" />
                  <h1 className="section-title text-white-offset">{exercise.title}</h1>
                </div>

                <div className="page-header-no-image space-maker">
                  {/* <h2 className="section-title justify-start">{exercise.name}</h2> */}
                  <Link to={ROUTES.EXERCISES} className="btn inline-link has-icon text-gray"><i className="icon-ml icon-chevron-left"></i><span>Voltar a todos os exercícios</span></Link>
                  <hr className="hr-s" />
                  <h1 className="section-title text-white-offset">{exercise.title}</h1>
                </div>

                <div id="exercise-detail--main">
                  <div id="exercise-info">
                    <div className="flex-row gap-xl flex-column-on-breakpoint-xxs gap-xxs-on-breakpoint-xxs">
                      <div className="exercise-detail">
                        <p className="exercise-detail--label">Dificuldade:</p>
                        <h4 id="exercise-detail--name">{exercise.difficulty}</h4>
                      </div>
                      <div className="exercise-detail">
                        <p className="exercise-detail--label">Categoria:</p>
                        <h4 id="exercise-info--name">{exercise.category}</h4>
                      </div>
                    </div>

                    <hr />

                    <div id="exercise-info--bodyparts" className="flex-row align-end gap-l flex-column-on-breakpoint-xxs gap-s-on-breakpoint-xxs align-start-on-breakpoint-xxs">
                      <BodyPartsComponent bodyParts={exercise.bodyParts} />
                    </div>
                  </div>

                  {exercise.slug &&
                    <VideoPlayer src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/exercises/${exercise.slug}/sthenos-fit-exercise-${exercise.slug}.mp4`} options={{
                      autoplay: false, controls: true, muted: true, loop: false, controlBar: {
                        volumePanel: false
                      }
                    }} />}

                </div>
              </div>
            </section>

            <section id="exercise-description" className="container-sm">
              <div id="exercise-description--bodyparts" className="flex-column gap-l">
                <BodyPartsComponent bodyParts={exercise.bodyParts} />
              </div>
              <div>
                <p className="text-description-xxl text-accent">{exercise.description_pt}</p>
              </div>
            </section>

            <hr className="hr-xxl" />

            <section>
              <div className="container-m">
                <h2 className="uppercase text-white-offset text-center">Há mais<br />exercícios<br />a fazer</h2>
                <hr className="hr-l" />
                <hr className="hr-xl vertical-line" />
                <hr className="hr-s" />
              </div>
            </section>

            <section className="container-m" id="related-exercises">
              <div className="flex-row justify-center align-center text-center" id="related-exercises--title">
                <h2 className="section-title">Como estes</h2>
                <Link to={ROUTES.EXERCISES} className="btn inline-link text-white">Ver lista de todos os exercícios</Link>
              </div>
              <ExerciseComponent exercises={relatedExercises} />
            </section>

            <hr className="hr-l" />
            <hr className="hr-xxxl vertical-line relative z-index-1" />
            <hr className="hr-xl vertical-line relative z-index-1" />
            <hr className="hr-xl" />

            <section id="exercises-training-plan">
              {/* <section>
                <section className="container-m expand-to-left" style={{ '--window-width': `${dimensions.width}px` }}>
                  <div className="images-container">
                    <div className="image-container">
                      <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/auxiliary/sthenos-fit-training-plan-phone-mockup-2.png`} alt="" />
                    </div>
                    <div className="image-container">
                      <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/auxiliary/sthenos-fit-training-plan-phone-mockup-2.png`} alt="" />
                    </div>
                    <div className="image-container">
                      <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/auxiliary/sthenos-fit-training-plan-phone-mockup-1.png`} alt="" />
                    </div>
                  </div>
                  <div>
                    <h2 className="title-font-medium line-height-1 uppercase">Gostavas<br />de ter<br />um treino<br />completo?</h2>
                    <hr className="hr-m" />
                    <p className="text-description-xl text-gray-light">Basta te registares e ganhas acesso a alguns planos de treino feitos especificamente a pensar em calistenia.</p>
                    <hr className="hr-m" />
                    <Link to={ROUTES.TRAINING} className="btn btn-invert btn-primary-ghost">Ver planos de treino</Link>
                  </div>
                </section>
              </section> */}

              <hr className="hr-xxl" />

              <section id="time-to-move">
                <div className="container-m">
                  <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-time-to-move.png`} />
                  <h1 className="uppercase text-center text-gray-lightest">Não percas mais tempo a fazer scroll.</h1>
                  {/* <hr className="hr-xl"/>
                  <h2 className="uppercase text-center text-accent text-outline">Mexe-te e junta-te<br/>a nós nos treinos.</h2> */}
                  <hr className="hr-xxxl" />
                </div>
              </section>
            </section>
          </div>) :
          null
          }
      </div>
    );
  });
}


export default ExerciseDetails;