export const LOCAL_STORAGE_ROUTES = {
    IS_LOGGED_IN: 'isLoggedIn',
    CURRENT_USERNAME: 'loggedInUsername',
    CURRENT_USERNAME_NAME: 'loggedInUsername_name',
    SHOPPING_CART: 'shoppingCart',
    TOKEN: 'token',
};

export const MAX_ITEMS_HOME = 4;
export const LAZY_LOADING_ITEMS = 5;
export const LOADING_TIME = 1000;
export const HOME_MIN_WIDTH_SLIDER = 1150;

export const HASH_SECRET_KEY = "kikofrango";

export const SUBSCRIPTIONS_TYPE = {
    ONE_SHOT: 'one-shot',
    PACK: 'pack',
}

export const WEEK_DAYS = ['2a', '3a', '4a', '5a', '6a', 'Sab', 'Dom'];

export const SHOP_COLORS = ['black', 'white', 'red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'gray'];
export const SHOP_SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];

export const ONLINE_SUBSCRIPTIONS = {
    TIER_1: 'plano-zero',
    TIER_2: 'plano-on',
    TIER_3: 'plano-pro',
}

export const BODYPARTS_MAP = {
    "upper-trapezius": "Trapézio Superior",
    "middle-trapezius": "Trapézio Médio",
    "lower-trapezius": "Trapézio Inferior",
    "latissimus-dorsi": "Dorso (Lats)",
    "teres-major": "Teres Major",
    "rhomboid": "Romboide",
    "gluteus": "Glúteos",
    "hamstrings": "Biceps Femoral (Hamstrings)",
    "quadriceps": "Quadriceps",
    "calves": "Gémeos",
    "anterior-deltoid": "Ombros Frontais",
    "middle-deltoid": "Ombros Laterais",
    "posterior-deltoid": "Ombros Anteriores",
    "upper-chest": "Peitoral Superior",
    "middle-chest": "Peitoral Central",
    "lower-chest": "Peitoral Inferior",
    "biceps": "Biceps",
    "brachialis": "Braquial",
    "triceps": "Triceps",
    "forearm": "Antebraço",
    "upper-abs": "Abdominais Superiores",
    "middle-abs": "Abdominais Centrais",
    "lower-abs": "Abdominais Inferiores",
    "obliques": "Oblíquos"
};

export const BODYPARTS_GROUPS = {
    'Ombros': ['Trapézio Superior', 'Ombros Frontais', 'Ombros Laterais', 'Ombros Anteriores'],
    'Costas': ['Trapézio Médio', 'Trapézio Inferior', 'Dorso (Lats)', 'Teres Major', 'Romboide'],
    'Pernas': ['Glúteos', 'Biceps Femoral (Hamstrings)', 'Quadriceps', 'Gémeos'],
    'Peitoral': ['Peitoral Superior', 'Peitoral Central', 'Peitoral Inferior'],
    'Biceps': ['Biceps', 'Braquial'],
    'Triceps': ['Triceps'],
    'Antebraço': ['Antebraço'],
    'Abdominais': ['Abdominais Superiores', 'Abdominais Centrais', 'Abdominais Inferiores', 'Oblíquos'],
    'Obliquos': ['Oblíquos'],
    'Gluteos': ['Glúteos'],
    'Posteriors': ['Biceps Femoral (Hamstrings)'],
    'Quadriceps': ['Quadriceps'],
    'Full Body': [
        'Trapézio Superior', 'Ombros Frontais', 'Ombros Laterais', 'Ombros Anteriores', 'Trapézio Médio',
        'Trapézio Inferior', 'Dorso (Lats)', 'Teres Major', 'Romboide', 'Glúteos', 'Biceps Femoral (Hamstrings)',
        'Quadriceps', 'Gémeos', 'Peitoral Superior', 'Peitoral Central', 'Peitoral Inferior', 'Biceps', 'Braquial',
        'Triceps', 'Antebraço', 'Abdominais Superiores', 'Abdominais Centrais', 'Abdominais Inferiores', 'Oblíquos',
        'Glúteos', 'Biceps Femoral (Hamstrings)', 'Quadriceps'
    ]
};

export const EXERCISES_LEVEL = [
    'Iniciante',
    'Intermedio',
    'Avançado',
    'Hardcore',
];

export const EXERCISES_GEAR = [
    'Sem equipamento',
    'Barbell',
    'Dumbbell',
    'Rings',
    'Dips bar',
    'Parallettes',
    'Pull up Bar'
];

export const EXERCISES_GROUP = [
    'Push',
    'Pull',
    'Legs',
    "Abdominais",
    "Full Body"
];
