import React from "react"
import { Helmet } from 'react-helmet';

function TermsConditionsPage() {
  return (
    <div id="page-container" page="terms-and-conditions">
      <Helmet>
        <title>Sthenos | Termos e Condiçoes </title>
      </Helmet>
      <div className="container-m page-header-no-image">
        <h1 className="section-title text-accent text-center">
          <span>Termos e Condições</span>
        </h1>
        <hr className="hr-xl"/>
      </div>
      <section className="bg-white-offset">
        <section className="container-s">
          <hr className="hr-xl"/>
          <p className="text-l text-accent">
            Bem-vindo ao nosso site. Ao acessar e utilizar o nosso site, você concorda em cumprir estes termos e condições de uso. Se você não concorda com estes termos e condições, não utilize o nosso site.
          </p>
          <hr className="hr-l"/>
          <h4>Propriedade do site</h4>
          <hr className="hr-s"/>
          <p>Este site é de propriedade exclusiva da Calistenia Portugal, e todo o conteúdo apresentado no site, incluindo imagens, texto, gráficos, logotipos e ícones, são de propriedade da Calistenia Portugal ou de seus fornecedores e parceiros.</p>
          <hr className="hr-l"/>

          <h4>Uso do site</h4>
          <hr className="hr-s"/>
          <p>Ao utilizar este site, você concorda em não copiar, reproduzir, distribuir, transmitir, exibir, publicar, vender, licenciar, modificar ou criar trabalhos derivados de qualquer parte do site, a menos que autorizado pela Calistenia Portugal. Você também concorda em não utilizar o site para fins ilegais ou proibidos.</p>
          <hr className="hr-l"/>

          <h4>Conteúdo gerado pelo usuário</h4>
          <hr className="hr-s"/>
          <p>Ao postar ou enviar conteúdo para o nosso site, você concorda em não enviar material que seja ilegal, difamatório, abusivo, ameaçador, ofensivo, prejudicial, obsceno, pornográfico, profano ou que viole a propriedade intelectual de terceiros. A Calistenia Portugal se reserva o direito de remover qualquer conteúdo que viole estes termos e condições.</p>
          <hr className="hr-l"/>

          <h4>Propriedade intelectual</h4>
          <hr className="hr-s"/>
          <p>Todo o conteúdo deste site é protegido por leis de propriedade intelectual, incluindo marcas registradas, direitos autorais e patentes. Qualquer uso não autorizado de qualquer parte do site pode violar as leis de propriedade intelectual.</p>
          <hr className="hr-l"/>

          <h4>Isenções de responsabilidade</h4>
          <hr className="hr-s"/>
          <p>Este site é fornecido "como está", sem garantias de qualquer tipo, expressas ou implícitas. A Calistenia Portugal não garante que o site seja livre de erros ou interrupções ou que o conteúdo seja preciso, confiável ou atualizado. A Calistenia Portugal não será responsável por quaisquer danos, diretos ou indiretos, decorrentes do uso ou incapacidade de uso do site.</p>
          <hr className="hr-l"/>

          <h4>Rescisão</h4>
          <hr className="hr-s"/>
          <p>A Calistenia Portugal pode rescindir o acesso de um usuário ao site a qualquer momento, sem aviso prévio, se o usuário violar estes termos e condições.</p>
          <hr className="hr-l"/>

          <h4>Lei aplicável</h4>
          <hr className="hr-s"/>
          <p>Estes termos e condições serão regidos e interpretados de acordo com as leis de Portugal. Qualquer disputa decorrente ou relacionada a estes termos e condições será submetida à jurisdição exclusiva dos tribunais de Portugal.</p>
          <hr className="hr-l"/>

          <h4>Alterações aos termos e condições</h4>
          <hr className="hr-s"/>
          <p>A Calistenia Portugal se reserva o direito de alterar estes termos e condições a qualquer momento, sem aviso prévio. O uso continuado do site após a publicação de tais alterações constitui aceitação dos termos e condições atualizados.</p>
          <p>Ao utilizar este site, você concorda em cumprir estes termos e condições. Se você tiver alguma dúvida ou comentário sobre estes termos e condições, entre em contato conosco.</p>
        </section>

        <hr className="hr-xl"/>

        <div className="container-m">
          <h2 className="section-title text-accent text-center">Política de Privacidade</h2>
        </div>
        <hr className="hr-l"/>
        <section className="container-s">
          <p className="text-l text-accent">
            A privacidade dos usuários do nosso site é importante para nós. Esta política de privacidade descreve como coletamos, usamos e protegemos as informações pessoais dos usuários. Ao utilizar este site, você concorda com a coleta e uso de suas informações pessoais conforme descrito nesta política de privacidade.
          </p>
          <hr className="hr-xl"/>
          <h4>Informações coletadas</h4>
          <hr className="hr-s"/>
          <p>Podemos coletar informações pessoais, como nome, endereço de e-mail, endereço residencial, número de telefone e informações de pagamento, quando fornecidas voluntariamente pelos usuários do site. Além disso, podemos coletar informações de uso do site, incluindo informações sobre o navegador, sistema operacional, endereço IP, data e hora de acesso e páginas visitadas.</p>
          <hr className="hr-l"/>

          <h4>Uso das informações coletadas</h4>
          <hr className="hr-s"/>
          <p>Podemos utilizar as informações pessoais coletadas para fins de comunicação com os usuários, como responder a dúvidas ou fornecer informações sobre produtos e serviços. Também podemos utilizar as informações pessoais para fins de marketing, como enviar newsletters ou promoções. As informações de uso do site podem ser utilizadas para melhorar o site e personalizar a experiência do usuário.</p>
          <hr className="hr-l"/>

          <h4>Compartilhamento de informações</h4>
          <hr className="hr-s"/>
          <p>Não compartilhamos informações pessoais com terceiros, exceto quando necessário para fornecer um produto ou serviço solicitado pelo usuário. Também podemos compartilhar informações pessoais em caso de solicitação de autoridades legais ou em caso de violação destes termos e condições.</p>
          <hr className="hr-l"/>

          <h4>Proteção das informações</h4>
          <hr className="hr-s"/>
          <p>Tomamos medidas de segurança para proteger as informações pessoais dos usuários, incluindo o uso de criptografia e medidas de segurança física. No entanto, não podemos garantir a segurança absoluta das informações transmitidas pela Internet.</p>
          <hr className="hr-l"/>

          <h4>Cookies</h4>
          <hr className="hr-s"/>
          <p>Podemos utilizar cookies para personalizar a experiência do usuário e fornecer informações sobre o uso do site. Os usuários podem optar por desativar os cookies nas configurações do navegador.</p>
          <hr className="hr-l"/>

          <h4>Alterações nesta política de privacidade</h4>
          <hr className="hr-s"/>
          <p>Podemos alterar esta política de privacidade a qualquer momento, sem aviso prévio. A utilização do site após a publicação de tais alterações constitui aceitação da política de privacidade atualizada.</p>
          <hr className="hr-l"/>

          <h4>Contato</h4>
          <hr className="hr-s"/>
          <p>Se você tiver alguma dúvida ou comentário sobre esta política de privacidade, entre em contato conosco.</p>
          <p>Ao utilizar este site, você concorda com a coleta e uso de suas informações pessoais conforme descrito nesta política de privacidade.</p>
        </section>

        <hr className="hr-xxl hr-xl-on-breakpoint-s"/>

      </section>
    </div>
  );
};

export default TermsConditionsPage
