import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'api/SthenosAPI';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useLocation } from "react-router-dom";
import { handlePhoneInputChange, handleZipCodeInputChange } from 'scripts/utils/Utils';
import { set } from 'lodash';

function AddressComponent({ initialAddresses, token, onAddressSelect, onAddressesChange }) {

  const location = useLocation();
  const [localAddresses, setLocalAddresses] = useState(initialAddresses);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    addressName: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    phone: ''
  });

  useEffect(() => {
    if (localAddresses.length === 1) {
      const singleAddress = localAddresses[0];
      setSelectedAddress(singleAddress.addressName);
      if (onAddressSelect) {
        onAddressSelect(singleAddress);
      }
    }
  }, [localAddresses, onAddressSelect]);

  const handleAddressClick = (address) => {
    setSelectedAddress(address.addressName);
    if (onAddressSelect) {
      onAddressSelect(address);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      handlePhoneInputChange(e, (newValue) => {
        setNewAddress(prevState => ({
          ...prevState,
          phone: newValue,
        }));
      });
    } else if (name === 'postalCode') {
      handleZipCodeInputChange(e, (newValue) => {
        setNewAddress(prevState => ({
          ...prevState,
          postalCode: newValue,
        }));
      });
    } else {
      setNewAddress(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddAddressClick = () => {
    setShowAddAddressForm(!showAddAddressForm);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const updatedAddresses = localAddresses.filter((_, idx) => idx !== deleteIndex);
    try {
      axios.put(API_ENDPOINTS.CREATE_PROFILE, {
        token,
        addresses: updatedAddresses
      }).then(() => {
        toast.success("Morada apagada com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        setLocalAddresses(updatedAddresses);
        onAddressesChange(updatedAddresses)
        setShowDeleteDialog(false);
      });
    } catch (error) {
      toast.error("Erro a apagar morada", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isDuplicate = localAddresses.some(addr => addr.address === newAddress.address && addr.postalCode === newAddress.postalCode && addr.city === newAddress.city && addr.country === newAddress.country);

    if (isDuplicate) {
      toast.error("Já tens essa morada adicionada!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      return;
    }

    try {
      const updatedProfile = {
        token,
        addresses: [...localAddresses, newAddress]
      };

      await axios.put(API_ENDPOINTS.CREATE_PROFILE, updatedProfile);
      setLocalAddresses([...localAddresses, newAddress]);
      if (onAddressesChange) {
        onAddressesChange([...localAddresses, newAddress]);
      }
      setShowAddAddressForm(false);
      setNewAddress({ addressName: '', address: '', postalCode: '', city: '', country: '', phone: '' });
      toast.success("Morada adicionada com sucesso!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error)
      toast.error("Erro a Adicionar morada", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  const handleCancel = async (e) => {
    setShowAddAddressForm(false);
  }
  useEffect(() => {
    setLocalAddresses(initialAddresses);
  }, [initialAddresses]);


  if (!localAddresses) {
    return <div>Loading...</div>;
  }

  const handleDeleteConfirmation = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteDialogHide = () => {
    setShowDeleteDialog(false);
  };

  const deleteDialogFooter = (
    <>
      <Button label="Cancelar" className="p-button-text btn btn-s inline-link text-gray-dark" onClick={handleDeleteDialogHide} />
      <Button label="Apagar" className="p-button-text btn btn-primary-fill" onClick={handleDelete} autoFocus />
    </>
  );

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      handlePhoneInputChange(e, (newValue) => {
        setEditingAddress(prev => ({
          ...prev,
          phone: newValue,
        }));
      });
    } else if (name === 'postalCode') {
      handleZipCodeInputChange(e, (newValue) => {
        setEditingAddress(prev => ({
          ...prev,
          postalCode: newValue,
        }));
      });
    } else {
      setEditingAddress(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    if (editIndex != null) {
      const updatedAddresses = [...localAddresses];
      updatedAddresses[editIndex] = editingAddress;

      try {
        const updatedProfile = {
          token,
          addresses: updatedAddresses,
        };
        await axios.put(API_ENDPOINTS.CREATE_PROFILE, updatedProfile);

        setLocalAddresses(updatedAddresses);
        onAddressesChange(updatedAddresses);
        toast.success("Morada atualizada com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
      } catch (error) {
        toast.error("Erro a atualizar morada", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }

      setEditIndex(null);
      setEditingAddress(null);
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditingAddress({ ...localAddresses[index] });
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditingAddress(null);
  };


  return (
    <>
      <Dialog visible={showDeleteDialog} header="Apagar morada?" modal footer={deleteDialogFooter} onHide={handleDeleteDialogHide}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {deleteIndex != null && <span>Depois de apagar a morada, não é possível recuperar. Tens a certeza que queres continuar e apagar a morada?</span>}
        </div>
      </Dialog>
      <div className="grid-2 gap-s grid-1-on-breakpoint-xs" addresses-count={localAddresses.length} location={location.pathname}>
        {!showAddAddressForm && (
          <>
            {location.pathname != "/checkout" ? (
              <button className="profile-address-item" type='new' onClick={handleAddAddressClick}>
                <i className="icon-add-circle"></i>
                <span>Criar nova morada</span>
              </button>
            ) : (
              <>
                <p className="profile-address-item" type='new'>Escolhe a morada de entrega carregando nela ou <span className="inline-link" onClick={handleAddAddressClick}>cria uma nova morada</span></p>
              </>
            )}
          </>
        )}
        {showAddAddressForm && (
          <>
            {location.pathname == "/checkout" && <hr className="hr-xs col-2" />}

            <form className="form-s white-bg profile-address-item" type="editing">
              <p className="text-s">Nova Morada</p>
              <input type="text" name="address" placeholder="Morada" value={newAddress.address} onChange={handleInputChange} />
              <input type="text" name="addressName" placeholder="Nome" value={newAddress.addressName} onChange={handleInputChange} />
              <div className="grid-2 gap-xxs">
                <input type="text" name="postalCode" placeholder="Codigo-Postal" value={newAddress.postalCode} onChange={handleInputChange} />
                <input type="text" name="city" placeholder="Cidade" value={newAddress.city} onChange={handleInputChange} />
                <input type="text" name="country" placeholder="Pais" value={newAddress.country} onChange={handleInputChange} />
                <input type="text" name="phone" placeholder="Telefone" value={newAddress.phone} onChange={handleInputChange} />
              </div>
              <hr className="hr-xs" />
              <div className="flex-row align-center justify-between">
                <button className="btn btn-s inline-link underline-on-hover-only" onClick={handleCancel}>Cancelar</button>
                <button className="btn btn-s btn-primary-fill" onClick={handleSubmit}><span>Adicionar</span><i className="icon-add-circle"></i></button>
              </div>
            </form>
          </>
        )}
        {localAddresses.map((address, index) => (
          <div key={index}>
            {editIndex === index ? (
              <form onSubmit={(e) => handleSubmitEdit(e, index)} className={`profile-address-item form-s white-bg ${(selectedAddress === address.addressName || localAddresses.length == 1) ? 'selected' : ''}`} type="editing">
                <p className="text-s">Editar Morada</p>
                <input type="text" name="address" placeholder="Morada" value={editingAddress?.address || ''} onChange={handleEditInputChange} />
                <input type="text" name="addressName" placeholder="Nome" value={editingAddress?.addressName || ''} onChange={handleEditInputChange} />
                <div className="grid-2 gap-xxs">
                  <input type="text" name="postalCode" placeholder="Codigo-Postal" value={editingAddress?.postalCode || ''} onChange={handleEditInputChange} />
                  <input type="text" name="city" placeholder="Cidade" value={editingAddress?.city || ''} onChange={handleEditInputChange} />
                  <input type="text" name="country" placeholder="Pais" value={editingAddress?.country || ''} onChange={handleEditInputChange} />
                  <input type="text" name="phone" placeholder="Telefone" value={editingAddress?.phone || ''} onChange={handleEditInputChange} />
                </div>
                <hr className="hr-xs" />
                <div className="flex-row align-center justify-between">
                  <button className="btn btn-s inline-link underline-on-hover-only" onClick={handleCancelEdit}>Cancelar</button>
                  <button type="submit" className='btn btn-s btn-primary-fill'>Gravar</button>
                </div>
              </form>
            ) : (
              <div key={index} className={`profile-address-item ${(selectedAddress === address.addressName || localAddresses.length == 1) ? 'selected' : ''}`} onClick={() => handleAddressClick(address)}>
                {(selectedAddress === address.addressName || localAddresses.length == 1) &&
                  <div className="align-center gap-xs"><i className="icon-delivery"></i><span className="text-s">Escolhida como morada de envio</span></div>
                }
                <div>
                  <h5>{address.address}</h5>
                  <span>{address.addressName}</span>
                  <span>{address.postalCode}, {address.city}</span>
                  <span>{address.country}</span>
                  <span>{address.phone}</span>
                </div>
                <div className="mt-s">
                  <button className="btn btn-s btn-primary-fill" onClick={() => handleEditClick(index)}>Editar</button>
                  {!onAddressSelect && <button className="btn btn-s btn-primary-ghost btn-invert" onClick={() => { setDeleteIndex(index); handleDeleteConfirmation(); }}><span>Apagar</span><i className="icon-delete"></i></button>}
                </div>
              </div>
            )}
          </div>
        ))}

      </div>
    </>
  );
}

export default AddressComponent;