import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from 'controller/routes/routes';

function BoxComponent({ boxes }) {

  return (
    <>
      {boxes.map((box, index) => (
        <Link to={box.disabled ? '#' : `${ROUTES.BOX}/${box.slug}`}
          className={`component-item box-item${box.disabled ? ' disabled' : ''}`}
          key={index}>
          <figure>
            <div className="bg-gray image-container" ratio="1:1">
              <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/boxes/${box.slug}/sthenos-fit-boxs-${box.slug}-main.jpg`} alt='' />
            </div>
            <figcaption>
              {box.disabled && (
                <h6 className="text-white-offset">Abre brevemente</h6>
              )}
              <img className="box-item--logo" alt='Sthenos Box Logo' src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/brand/sthenos-logo-dourado-${box.slug}.png`}/>
            </figcaption>
          </figure>
          {box.disabled ? null : 
            <div className="component-item--cta-container">
              <div className="component-item--cta-arrow-container">
                <span></span><span></span><span></span>
                <svg className="arrow-head" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 10 15" }} viewBox="0 0 10 15">
                  <path d="M3.3.2.4 3.1l4.3 4.4-4.3 4.4 2.9 2.9 6.3-6.3v-2z" />
                </svg>
              </div>
              <p className="text-white-offset">Ver detalhes sobre a box</p>
            </div>
          }
        </Link>
      ))}
    </>
  );
}

export default BoxComponent;