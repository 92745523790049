import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { clearLocalData, getLocalData } from 'scripts/utils/Utils';

import { LOCAL_STORAGE_ROUTES } from 'scripts/Constants';

import { ROUTES } from 'controller/routes/routes';
import { useLoading } from 'controller/hooks/hooks';
import { useSelector } from 'react-redux';

import { auth } from 'firebase-config';
import { useDispatch } from 'react-redux';
import { fetchProfile } from 'controller/redux/ProfileSlice';

const ProfileModal = ({ props }) => {

    const { isProfileDataFetched, currentProfile } = useSelector((state) => state.profile);
    const dispatch = useDispatch();

    const { handleClose } = props;

    const [loading] = useLoading();
    const onLogout = async () => {
        try {
            await auth.signOut();
            clearLocalData();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!isProfileDataFetched) {
            dispatch(fetchProfile({ token: getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) }));
        }
    }, [dispatch, isProfileDataFetched]);

    if (!currentProfile || !currentProfile.email) {
        return;
    }

    return (
        <>
            {currentProfile && (
                <div className="modal-header">
                    <div className="profile-image-container">
                        <div className="image-container" ratio="1:1">
                            {currentProfile.photo ? (
                                <img src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/profile/${currentProfile.photo}`} alt="Profile" style={{ width: '100px', height: '100px' }} />
                            ) : (
                                <h4>{currentProfile.email.split('@')[0].split('')[0].charAt(0).toUpperCase()}</h4>
                            )}
                        </div>
                    </div>
                    <div className="flex-column gap-xxs">
                        <p>{currentProfile.name}</p>
                        <p className="text-s text-gray">{currentProfile.email}</p>
                    </div>
                    <div className="flex-row align-center justify-between">
                        <Link to={ROUTES.PROFILE} className='btn-s btn-primary-fill' onClick={handleClose}><span>Ver conta</span></Link>
                        <button className='btn-s btn-primary-ghost' state={loading ? "processing" : "idle"} disabled={loading} onClick={onLogout}><span>{loading ? 'Processing' : 'Logout'}</span></button>
                    </div>
                </div>
            )}
        </>
    );

};

export default ProfileModal;
