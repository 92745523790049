import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';

import BoxComponent from "render/views/pages/Box/BoxComponent";
import { applySearchFilters } from "scripts/utils/Utils";

import { useSelector, useDispatch } from "react-redux"
import LoadingPageComponent from "render/views/components/LoadingPageComponent";
import { fetchBoxes } from "controller/redux/HomeSlice";

function BoxesPage() {

  const { boxes, isDataFetched } = useSelector((state) => state.home)
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredBoxes = applySearchFilters(searchTerm, boxes, ["name", "description"])

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchBoxes())
    }
  }, [dispatch, isDataFetched]);

  return (
    <div id="page-container" page="box-list">
      <Helmet>
        <title>Sthenos | Boxes </title>
        <meta name="description" content="As nossas boxs" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container-m">
        <h1 className="page-title">Boxes</h1>
        <hr className="hr-m" />
        <section className="container-xs p-0">
        </section>
        <hr className="hr-xxl hr-l-on-breakpoint-m" />

        {/* <input
          type="text"
          style={{ color: "white" }}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder="Search for box..."
        /> */}

        <div className="grid-2 gap-l-on-breakpoint-m gap-m-on-breakpoint-s grid-1-on-breakpoint-xs">
          <BoxComponent boxes={filteredBoxes} />
        </div>

        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default BoxesPage;