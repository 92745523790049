import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet';

import ExerciseComponent from "render/views/pages/Exercises/ExerciseComponent";
import { useSelector, useDispatch } from "react-redux"
import LoadingPageComponent from "render/views/components/LoadingPageComponent";
import { fetchExercises } from "controller/redux/HomeSlice";
import FilterComponent from "render/views/components/FilterComponent";

function ExercisesPage() {

  const { exercises, isDataFetched } = useSelector((state) => state.home)
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.home.status)
  const { error } = useSelector((state) => state.home.status)

  const [filteredExercises, setFilteredExercises] = useState(exercises)

  function handleFilter(filtered) {
    setFilteredExercises(filtered)
  }

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchExercises())
    }
  }, [dispatch, isDataFetched]);

  if (status === 'loading') {
    return <LoadingPageComponent />
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="page-container" page="exercise-list">
      <Helmet>
        <title>Sthenos | Exercícios </title>
        
        
        
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container-m">
        <h1 className="page-title text-center">Exercícios</h1>
        <hr className="hr-xxl" />
        <>

          {exercises && filteredExercises &&
            <FilterComponent
              list={exercises}
              onFilter={handleFilter}
              type="exercise"
            />
          }
            <ExerciseComponent exercises={filteredExercises} />
        </>
        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default ExercisesPage;