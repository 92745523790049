import React from 'react';

const TrainerModal = ({ props }) => {

  const { trainer } = props;

  return (
    <div>
      <div className="flex-row align-center gap-m">
        <div className="image-container" ratio="1:1">
          <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/trainers/sthenos-${trainer.slug}.jpg`} alt='' />
        </div>
        <div>
          <h3 className="line-height-1">{trainer.name}</h3>
          <hr className="hr-xs" />
          <h6 className="text-accent">{trainer.role}</h6>
        </div>
      </div>
      <hr className="hr-l" />
      <p className="text-description-xl text-accent">{trainer.quote}</p>
      <hr className="hr-l" />
      <p className="text-s">{trainer.description_pt}</p>
      <hr className="hr-l" />
      <div className="flex-row gap-m">
        <div className="flex-column gap-xxs">
          <span className="text-s text-uppercase text-accent">Skill Favorito</span>
          <h6>{trainer.favoriteSkill}</h6>
        </div>
        <div className="flex-column gap-xxs">
          <span className="text-s text-uppercase text-accent">Exercício Favorito</span>
          <h6>{trainer.favoriteExercise}</h6>
        </div>
        <div className="flex-column gap-xxs">
          <span className="text-s text-uppercase text-accent">Guilty Pleasure</span>
          <h6>{trainer.guiltyPleasure}</h6>
        </div>
        <div className="flex-column gap-xxs">
          <span className="text-s text-uppercase text-accent">Música Favorita</span>
          <h6>{trainer.favoriteMusic}</h6>
        </div>
      </div>
    </div>
  );
};

export default TrainerModal;
