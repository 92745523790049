import React from "react";

function CourseModuleComponent({ module , state, onClick}) {

    if(state === "completed")
    return (
        <>
            <div className="course-module-item" data-lock="false" onClick={onClick} style={{ cursor: 'pointer' }}>
                <div className="flex-row align-center gap-xs">
                    <i className="icon-email"></i>
                    <h6 className="course-module-item--title">{module.name}</h6>
                </div>
                <div>
                    <span className="text-s text-gray">{module.duration} min</span>
                </div>
            </div>
        </>
    );
    else if(state==="started") 
    return (
        <>
            <div className="course-module-item" data-lock="false" onClick={onClick} style={{ cursor: 'pointer' }}>
                <div className="flex-row align-center gap-xs">
                    <i className="icon-play"></i>
                    <h6 className="course-module-item--title">{module.name}</h6>
                </div>
                <div>
                    <span className="text-s text-gray">{module.duration} min</span>
                </div>
            </div>
        </>
    );
    else
    return (
        <>
            <div className="course-module-item" data-lock="true">
                <div className="flex-row align-center gap-xs">
                    <i className="icon-lock"></i>
                    <h6 className="course-module-item--title">{module.name}</h6>
                </div>
                <div>
                    <div className="flex-row gap-xxxs text-s text-gray"><span>{module.duration}</span><span>min</span></div>
                </div>
            </div>
        </>
    );
}

export default CourseModuleComponent;