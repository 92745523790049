import React from "react";

import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";

function OnlineSubscriptionComponent({ subscription }) {

  const { handleShow, handleClose } = useModal();

  return (
    <>
        <div className="online-subscription-item">
          <div className="online-subscription-item-price-info">
            <h2 className="online-subscription-item-price-info--price">{subscription.cost}<sup>€</sup></h2>
            {subscription.costFrequency != null ?
              <h6 className="online-subscription-item-price-info--frequency">{subscription.costFrequency}</h6>
              : ''
            }
          </div>
          <div className="online-subscription-item-info">
            <h4 className="online-subscription-item-info--name">{subscription.name}</h4>
            <h6 className="online-subscription-item-info--type">apenas online</h6>
          </div>
          <p className="online-subscription-item--description">{subscription.description}</p>
          <button type="button" className="btn btn-primary-ghost btn-alt" onClick={() =>
                            handleShow(MODAL_TYPES.EXTERNAL_SITE,{handleClose,url: "https://e.3cket.com/calisteniaportugal"})}>Aderir</button>
        </div>
    </>
  );
}

export default OnlineSubscriptionComponent;