import { addItemToCart, fetchStoreItem, setStoreItem } from "controller/redux/StoreSlice";
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';

import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { getColorValue, getDataBySlug } from "scripts/utils/Utils";
import { SHOP_SIZES } from "scripts/Constants";
import { toast } from "react-toastify";
import { useModal, MODAL_TYPES } from "controller/providers/ModalContext";

const StoreItemDetails = () => {

  const dispatch = useDispatch();
  const { handleShow, handleClose } = useModal();
  const storeItem = useSelector(state => state.store.storeItem);
  const storeItems = useSelector(state => state.store.storeItems);
  const { slug } = useParams();


  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const [selectedImage, setSelectedImage] = useState(`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${storeItem.photo}`);

  const availableColors = storeItem?.inventory
    ? [...new Set(storeItem.inventory.map(item => item.color))]
    : [];

  const selectedStock = selectedColor && selectedSize
    ? storeItem.inventory
      ?.filter(item => item.color === selectedColor && item.size === selectedSize.toLocaleLowerCase())[0]?.stock
    : 0;

  const selectedPictures = selectedColor && selectedSize
    ? storeItem.inventory
      ?.filter(item => item.color === selectedColor && item.size === selectedSize.toLocaleLowerCase())[0]?.pictures
    : [];

  console.log(availableColors)

  const enabledSizes = selectedColor
    ? storeItem.inventory
      ?.filter(item => item.color === selectedColor && item.stock > 0)
      .map(item => item.size.toUpperCase())
    : [];

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setSelectedSize('');

    const inventoryItem = storeItem.inventory.find(item => item.color === color);
    if (inventoryItem) {
      setSelectedImage(`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${inventoryItem.photo}`);
    } else {
      setSelectedImage(`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${storeItem.photo || 't-shirt-featured-placeholder'}`);
    }
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };


  useEffect(() => {
    if (storeItems.length === 0) {
      dispatch(fetchStoreItem(slug));
    } else {
      dispatch(setStoreItem(getDataBySlug(storeItems, slug)));
    }
  }, [dispatch, slug, storeItems]);

  useEffect(() => {
    setSelectedImage(`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${storeItem.photo}`);
  }, [storeItem]);

  const handleAddToCart = () => {
    if (!selectedColor || !selectedSize) {
      if (availableColors.includes('no-color')) {
        setSelectedColor('no-color');
        setSelectedSize('no-size');
      } else {
        toast.error("Por favor, selecione a cor e o tamanho.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        return;
      }
    }

    const itemToAdd = {
      ...storeItem,
      selectedColor,
      selectedSize,
      selectedStock,
      selectedPictures,
    };

    console.log(storeItem)

    dispatch(addItemToCart(itemToAdd));
    handleShow(MODAL_TYPES.SHOPPING_CART, handleClose);
  };

  return (
    <div id="page-container" page="store">
      <Helmet>
        <title>Sthenos | {String(storeItem.name)} </title>
        <meta name="description" content={storeItem.description_pt} />
        <meta name="keywords" content="Sthenos, Merchandising" />
        <meta property="og:image" content={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${storeItem.photo}`} />
      </Helmet>
      {storeItem ? (
        <>
          <section id="store-detail">
            <div className="container grid-2 gap-xxl gap-xl-on-breakpoint-xxl gap-l-on-breakpoint-m grid-1-on-breakpoint-sm">
              <div className="page-header-no-image container">
                <div className="image-container" ratio="2:3">
                  <img
                    className="img-cover"
                    src={selectedImage}
                    alt="Selected Product View"
                  />
                </div>
              </div>

              <div className="page-header-no-image container">
                <Link to={ROUTES.STORE} className="btn inline-link has-icon text-gray"><i className="icon-ml icon-chevron-left"></i><span>Voltar à loja</span></Link>
                <hr className="hr-s" />
                <h1 className="uppercase text-gray-offset">{storeItem.name}</h1>

                <hr className="hr-m" />

                {storeItem.discount == null || storeItem.discount == 0 ? (
                  <h2 className="text-accent">{storeItem.price}€</h2>
                ) : (
                  <div className="flex-column">
                    <h5 className="text-gray line-through">{storeItem.price}€</h5>
                    <h2 className="text-accent">{storeItem.price - storeItem.discount}€</h2>
                  </div>
                )}

                <hr className="hr-m" />

                {
                  availableColors.length > 0 && (availableColors.length >= 1 && !availableColors.includes('no-color')) && (
                    <>
                      <div className="flex-row gap-l flex-column-on-breakpoint-ml gap-m-on-breakpoint-ml">
                        <div className="store-detail-options">
                          <p>Escolhe a cor</p>
                          <div>
                            {availableColors.map((color, index) => (
                              <button
                                className={selectedColor === color ? 'selected' : ''}
                                onClick={() => handleColorChange(color)}
                                key={index}
                                style={{ backgroundColor: getColorValue(color) }}
                              ></button>
                            ))}
                          </div>
                        </div>

                        <div
                          className="store-detail-options"
                          style={{ display: selectedColor ? 'flex' : 'none' }}
                        >
                          <p>Escolhe o tamanho</p>
                          <div>
                            {SHOP_SIZES.map((size, index) => (
                              <button
                                className={selectedSize === size ? 'selected' : ''}
                                onClick={() => handleSizeChange(size)}
                                key={index}
                                {...(enabledSizes.includes(size) ? {} : { disabled: true })}
                              >
                                {size}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>

                      <hr className="hr-xl hr-ml-on-breakpoint-ml" />
                    </>
                  )}

                <button className="btn-l btn-primary-fill" onClick={handleAddToCart} disabled={selectedSize == "" && !availableColors.includes('no-color')}>
                  {availableColors.length > 0 ? (
                    "Adicionar ao cesto"
                  ) : (
                    "Esgotado"
                  )}
                </button>
                <hr className="hr-l" />
                <hr className="horizontal-line bg-gray-lightest" />
                <hr className="hr-s" />
                <p>Detalhes:</p>
                <p className="text-gray">{storeItem.description_pt}</p>
                <hr className="hr-s" />
                <hr className="horizontal-line bg-gray-lightest" />
                <hr className="hr-s" />
                <p>Prazo estimado de estrega:</p>
                <p className="text-gray">Cerca de 4 dias</p>
                <hr className="hr-s" />
                <hr className="horizontal-line bg-gray-lightest" />
              </div>


            </div>
          </section>
        </>
      ) : (
        <div>No data found</div>
      )}

      <hr className="hr-xxl" />
    </div>
  );

};



export default StoreItemDetails