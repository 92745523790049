import React from "react";

function BodyPartsComponent({ bodyParts , details = true}) {

    const uniqueBodyParts = Array.from(new Set(bodyParts.map(bodypart => bodypart.name)));

    return (
        <>
            <div className="bodyparts">
                {bodyParts.map((bodypart, index) => (
                    <img alt="" key={index} src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/bodyparts/bodyparts-highlight-${bodypart.slug}.svg`} />
                ))}
                <img alt="" className="bodypart-base" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/bodyparts-base.svg`} />
            </div>
            { details && 
            <div className="exercise-detail">
                <p className="exercise-detail--label">Músculos exercitados:</p>
                <p className="exercise-detail--muscles-list">
                    {uniqueBodyParts.map((bodypart, index) => (
                        <span key={index}>{bodypart}{index === uniqueBodyParts.length - 1 ? '' : ', '}</span>
                    ))}
                </p>
            </div>}
            
        </>
    );

}

export default BodyPartsComponent;