import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LAZY_LOADING_ITEMS } from "scripts/Constants";

import InfiniteLoaderComponent from "render/views/components/InfiniteLoaderComponent";

function LessonComponent({ lessons, classStyle = "grid-4 gap-l gap-m-on-breakpoint-ml" }) {
  const [count, setCount] = useState(LAZY_LOADING_ITEMS);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setCount(count => count + LAZY_LOADING_ITEMS);
      setIsLoading(false);
    }, 500);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={count}
        next={loadMore}
        scrollThreshold={0.5}
        hasMore={count < lessons.length}
        style={{ overflow: 'hidden' }}
      >
        <div className={classStyle}>
          {lessons.map((lesson, index) => (
            <Link to={`${ROUTES.LESSONS}/${lesson.slug}`} className="component-item lesson-item" featured={lesson.featured.toString()} key={index}>
              <figure>
                <div className="bg-gray image-container" ratio="2:3">
                  <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/lessons/${lesson.slug}/sthenos-fit-lesson-${lesson.slug}.jpg`} alt='' />
                </div>
                <figcaption>
                  <h4>{lesson.title}</h4>
                </figcaption>
              </figure>
              <div className="component-item--cta-container">
                <div className="component-item--cta-arrow-container">
                  <span></span><span></span><span></span>
                  <svg className="arrow-head" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 10 15" }} viewBox="0 0 10 15">
                    <path d="M3.3.2.4 3.1l4.3 4.4-4.3 4.4 2.9 2.9 6.3-6.3v-2z" />
                  </svg>
                </div>
                <p className="text-white-offset">Ver detalhes sobre a aula</p>
              </div>
            </Link>
          ))
          }
        </div>
      </InfiniteScroll>
      {isLoading && (
        <InfiniteLoaderComponent></InfiniteLoaderComponent>)
      }
    </>
  );
}

export default LessonComponent;