import React, { useState } from 'react';

function OrdersComponents({ orders }) {

    console.log(orders)

    const [orderMoreInfoVisibility, setOrderMoreInfoVisibility] = useState({});

    const toggleMoreInfo = (orderId) => {
        setOrderMoreInfoVisibility((prevState) => {
            const isVisible = prevState[orderId];
            // If `orderId` does not exist, `isVisible` will be `undefined`, so we negate it twice to convert to `true`
            // If `orderId` exists, we just toggle its current boolean value
            return {
                ...prevState,
                [orderId]: !isVisible
            };
        });
    };

    return (
        <>
            {orders.length > 0 ? (
                <div className="profile-orders">
                    {orders.map((order, index) => (
                        <div key={index} className="profile-order-item">
                            <div className="profile-order-item--details" data-key={order.id}>
                                <div>
                                    <div>
                                        <div>
                                            <h6>Data do pedido</h6>
                                            <span>{new Date(order.startdate).toLocaleDateString('pt-PT')}</span>
                                        </div>
                                        <div>
                                            <h6>Total</h6>
                                            <span>{(parseInt(order.totalamount) / 100).toFixed(2)}€</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h6># Pedido</h6>
                                            <span>{order.id}</span>
                                        </div>
                                        <div>
                                            <div className="flex-row">
                                                <button className="btn-s btn-primary-ghost" onClick={() => toggleMoreInfo(order.id)}>
                                                    {orderMoreInfoVisibility[order.id] ? (
                                                        <span>Esconder Detalhes</span>
                                                    ) : (
                                                        <span>Ver Detalhes</span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {orderMoreInfoVisibility[order.id] && (
                                    <div>
                                        <div>
                                            <div>
                                                <h6>Enviado para</h6>
                                                <span>{order.address.addressName}</span>
                                            </div>
                                            <div>
                                                <h6>Tracking Number</h6>
                                                <a href={order.trackingurl} target="_blank" rel="noopener noreferrer">{order.trackingnumber}</a>
                                            </div>
                                            {/* <div>
                                                <h6>Email</h6>
                                                <span>{order.email}</span>
                                            </div> */}
                                        </div>
                                        <div>
                                            <div>
                                                <h6>Subtotal</h6>
                                                <span>{((parseInt(order.totalamount) - 500) / 100).toFixed(2)}€</span>
                                            </div>
                                            <div>
                                                <h6>Envio</h6>
                                                <span>5€</span>
                                            </div>
                                            <div>
                                                <h6>Fatura</h6>
                                                <a href={order.receipt_url} target="_blank" rel="noopener noreferrer" className="inline-link flex-row">
                                                    <span>Ver Fatura</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="profile-order-item--cart">
                                <div>
                                    <div>
                                        <h6>Estado</h6>
                                        <h5>{order.status.charAt(0).toUpperCase() + order.status.slice(1).toLowerCase()}</h5>
                                    </div>
                                    <div>
                                        <h6>Última atualização</h6>
                                        <span>{new Date(order.lastupdate).toLocaleDateString('pt-PT')}</span>
                                    </div>
                                </div>
                                <ul>
                                    {order.items.map((item, itemIndex) => (
                                        <li key={itemIndex} className="list-item profile-order-cart-item">
                                            <div className="image-container" ratio="2:3">
                                                <img
                                                    className="img-cover"
                                                    src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${item.photo}`}
                                                    alt={item.name}
                                                />
                                            </div>
                                            <div className="flex-column gap-m">
                                                <div className="flex-row justify-between flex-column-on-breakpoint-xs gap-s-on-breakpoint-xs">
                                                    <div className="flex-column gap-xxs">
                                                        <h4 className="profile-order-cart-item--title">{item.name}</h4>
                                                        <div className="flex-row align-center gap-xxxs">
                                                            <span className="profile-order-cart-item--selected-option">{item.size}</span>
                                                            <span className="profile-order-cart-item--selected-option" style={{ backgroundColor: item.color }}></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-column align-end align-start-on-breakpoint-xs">
                                                        <h4 className="profile-order-cart-item--price">{((item.price - item.discount) * item.quantity).toFixed(2)} €</h4>
                                                        <span className="profile-order-cart-item--price-unit">{item.price - item.discount}€ / unid.</span>
                                                    </div>
                                                </div>
                                                <div className="profile-order-cart-item--quantity">
                                                    <span>Quantidade:</span>
                                                    <span>{item.quantity}</span>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="list-item profile-order-cart-item">
                                        <div className="image-container bg-accent text-white align-center justify-center" ratio="1:1">
                                            <i className="icon-xl icon-delivery"></i>
                                        </div>
                                        <div className="flex-column gap-m">
                                            <div className="flex-row justify-between">
                                                <div className="flex-column gap-xxs">
                                                    <h4 className="profile-order-cart-item--title">Envio</h4>
                                                </div>
                                                <h4 className="profile-order-cart-item--price">5 €</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="profile-empty-content">
                    <p>As encomendas que fizeres aparecerão aqui</p>
                </div>
            )}
        </>
    );


}

export default OrdersComponents;