import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ROUTES } from "controller/routes/routes";
import { MODAL_TYPES, useModal } from "controller/providers/ModalContext";
import { clearShoppingCart, increaseQuantity, decreaseQuantity, deleteItem, selectTotalQuantity } from 'controller/redux/StoreSlice';
import { toast } from 'react-toastify';
import { getColorValue } from "scripts/utils/Utils";

function ShoppingCartPage() {
  const shoppingCart = useSelector(state => state.store.shoppingCart);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const nrItems = useSelector(selectTotalQuantity);

  const { handleShow, handleClose } = useModal();

  const dispatch = useDispatch();

  const handleIncrease = (item) => {
    dispatch(increaseQuantity(item));
  };

  const handleDecrease = (item) => {
    dispatch(decreaseQuantity(item));
  };

  const handleDeleteItem = (item) => {
    dispatch(deleteItem(item));
  };

  return (
    <div id="page-container" page="cart">
      <Helmet>
        <title>Sthenos | Confirmar Encomenda</title>
        
        
        
      </Helmet>

      <section className="container-m fullheight">
        <div className="page-header-no-image">
          <h1 className="uppercase text-gray-offset">A tua encomenda</h1>
          <hr className="hr-s" />
          <div className="flex-row align-center gap-m">
            <h5 className="text-accent">Resumo</h5>
          </div>
        </div>
        <hr className="hr-xl" />

        <div id="checkout-grid">
          <div>
            <ul>
              {shoppingCart.map((item, index) => (
                <li className="list-item confirm-cart-item" key={`item-${item.storeItem.id}-${index}`}>
                  <div className="image-container" ratio="2:3">
                    <img
                      className="img-cover"
                      src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${item.storeItem.inventory.find(invItem =>
                        invItem.color === item.storeItem.selectedColor &&
                        invItem.size === item.storeItem.selectedSize.toLowerCase())?.photo || item.storeItem.photo
                        }`}
                      alt={`Product ${item.storeItem.slug}`}
                    />
                  </div>
                  <div className="flex-column gap-m">
                    <div className="flex-row justify-between flex-column-on-breakpoint-ml gap-s-on-breakpoint-ml">
                      <div className="flex-column gap-xxs">
                        <h3 className="confirm-cart-item--title">{item.storeItem.name}</h3>
                        <div className="flex-row align-center gap-xxxs">
                          {item.storeItem.selectedSize !== '' && (
                            <span className="confirm-cart-item--selected-option">{item.storeItem.selectedSize}</span>
                          )}
                          {item.storeItem.selectedColor !== '' && (
                            <span className="confirm-cart-item--selected-option" style={{ backgroundColor: getColorValue(item.storeItem.selectedColor) }}></span>
                          )}
                        </div>
                      </div>
                      <div className="flex-column align-end align-start-on-breakpoint-ml">
                        <h3 className="confirm-cart-item--price">{(item.storeItem.price - item.storeItem.discount) * item.quantity} €</h3>
                        {item.quantity > 1 && (
                          <span className="confirm-cart-item--price-unit">{item.storeItem.price - item.storeItem.discount}€ / unid.</span>
                        )}
                      </div>
                    </div>
                    <div className="justify-self-stretch mt-auto flex-row gap-l justify-between-on-breakpoint-xs">
                      <div className="confirm-cart-item--quantity">
                        <button onClick={() => handleDecrease(item.storeItem)} > - </button>
                        {item.quantity}
                        <button onClick={() => {
                          if (item.quantity < item.storeItem.selectedStock) {
                            handleIncrease(item.storeItem);
                          } else {
                            toast.error("Lamentamos, mas parece que o stock deste artigo esgotou", {
                              position: toast.POSITION.TOP_CENTER,
                              autoClose: 5000,
                            });
                          }
                        }}> + </button>
                      </div>
                      <button type="button" className="confirm-cart-item--delete btn btn-secondary-ghost hide-on-breakpoint-xs" onClick={() => handleDeleteItem(item.storeItem)}><span>Remover</span><i className="icon-delete"></i></button>
                      <button type="button" className="confirm-cart-item--delete btn btn-secondary-ghost hide flex-on-breakpoint-xs" onClick={() => handleDeleteItem(item.storeItem)}><i className="icon-delete"></i></button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

          </div>

          <div className="aside-content">
            <h5 className="aside-title">Resumo da encomenda</h5>
            <ul className="flex-column">
              <li className="list-item">
                <p className="text-gray-light">Subtotal ({nrItems} {nrItems === 1 ? 'artigo' : 'artigos'})</p>
                <p>{shoppingCart.reduce((acc, item) => acc + ((item.storeItem.price - item.storeItem.discount) * item.quantity), 0)}€</p>
              </li>
              <li className="list-item">
                <p className="text-gray-light">Entrega ao Domicílio</p>
                <p>5€</p>
              </li>
            </ul>

            <hr className="hr-xl" />

            <div className="flex-row align-center justify-end gap-m">
              <p className="gray-light">Total</p>
              <h3>{shoppingCart.reduce((acc, item) => acc + ((item.storeItem.price - item.storeItem.discount) * item.quantity), 0) + 5}€</h3>
            </div>

            <hr className="hr-xs" />

            {isLoggedIn ? (
              <Link to={ROUTES.CHECKOUT} className="w-100 btn-l btn-primary-fill">Ir para pagamento</Link>
            ) : (
              <>
                <div className="flex-column gap-xs">
                  <button type="button" className="btn-l btn-primary-fill" onClick={() => handleShow(MODAL_TYPES.LOGIN, { handleClose })}>
                    <span>Comprar com a minha conta</span>
                  </button>
                  <Link to={ROUTES.CHECKOUT} className="btn inline-link text-right">Continuar como convidado</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <hr className="hr-xxl" />
    </div>
  );
}

export default ShoppingCartPage;
