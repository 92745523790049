import React from "react";

function BoxFeaturesComponent({ zones }) {

  return (
      <>
        {Object.entries(zones).sort(([zoneA], [zoneB]) => {
          if (zoneA.name === 'Zona de treino') return -1;
          if (zoneB.name === 'Zona de treino') return 1;
          return 0;
        }).map(([zone, details], index) => (
        <div key={index}>
          <h4 className="features-title">{zone.name}</h4>
          <ul className="feature-items-container">
            {details.features.map((feature, i) => (
              <li className="feature-item" key={i}>
                <i className="icon-check-circle"></i><span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}

export default BoxFeaturesComponent;