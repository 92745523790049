import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import LoadingPageComponent from 'render/views/components/LoadingPageComponent';
import { LOADING_TIME } from 'scripts/Constants';
import { useSelector } from "react-redux"
import ErrorPage from 'render/views/pages/ErrorPage';

export const useLoading = (isLoading=false) => {
    const [loading, setLoading] = useState(isLoading);
    return [loading, setLoading];
};

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({});
  const windowDimensions = useWindowSize();

  useEffect(() => {
    setDimensions({
      height: windowDimensions.height,
      width: windowDimensions.width
    });

    function handleResize() {
      setDimensions({
        height: windowDimensions.height,
        width: windowDimensions.width
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions]);

  return dimensions;
}

export const useDelayedLoading = (slug) => {
  const [loading, setLoading] = useState(true);

  const status = useSelector((state) => state.home.status);
  const error = useSelector((state) => state.home.error);

  useEffect(() => {
    if(status=="succeeded" || error && error.includes("not found")){
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, LOADING_TIME);
      return () => clearTimeout(timer);
    }    
  }, [status]);

  return (renderComponent) => {
      return (
        <>
          { loading && <LoadingPageComponent />}
          {(!slug || (error && error.includes("not found"))) ? (
            <ErrorPage/>
          ) : (
            renderComponent()
          )}
        </>
      );
  };
};

export const useDelayedLoadingByState = (state) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, LOADING_TIME);
      return () => clearTimeout(timer);
  }, [state]);

  return (renderComponent) => {
      return (
        <>
          {(loading && !state) && <LoadingPageComponent />}
          {renderComponent()}
        </>
      );
  };
};