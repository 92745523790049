import React, { useState } from "react";
import { useModal,MODAL_TYPES } from "controller/providers/ModalContext";

function TrainerComponent({ trainer }) {
  
  const { handleShow } = useModal();

  return (
        <div className="trainer-item">
          <figure>
            <div className="bg-gray image-container" ratio="2:3">
              <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/trainers/sthenos-${trainer.slug}.jpg`} alt='' />
              <div>
                <p>{trainer.quote}</p>
              </div>
            </div>
            <figcaption>
              <h6 className="text-white-offset">{trainer.role}</h6>
              <h4>{trainer.name}</h4>
              <div>
                <button className="btn btn-primary-ghost" onClick={() => handleShow(MODAL_TYPES.TRAINER, { trainer })}>Ver detalhes</button>
              </div>
            </figcaption>
          </figure>
        </div>
  );
}

export default TrainerComponent;
