import { ROUTES } from 'controller/routes/routes';
import { useLoading } from 'controller/hooks/hooks';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTIONS_TYPE, HASH_SECRET_KEY } from 'scripts/Constants';
import pako from 'pako';
import { handleOpenExternalPage } from 'scripts/utils/Utils';

const BoxSelectorModal = ({ props }) => {
    const { handleClose, url_carnaxide, url_benfica, box, type, description, price } = props;
    const [loading, setLoading] = useLoading();
    const navigate = useNavigate();
    const [selectedBox, setSelectedBox] = useState(box);

    const closeModal = () => {
        handleClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleClose) {
            handleClose();
        }

        if (description === "" && price === "") {
            const url = selectedBox === "Carnaxide" ? url_carnaxide : url_benfica;
            handleOpenExternalPage(url, handleClose);
            return;
        }

        await new Promise(resolve => setTimeout(resolve, 100));

        const targetRoute = type === SUBSCRIPTIONS_TYPE.ONE_SHOT ? ROUTES.ONESHOTLESSON : ROUTES.PACKS;

        const data = {
            url: selectedBox === "Carnaxide" ? url_carnaxide : url_benfica,
            box: selectedBox,
            description: description,
            price: price
        };

        const compressedDataArray = pako.deflate(JSON.stringify(data));
        const base64Data = btoa(String.fromCharCode(...compressedDataArray));

        navigate(`${targetRoute}?data=${encodeURIComponent(base64Data)}`);
    };

    const handleLocationChange = (event) => {
        setSelectedBox(event.target.value);
    };

    return (
        <>
            <h5 className="line-height-1 text-accent">Seleciona a box em que queres treinar</h5>
            <hr className="hr-m" />
            <div className="flex-row">
                <div className="custom-select flex-column">
                    <label htmlFor="location">Box selecionada:</label>
                    <div>
                        <select className="btn btn-primary-ghost" id="location" value={selectedBox} onChange={handleLocationChange}>
                            <option value="Carnaxide">Carnaxide</option>
                            <option value="Benfica">Benfica</option>
                        </select>
                        <svg className="chevron-down" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ 'enableBackground': 'new 0 0 14 9' }} viewBox="0 0 14 9"><path d="M1.5 2.1 7.1 7l5.4-4.8" /></svg>
                    </div>
                </div>
            </div>

            <hr className="hr-m" />
            <div className="modal-footer">
                <button className="btn inline-link text-gray-dark" type="reset" onClick={closeModal}>Cancelar</button>
                <button className='btn btn-primary-fill' type="submit" onClick={handleSubmit} state={loading ? "processing" : "idle"} disabled={loading}><span>{loading ? 'Processing' : 'Seguinte'}</span></button>
            </div>
        </>
    );
};

export default BoxSelectorModal;
