import React, { useEffect, useState, useRef } from "react"
import { Helmet } from 'react-helmet';

import { useSelector, useDispatch } from "react-redux"
import LoadingPageComponent from "render/views/components/LoadingPageComponent";
import FilterComponent from "render/views/components/FilterComponent";
import { fetchStoreItems } from "controller/redux/StoreSlice";
import StoreItemComponent from "./StoreItemComponent";
import StoreBanner from "./StoreBanner";
import { set } from "lodash";

function StorePage() {

  const storeItems = useSelector(state => state.store.storeItems);

  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.store.status)
  const { error } = useSelector((state) => state.store.status)

  const [filteredStoreItems, setFilteredStoreItems] = useState(storeItems)
  const featuredItems = storeItems.filter(item => item.featured);
  const sectionRef = useRef(null);

  function handleFilter(filtered) {
    setFilteredStoreItems(filtered)
  }

  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    dispatch(fetchStoreItems());
  }, [dispatch]);

  if (status === 'loading') {
    return <LoadingPageComponent />
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="page-container" page="store">
      <Helmet>
        <title>Sthenos | Loja</title>
      </Helmet>

      <section>
        <div id="store-banner-container">
          <StoreBanner items={featuredItems}></StoreBanner>
          {/* <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} /> */}
          <div className="container">
            <h1 id="swag-text" className="text-accent text-outline-thicker">CaliCartel</h1>
            <button onClick={scrollToSection}>
              <h4 className="text-accent uppercase">Compra agora!</h4>
              <i className="icon-chevron-down text-white"></i>
            </button>
          </div>
        </div>
      </section>

      <section className="container" ref={sectionRef}>
        {/* <StoreFeaturedItemSlider items={featuredItems}></StoreFeaturedItemSlider> */}
        {/* <hr className="hr-xxl" /> */}
        {/* <>
          {storeItems && filteredStoreItems &&
            <FilterComponent
              list={storeItems}
              onFilter={handleFilter}
              type="store"
              label="Pesquisar artigo na loja..."
            />
          }
        </> */}
        <hr className="hr-xl" />
        <StoreItemComponent storeItems={storeItems} />
        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default StorePage;