import React, { useEffect } from 'react';
import { handleOpenExternalPage } from 'scripts/utils/Utils';

const ExternalPageModal = ({ props }) => {
    const { handleClose, url } = props;

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
            handleOpenExternalPage(url);
        }, 3500);

        return () => clearTimeout(timer);
    }, [handleClose, url]);

    return (
        <div className="text-center">
            <div className="processing-loader"></div>
            <hr className="hr-m"/>
            <h4 className="text-accent">A redirecionar<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span></h4>
            <hr className="hr-m"/>
            {/* <p>Abrirá dentro em breve uma janela nova para poderes continuar a compra através do nosso parceiro.</p> */}
            <p>Por favor conclui a tua compra na janela que abrirá do nosso parceiro.</p>
        </div>
    );
};

export default ExternalPageModal;
