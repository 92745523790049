import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_ROUTES } from 'scripts/Constants';
import { getLocalData, setLocalData } from 'scripts/utils/Utils';

const isLoggedIn = getLocalData(LOCAL_STORAGE_ROUTES.IS_LOGGED_IN) || false
const token = getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) || ''

const initialState = {
    isLoggedIn,
    token,
};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
            setLocalData(LOCAL_STORAGE_ROUTES.IS_LOGGED_IN, action.payload);
        },
        setToken: (state, action) => {
            state.token = action.payload;
            setLocalData(LOCAL_STORAGE_ROUTES.TOKEN, action.payload);
        },
    },
});

export const { setLoggedIn, setToken } = AuthSlice.actions;

export default AuthSlice.reducer;