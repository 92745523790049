import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from '../../../controller/routes/routes';
import { WEEK_DAYS } from "scripts/Constants";
import { unslug } from "scripts/utils/Utils";

function ScheduleComponent({ box, schedule }) {

    return (
        <div className="schedule-container grid-7 justify-center gap-m grid-3-on-breakpoint-ml gap-row-l-on-breakpoint-ml">
            {WEEK_DAYS.map((day, index) => (
                <div className="schedule-group" key={index}>
                    <h4 className="schedule-group--title">{day}</h4>
                    {schedule[day] &&
                        schedule[day].map((item, itemIndex) => (
                            item.box === box.toLowerCase() && item.lesson ? (<Link to={`${ROUTES.LESSONS}/${item.lesson}`} className="schedule-item" key={itemIndex}>
                                <div className="schedule-item--lesson-name">{unslug(item.lesson)}</div>
                                <div className="schedule-item--lesson-timeslot"><span>{item.startTime}</span> <span className="divider">-</span> <span>{item.endTime}</span></div>
                            </Link>) : null
                        ))}
                </div>
            ))}
        </div>
    );

}

export default ScheduleComponent;