import { I18nextProvider } from 'react-i18next';

import i18n from 'i18next';
import { Provider } from 'react-redux';
import ReduxStore from 'controller/redux/ReduxStore';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ModalProvider, GlobalModal } from './ModalContext';

i18n.init({
  lng: 'pt',
  resources: {
    pt: {
      translation: require('../../translations/pt.json')
    },
    en: {
      translation: require('../../translations/en.json')
    }
  }
});

export const MainProvider = (props) => (
  <I18nextProvider i18n={i18n}>
    <Provider store={ReduxStore}>
      <ModalProvider>
        <GlobalModal />
        {props.children}
      </ModalProvider>
    </Provider>
    <ToastContainer />
  </I18nextProvider>
);
