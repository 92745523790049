import React, { useEffect } from 'react';
import { MODAL_TYPES, useModal } from './ModalContext';

const PopupProvider = () => {
    const { handleShow, handleClose } = useModal();

    useEffect(() => {
        if (sessionStorage.getItem('modalShown') !== 'true') {
            const timer = setTimeout(() => {
                handleShow(MODAL_TYPES.POPUP, { handleClose });
                sessionStorage.setItem('modalShown', 'true');
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [handleShow, handleClose]);

    return null;
};

export default PopupProvider;
