import { fetchProfile, updateProfile } from 'controller/redux/ProfileSlice';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import LoadingPageComponent from 'render/views/components/LoadingPageComponent';
import { LOCAL_STORAGE_ROUTES } from 'scripts/Constants';
import { getLocalData } from 'scripts/utils/Utils';
import AddressComponent from './components/AddressComponent';
import OrdersComponents from './components/OrdersComponent';

function ProfilePage() {
  const { isProfileDataFetched, currentProfile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('personalData');
  const [localNif, setLocalNif] = useState('');
  const [localAddresses, setLocalAddresses] = useState([]);
  const [localPhoto, setLocalPhoto] = useState('');
  const [localName, setLocalName] = useState(''); // Assuming you add this for the name
  const [isEditing, setIsEditing] = useState(false);

  const [localPhotoPreview, setLocalPhotoPreview] = useState('');


  useEffect(() => {
    if (!isProfileDataFetched) {
      dispatch(fetchProfile({ token: getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) }));
    }
  }, [dispatch, isProfileDataFetched]);

  useEffect(() => {
    if (currentProfile && (currentProfile.nif !== localNif || currentProfile.addresses !== localAddresses || currentProfile.photo !== localPhoto)) {
      setLocalNif(currentProfile.nif || '');
      setLocalAddresses(currentProfile.addresses || []);
      setLocalPhoto(currentProfile.photo || '');
      setLocalName(currentProfile.name || '');
    }
  }, [currentProfile]);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const handleNifChange = (e) => {
    setLocalNif(e.target.value);
  };

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setLocalPhoto(e.target.files[0]);
      const previewUrl = URL.createObjectURL(file);
      setLocalPhotoPreview(previewUrl); // Store the blob URL for previewing
    }
  };

  const handleSaveChanges = () => {

    const updatedProfile = {
      ...currentProfile,
      nif: localNif,
      photo: localPhoto,
      name: localName,
    };

    dispatch(updateProfile({ profileData: updatedProfile }));
    setIsEditing(false);
  };

  const handleNameChange = (e) => {
    setLocalName(e.target.value);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  if (!currentProfile || !currentProfile.email) {
    return <LoadingPageComponent />;
  }

  const updateAddresses = (updatedAddresses) => {
    setLocalAddresses(updatedAddresses);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'personalData':
        return (
          <div id="personal-data">
            <h2>Dados pessoais</h2>
            <hr className="hr-s" />
            <div className="flex-row justify-between align-center gap-s">
              {isEditing ? (
                <>
                  <button className="btn-s inline-link" onClick={() => setIsEditing(false)}>Cancelar</button>
                  <button className="btn-s btn-primary-ghost btn-alt" onClick={handleSaveChanges}>Gravar</button>
                </>
              ) : (
                <button className="btn-s btn-primary-ghost btn-alt" onClick={toggleEditMode}>Editar</button>
              )}
            </div>
            <hr className="hr-ml" />
            {/* Photo */}
            <div className="list-item profile-info-item">
              <div>
                <p><b>Foto de Perfil</b></p>
                {isEditing ? (
                  <>
                    <input
                      type="file"
                      id="photo"
                      name="photo"
                      onChange={handlePhotoChange}
                      style={{ display: 'block' }} // Show the file input
                    />
                  </>
                ) : (
                  <img
                    src={localPhotoPreview || `${process.env.REACT_APP_MEDIA_BASE_PATH}/profile/${currentProfile.photo || 'default.jpg'}`}
                    alt="Profile"
                    style={{ width: '100px', height: '100px' }}
                  />
                )}
              </div>
            </div>
            <div className="list-item profile-info-item">
              <div>
                <p><b>Nome</b></p>
                {isEditing ? (
                  <input
                    type="text"
                    value={localName}
                    onChange={handleNameChange}
                  />
                ) : (
                  <p>{localName}</p>
                )}
              </div>
            </div>
            <div className="list-item profile-info-item">
              <div>
                <p><b>NIF</b></p>
                {isEditing ? (
                  <input
                    type="number"
                    value={localNif}
                    onChange={handleNifChange}
                    placeholder="Insere o teu NIF"
                    min={100000000}
                    max={999999999}
                  />
                ) : (
                  <>
                    {localNif ? (
                      <p>{localNif}</p>
                    ) : (
                      <p className="text-gray-lighter text-light">Sem NIF associado</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      case 'addresses':
        return (<><h2>Moradas</h2>
          <AddressComponent initialAddresses={localAddresses} token={currentProfile.token} onAddressesChange={updateAddresses}></AddressComponent></>);
      case 'orders':
        return (
          <>
            <h2>Encomendas</h2>
            <hr className="hr-l" />
            <OrdersComponents orders={currentProfile.orders}></OrdersComponents>
          </>
        );
      case 'courses':
        return (
          <>
            <h2>Cursos</h2>
            <hr className="hr-l" />
            {currentProfile.courses ? (
              currentProfile.courses.map((course, index) => (
                <div key={index} className="profile-info-item">
                  <div className="list-item profile-course-item">
                    <h4>{course.title}</h4>
                    <p>{course.short_description}</p>
                    <div className="profile-course-item--details">
                      <button className="btn-s btn-primary-ghost btn-alt">Ver curso</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="profile-empty-content">
                <p>Os cursos que comprares aparecerão aqui</p>
              </div>
            )}
          </>
        );
      default:
        return;
    }
  };

  return (
    <>
      {currentProfile && (
        <div id="page-container" page="profile">
          <Helmet>
            <title>Sthenos | A tua conta</title>
            <meta name="description" content="A melhor forma de treinar Calistenia em Portugal" />
            <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
          </Helmet>
          <div className="container-sm">
            <hr className="hr-xxxl" />
            <hr className="hr-xl" />
            <div id="account-grid">
              <div>
                <div className="aside-content">
                  <div className="flex-column gap-m">
                    <div className="profile-image image-container bg-gray align-center justify-center" ratio="1:1">
                      <img
                        src={localPhotoPreview || `${process.env.REACT_APP_MEDIA_BASE_PATH}/profile/${currentProfile.photo || 'default.jpg'}`}
                        alt="Profile"
                      />
                    </div>
                    <h5 className="aside-title">A tua conta</h5>
                  </div>
                  <ul className="profile-tabs">
                    <button className={`list-item ${activeTab === 'personalData' ? 'active' : ''}`} onClick={() => handleTabChange('personalData')}>
                      <p>Dados Pessoais</p>
                    </button>
                    {/* <button className="list-item">
                      <p>Subscrição</p>
                    </button> */}
                    <button className={`list-item ${activeTab === 'addresses' ? 'active' : ''}`} onClick={() => handleTabChange('addresses')}>
                      <p>Moradas</p>
                    </button>
                    <button className={`list-item ${activeTab === 'orders' ? 'active' : ''}`} onClick={() => handleTabChange('orders')}>
                      <p>Encomendas</p>
                    </button>
                    {/* <button className={`list-item ${activeTab === 'courses' ? 'active' : ''}`} onClick={() => handleTabChange('courses')}>
                      <p>Cursos</p>
                    </button> */}
                  </ul>
                </div>
              </div>
              <div className="profile-content">
                {renderActiveTabContent()}
              </div>
            </div>

            <hr className="hr-xxxl" />
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePage