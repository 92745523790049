import SecureLS from 'secure-ls';
import { toast } from 'react-toastify';
import axios from 'axios';

const ls = new SecureLS();

export const setLocalData = (key, value) => {
    const expiration = '2025-01-01'; // ToDo: isto tem de vir do backend com token ainda temos de ver mais sobre isto
    const currentValue = ls.get(key);
    if (currentValue) {
        ls.set(key, { ...currentValue, ...value }, { expires: expiration });
    } else {
        ls.set(key, value, { expires: expiration });
    }
};

export const getLocalData = (key) => {
    try {
        return ls.get(key);
    } catch (e) {
        console.error("Error parsing JSON from localStorage:", e);
        return null; // or a sensible default
    }
};


export const clearLocalData = () => {
    ls.removeAll();
    refreshPage()
};

export const removeLocalData = (key) => {
    ls.remove(key)
};

export function refreshPage() {
    setTimeout(() => {
        window.location = window.location.origin;
    }, 100);
};

export function getDataBySlug(data, slug) {
    if (data.length === 0)
        return []
    else
        return data.find(item => item.slug === slug);
}

export function getRandomData(data, length) {
    const shuffled = data.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, length);
}

export function applySearchFilters(search, data, properties) {
    return data ? data.filter(item => {
        for (const prop in item) {
            if (properties.includes(prop) && item[prop] && item[prop].toLowerCase().includes(search.toLowerCase())) {
                return true;
            }
        }
        return false;
    }) : [];
}

export const generateFetchActionReducers = (actionType) => ({
    pending: (state) => {
        state.status = 'loading';
    },
    fulfilled: (state, action) => {
        state.status = 'succeeded';
        if (action.payload) {
            state[actionType] = action.payload;
        }
    },
    rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    },
});

export const generateExtraReducers = (fetchActions) => (builder) => {
    fetchActions.forEach(({ action, reducers }) => {
        Object.entries(reducers).forEach(([key, reducer]) => {
            builder.addCase(action[key], reducer);
        });
    });
};

export const groupAndSortSchedule = (schedule) => {
    const scheduleByDay = schedule.reduce((acc, item) => {
        if (!acc[item.weekDay]) {
            acc[item.weekDay] = [];
        }
        acc[item.weekDay].push(item);
        return acc;
    }, {});

    Object.keys(scheduleByDay).forEach((day) => {
        scheduleByDay[day].sort((a, b) => {
            const aTime = a.startTime.split(":");
            const bTime = b.startTime.split(":");
            if (parseInt(aTime[0], 10) < parseInt(bTime[0], 10)) {
                return -1;
            } else if (
                parseInt(aTime[0], 10) > parseInt(bTime[0], 10)
            ) {
                return 1;
            } else {
                if (parseInt(aTime[1], 10) < parseInt(bTime[1], 10)) {
                    return -1;
                } else if (
                    parseInt(aTime[1], 10) > parseInt(bTime[1], 10)
                ) {
                    return 1;
                }
                return 0;
            }
        });
    });

    return scheduleByDay;
};

export function getPriceRangeFilter(list) {
    let minPrice = Infinity;
    let maxPrice = -Infinity;

    list.forEach(item => {
        if (item.price < minPrice) {
            minPrice = item.price;
        }
        if (item.price > maxPrice) {
            maxPrice = item.price;
        }
    });

    return { minPrice, maxPrice };
}

export function countDifficultyLevels(items) {
    const levels = {};
    for (let i = 1; i <= 9; i++) {
        levels[i] = 0;
    }
    items.forEach((item) => {
        const level = item.difficulty;
        if (level >= 1 && level <= 9) {
            levels[level]++;
        }
    });
    return levels;
}

export function intersection(list1, list2) {
    return list1.filter(item => list2.includes(item));
}

export function getUniqueValues(exercises, key) {
    const uniqueValues = new Set();
    exercises.forEach((exercise) => {
        const values = exercise[key];
        if (Array.isArray(values)) {
            values.forEach((value) => {
                if (value.hasOwnProperty("name")) {
                    uniqueValues.add(value.name);
                } else if (value.hasOwnProperty("bodyParts")) {
                    value.bodyParts.forEach((bodyPart) => uniqueValues.add(bodyPart.name));
                }
            });
        } else {
            uniqueValues.add(values);
        }
    });
    return Array.from(uniqueValues);
}

export function filterCategory(filteredList, input) {
    if (!input) {
        return filteredList;
    }
    return filteredList.filter((item) => {
        if (item.category === input) {
            return true;
        } else if (item.bodyParts.some((part) => part.name === input)) {
            return true;
        } else {
            return false;
        }
    });
}

export function getRelated(list, item, nr_related) {
    if (item && list) {
        if (Object.keys(item).length === 0 || Object.keys(list).length === 0) {
            return [];
        } else {
            const related = list.filter(listItem => {
                if (listItem.slug === item.slug) {
                    return false;
                }
                const sharedBodyParts = listItem.bodyParts.some(part =>
                    item.bodyParts.map(part => part.slug).includes(part.slug)
                );

                const sharedKeywords = ['title', 'slug', 'description_pt'].some(key => {
                    const itemWords = item[key].toLowerCase().split(' ');
                    const itemItemWords = listItem[key].toLowerCase().split(' ');
                    return itemWords.some(word => itemItemWords.includes(word));
                });

                return sharedBodyParts || sharedKeywords;
            });
            return related.slice(0, nr_related);
        }
    } else {
        return [];
    }

};

export function filterSubscriptions(subscriptions, online) {
    return subscriptions.filter(subscription => subscription.online === online);
}

export const handleOpenExternalPage = (url, setPopupClosed) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    if (isMobile) {
        window.location.href = url;
    } else {
        const popup = window.open(url, 'popUpWindow', 'height=900,width=1000,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');

        const checkPopupClosed = setInterval(() => {
            if (popup.closed) {
                setPopupClosed(true);
                clearInterval(checkPopupClosed);
            }
        }, 1000);
    }
};


export const notification = (text, type = toast.TYPE.SUCCESS, location = toast.POSITION.TOP_CENTER) => {
    switch (type) {
        case toast.TYPE.SUCCESS:
            toast.success(text, { position: location });
            break;
        case toast.TYPE.ERROR:
            toast.error(text, { position: location });
            break;
        case toast.TYPE.WARNING:
            toast.warn(text, { position: location });
            break;
        case toast.TYPE.INFO:
            toast.info(text, { position: location });
            break;
        default:
            toast(text, { position: location });
    }
}

export async function fetchData(api, endpoint, slug) {
    let response;
    if (slug !== undefined) {
        response = await api.getReturnData(endpoint + slug);
    } else {
        response = await api.getReturnData(endpoint);
    }
    return response;
}


export async function fetchProfileData(endpoint, slug, api, profile_data) {
    try {
        const response = await axios.get(endpoint + slug);
        if (!response.data.hasOwnProperty('statusCode')) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        if (api && profile_data) {
            return createProfile(api, profile_data);
        }
    }
}

export async function createProfile(api, profile_data) {
    try {
        const response = await axios.post(api.API_ENDPOINTS.CREATE_PROFILE, profile_data);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function fetchDataLesson(api, slug) {
    const response = await api.getReturnData(api.API_ENDPOINTS.LESSONS_DETAILED + slug);
    const schedule = await api.getReturnData(api.API_ENDPOINTS.SCHEDULE);
    response.schedules = schedule.filter(item => item.lesson === slug);
    return response;
}

export const getColorValue = (colorName) => {
    const colorMap = {
        'caqui': '#c9b7a0',
    };

    return colorMap[colorName.toLowerCase()] || colorName;
};

export function unslug(slug) {
    return slug
        .replace(/-/g, ' ')
        .split(' ')
        .map(word => word === 'e' ? word : word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const handleZipCodeInputChange = (e, setcleanZipCodeValue) => {
    const inputValue = e.target.value;
    // Allow only digits and a dash and insert dash after 4th character
    if (/^\d{0,4}-?\d{0,3}$/.test(inputValue)) {
        if (inputValue.length === 5 && inputValue.charAt(4) !== '-') {
            setcleanZipCodeValue(inputValue.slice(0, 4) + '-' + inputValue.slice(4));
        } else {
            setcleanZipCodeValue(inputValue);
        }
    }
};

export const handleNIFInputChange = (e, setcleanNIFValue) => {
    const inputValue = e.target.value;
    // Allow only digits and limit length to 9 characters
    if (/^\d{0,9}$/.test(inputValue)) {
        setcleanNIFValue(inputValue);
    }
};

export const handlePhoneInputChange = (e, setcleanPhoneValue) => {
    const inputValue = e.target.value;
    // Allow only digits and limit length to 9 characters
    if (/^\d{0,9}$/.test(inputValue)) {
        setcleanPhoneValue(inputValue);
    }
};