import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "render/views/pages/Home/HomePage";
import Training from 'render/views/pages/Training/TrainingPage';
import StorePage from 'render/views/pages/Store/StorePage';
import Subscribe from 'render/views/pages/Subscribe/SubscribePage';
import ShoppingCart from 'render/views/pages/Store/ShoppingCartPage';
import Error from "render/views/pages/ErrorPage";
import CoursesPage from "render/views/pages/Courses/CoursesPage";
import ExercisesPage from "render/views/pages/Exercises/ExercisesPage";
import ExerciseDetails from "render/views/pages/Exercises/ExerciseDetails";
import LessonsPage from "render/views/pages/Lessons/LessonsPage";
import ProfilePage from "render/views/pages/Profile/ProfilePage";
import SthenosAI from "render/views/pages/SthenosAI/SthenosAI";
import BoxesPage from 'render/views/pages/Box/BoxesPage';
import BoxDetailPage from 'render/views/pages/Box/BoxDetailPage';
import CheckoutPage from 'render/views/pages/Checkout/CheckoutPage';
import SucceededComponent from 'render/views/pages/Checkout/SucceededComponent';
import StoreItemDetails from 'render/views/pages/Store/StoreItemDetails';
import CourseDetails from 'render/views/pages/Courses/CourseDetails';
import LessonDetails from 'render/views/pages/Lessons/LessonDetails';
import TrainingPlansPage from 'render/views/pages/Training Plans/TrainingPlansPage';
import TrainingPlanDetails from 'render/views/pages/Training Plans/TrainingPlanDetails'
import TermsConditionsPage from 'render/views/pages/TermsConditionsPage';
import VerificationPage from 'render/views/pages/Verification/VerificationPage';
import OneShotLessonPage from 'render/views/pages/Integrations/OneShotLessonPage';
import PacksPage from 'render/views/pages/Integrations/PacksPage';
import EbooksPage from 'render/views/pages/Ebooks/EbooksPage';
import EbookDetails from 'render/views/pages/Ebooks/EbookDetails';

export const ROUTES = {
  HOME: '/',

  VERIFICATION: '/verification',
  BOX: '/box',
  BOX_DETAILS: '/box/:slug/',
  TRAINING: '/treinar',
  STORE: '/loja',
  STORE_ITEM: '/loja/:slug/',
  TRAINING_PLANS: '/planos_treino',
  TRAINING_PLAN: '/planos_treino/:slug/',
  LESSONS: `/aulas`,
  LESSON_DETAILS: `/aulas/:slug/`, // this route is not to be used, its only pointing to if there is an :id should go to LessonDetails
  SUBSCRIBE: '/subscrever',
  SHOPPING_CART: '/carrinho',
  COURSES: '/cursos',
  COURSES_DETAILS: '/cursos/:slug/', // this route is not to be used, its only pointing to if there is an :id should go to CourseDetails
  EXERCISES: '/exercicios',
  EBOOKS: '/ebooks',
  EBOOKS_DETAILS: '/ebooks/:slug/', // this route is not to be used, its only pointing to if there is an :id should go to EbookDetails
  EXERCISES_DETAILS: '/exercicios/:slug/', // this route is not to be used, its only pointing to if there is an :id should go to ExerciseDetails
  PROFILE: '/perfil',
  TERMS_CONDITIONS: '/termos_condicoes',
  FAQ: '/faq',
  STHENOS_AI: '/sthenos_ai',
  CHECKOUT: '/checkout',
  PAYMENT_SUCCEEDED: '/succeeded',
  ONESHOTLESSON: '/avulso',
  PACKS: '/packs',
};


const RoutesFile = () => {

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<Home />}
        exact
      />
      {<Route
        path={ROUTES.BOX}
        element={<BoxesPage />}
      />}
      <Route
        path={ROUTES.VERIFICATION}
        element={<VerificationPage />}
      />
      <Route
        path={ROUTES.ONESHOTLESSON}
        element={<OneShotLessonPage />}
      />
      <Route
        path={ROUTES.PACKS}
        element={<PacksPage />}
      />
      <Route
        path={ROUTES.TERMS_CONDITIONS}
        element={<TermsConditionsPage />}
      />
      <Route
        path={ROUTES.BOX_DETAILS}
        element={<BoxDetailPage />}
      />
      <Route
        path={ROUTES.TRAINING}
        element={<Training />}
      />
      <Route
        path={ROUTES.STORE}
        element={<StorePage />}
      />
      <Route
        path={ROUTES.STORE_ITEM}
        element={<StoreItemDetails />}
      />
      <Route
        path={ROUTES.SUBSCRIBE}
        element={<Subscribe />}
      />
      <Route
        path={ROUTES.SHOPPING_CART}
        element={<ShoppingCart />}
      />
      <Route
        path={ROUTES.COURSES}
        element={<CoursesPage />}
      />
      <Route
        path={ROUTES.COURSES_DETAILS}
        element={<CourseDetails />}
      />
      <Route
        path={ROUTES.EXERCISES}
        element={<ExercisesPage />}
      />
      <Route
        path={ROUTES.EXERCISES_DETAILS}
        element={<ExerciseDetails />}
      />
      <Route
        path={ROUTES.LESSONS}
        element={<LessonsPage />}
      />
      <Route
        path={ROUTES.LESSON_DETAILS}
        element={<LessonDetails />}
      />
      <Route
        path={ROUTES.EBOOKS}
        element={<EbooksPage />}
      />
      <Route
        path={ROUTES.EBOOKS_DETAILS}
        element={<EbookDetails />}
      />
      <Route
        path={ROUTES.TRAINING_PLANS}
        element={<TrainingPlansPage />}
      />
      <Route
        path={ROUTES.TRAINING_PLAN}
        element={<TrainingPlanDetails />}
      />
      <Route
        path={ROUTES.PROFILE}
        element={<ProfilePage />}
      />
      <Route
        path={ROUTES.STHENOS_AI}
        element={<SthenosAI />}
      />
      <Route
        path={ROUTES.CHECKOUT}
        element={<CheckoutPage />}
      />
      <Route
        path={ROUTES.PAYMENT_SUCCEEDED}
        element={<SucceededComponent />}
      />
      <Route
        path='*'
        element={<Error />}
      />
    </Routes>
  )

};

export default RoutesFile;