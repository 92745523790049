import React, { useEffect, useState, useCallback } from "react"
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"

import LoadingPageComponent from "render/views/components/LoadingPageComponent";
import { fetchExercises, fetchTrainingPlan } from "controller/redux/HomeSlice";
import { getDataBySlug } from "scripts/utils/Utils";
import VideoPlayer from "render/views/components/VideoPlayer";

const TrainingPlanDetails = () => {
  const { slug } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [remainingTime, setRemainingTime] = useState(0);

  const { training_plan, exercises, isDataFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();

  const handlePlay = useCallback(() => {
    setCurrentIndex(0);
    // Implement your logic here to start the training
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex(prevIndex => prevIndex + 1);
    // Implement your logic here to move to the next exercise or pause
  }, []);

  useEffect(() => {
    if (Object.keys(training_plan).length === 0) {
      dispatch(fetchTrainingPlan(slug)).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    if (!isDataFetched) {
      dispatch(fetchExercises()).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, training_plan, slug, isDataFetched]);

  useEffect(() => {

    if (Object.keys(training_plan).length != 0) {

      let timer;
      const currentTraining = training_plan.training[currentIndex];

      // Start a timer only if the current training exists and is a pause
      if (currentTraining && currentTraining.type === 'PAUSE') {
        setRemainingTime(currentTraining.rest);
        timer = setInterval(() => {
          setRemainingTime(prevTime => {
            if (prevTime <= 1) {
              // If the timer reaches 0, go to the next exercise and clear the timer
              clearInterval(timer);
              handleNext();
            }
            // Otherwise, just decrement the remaining time
            return prevTime - 1;
          });
        }, 1000);
      }

      // Clear the timer when component is unmounted or currentTraining changes
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };

    } else {
      setIsLoading(false);
    }

  }, [currentIndex, training_plan.training, handleNext]);

  if (isLoading) {
    return <LoadingPageComponent />;
  }

  const renderTraining = () => {
    const currentTraining = training_plan.training[currentIndex];

    if (!currentTraining) {
      // All exercises/pauses have been completed
      return (
        <div>
          <p>Congratulations! You have completed the training plan.</p>
          <button onClick={handlePlay}>Play Again</button>
        </div>
      );
    }

    if (currentTraining.type === 'EXERCISE') {
      return (
        <div>
          <h2 style={{ color: '#444' }}>Exercise {currentTraining.index}</h2>
          <p>Exercise: {getDataBySlug(exercises, currentTraining.exercise).name}</p>
          <div id="course-detail--main">
            <VideoPlayer src={getDataBySlug(exercises, currentTraining.exercise).video} />
          </div>

          <p>Reps: {currentTraining.reps}</p>
          <p>Series: {currentTraining.series}</p>
          <p>Rest: {currentTraining.rest} seconds</p>
          <button onClick={handleNext}>Next</button>
        </div>
      );
    }

    if (currentTraining.type === 'PAUSE') {
      return (
        <div>
          <h2>Pause {currentTraining.index}</h2>
          <h1>Remaining time: {remainingTime} seconds</h1>
          <button onClick={handleNext}>Next</button>
        </div>
      );
    }
  };

  if (Object.keys(training_plan).length != 0) {
    return (
      <div>
        <h1>{training_plan.title}</h1>
        <p>{training_plan.desc}</p>
        {renderTraining()}
      </div>
    );
  }
};

export default TrainingPlanDetails;