import React, { useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

import { ROUTES } from 'controller/routes/routes';
import { LAZY_LOADING_ITEMS } from "scripts/Constants";
import InfiniteLoaderComponent from "render/views/components/InfiniteLoaderComponent";

function ExerciseComponent({ exercises }) {
  const [count, setCount] = useState(LAZY_LOADING_ITEMS);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setCount(count => count + LAZY_LOADING_ITEMS);
      setIsLoading(false);
    }, 500);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={count}
        next={loadMore}
        scrollThreshold={0.5}
        hasMore={count < exercises.length}
        style={{ overflow: 'hidden' }}
      >
        <div className="grid-5 gap-l gap-row-xl grid-4-on-breakpoint-l grid-3-on-breakpoint-m gap-m-on-breakpoint-sm gap-row-l-on-breakpoint-sm grid-2-on-breakpoint-xs gap-s-on-breakpoint-xxs gap-row-m-on-breakpoint-xxs">
          {exercises.slice(0, count).map((exercise, index) => (
            <Link to={`${ROUTES.EXERCISES}/${exercise.slug}`} className="exercise-item" key={index}>
              <figure>
                <div className="bg-gray image-container" ratio="2:3">
                  <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/exercises/${exercise.slug}/sthenos-fit-exercise-${exercise.slug}.jpg`} alt='' />
                  <div className="exercise-image-info-overlay">
                    <p className="exercise-image-info-overlay--difficulty">{exercise.difficulty}</p>
                    <span></span>
                    <p className="exercise-image-info-overlay--cta"><span><i className="icon-close"></i></span></p>
                  </div>
                </div>
                <figcaption>
                  <p className="text-white-offset text-xs">{exercise.category}</p>
                  <h5>{exercise.title}</h5>
                </figcaption>
              </figure>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      {isLoading && (
        <InfiniteLoaderComponent></InfiniteLoaderComponent>)
      }
    </>
  );
}

export default ExerciseComponent;