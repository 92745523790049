import React from "react";

function TestimonialComponent({ testimonial }) {
    return (
        <div className="testimonial-item">
            <figure>
                <svg className="quote" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: "new 0 0 300 300" }} viewBox="0 0 300 300"><path d="M78.9 67.6c29.9 0 54.9 25 54.9 54.9 0 30.7-24.2 54.9-54.1 54.9 3.2 26.7 26.7 46.9 54.1 46.9v8.1C73.2 232.4 24 183.9 24 123.3v-.8c0-29.9 24.2-54.9 54.9-54.9zm142.2 0c29.9 0 54.9 25 54.9 54.9 0 30.7-24.2 54.9-54.1 54.9 3.2 26.7 26.7 46.9 54.1 46.9v8.1c-60.6 0-109.9-48.5-109.9-109.1v-.8c.1-29.9 24.3-54.9 55-54.9z" /></svg>
                <div className="bg-gray image-container" ratio="9:16">
                    <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/testimonials/${testimonial.slug}.jpg`} alt='' />
                </div>
                <figcaption>
                    <h5>{testimonial.name}</h5>
                    <hr className="hr-s" />
                    <p className="text-gray">{testimonial.testimonial}</p>
                </figcaption>
            </figure>
        </div>
    );

}

export default TestimonialComponent;