import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { selectTotalValue } from 'controller/redux/StoreSlice';
import { ROUTES } from 'controller/routes/routes';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getLocalData, handleNIFInputChange, handlePhoneInputChange, handleZipCodeInputChange } from 'scripts/utils/Utils';
import { fetchProfile } from 'controller/redux/ProfileSlice';
import { LOCAL_STORAGE_ROUTES } from 'scripts/Constants';
import AddressComponent from '../Profile/components/AddressComponent';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_ENDPOINTS } from 'api/SthenosAPI';

const CheckoutForm = ({ onProcessingStateChange }) => {

  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isProfileDataFetched, currentProfile } = useSelector((state) => state.profile);
  const shoppingCart = useSelector(state => state.store.shoppingCart);
  const [cleanZipCodeValue, setcleanZipCodeValue] = useState('');
  const [cleanPhoneValue, setcleanPhoneValue] = useState('');
  const [cleanNIFValue, setcleanNIFValue] = useState('');

  const totalValue = useSelector(selectTotalValue);

  useEffect(() => {
    if (!isProfileDataFetched) {
      dispatch(fetchProfile({ token: getLocalData(LOCAL_STORAGE_ROUTES.TOKEN) }));
    }
  }, [dispatch, isProfileDataFetched]);

  useEffect(() => {
    onProcessingStateChange(isProcessing);
  }, [isProcessing, onProcessingStateChange]);

  const [selectedAddressFromChild, setSelectedAddressFromChild] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [unregisteredUserDetails, setUnregisteredUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    zipcode: '',
    location: '',
    nif: '',
  });

  const handleAddressSelect = (address) => {
    setSelectedAddressFromChild(address);
  };

  function createItemDescription(items) {
    return items.map(item => {
      const inventoryItem = item.storeItem.inventory.find(invItem =>
        invItem.color === item.storeItem.selectedColor &&
        invItem.size === item.storeItem.selectedSize.toLowerCase());
      return {
        id: item.storeItem.id,
        quantity: item.quantity,
        name: item.storeItem.name,
        photo: inventoryItem ? inventoryItem.photo : item.storeItem.photo,
        color: item.storeItem.selectedColor,
        size: item.storeItem.selectedSize,
        price: item.storeItem.price,
        discount: item.storeItem.discount,
      };
    });
  }

  const createUnpaidOrder = async () => {
    let orderDetails;
    if (isLoggedIn) {
      if (!selectedAddressFromChild) {
        toast.error("Por favor seleciona uma morada!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        return;
      }

      orderDetails = {
        items: createItemDescription(shoppingCart),
        totalamount: totalValue,
        address: selectedAddressFromChild,
        email: currentProfile.email,
        status: 'PROCESSING',
        trackingnumber: 'n/a',
        trackingurl: 'n/a',
        receipt_url: 'n/a',
        profileId: currentProfile.id,
        nif: currentProfile.nif || '999999999',
      };
    } else {
      const { name, email, address, location, nif } = unregisteredUserDetails;
      const userAddress = {
        addressName: name,
        address,
        postalCode: cleanZipCodeValue,
        city: location,
        country: 'Portugal',
        phone: cleanPhoneValue,
      };

      if (!address || !email) {
        toast.error("Please fill in your address and email!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        return;
      }

      orderDetails = {
        items: createItemDescription(shoppingCart),
        totalamount: totalValue,
        address: userAddress,
        email,
        status: 'PROCESSING',
        trackingnumber: 'n/a',
        trackingurl: 'n/a',
        receipt_url: 'n/a',
        nif: cleanNIFValue || '999999999',
        profileId: -1, // 999999 is the default profile id for unregistered users
      };
    }
    return orderDetails;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    if (!isLoggedIn && !validateForm()) {
      setIsProcessing(false);
      return;
    }
    try {
      const orderRef = await createUnpaidOrder();
      if (!orderRef) {
        setIsProcessing(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
        },
        redirect: 'if_required',
      });

      if (error) {
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log(orderRef)
        await pushOrderRefToBackend(orderRef, paymentIntent);
        window.location.href = `${window.location.origin}${ROUTES.PAYMENT_SUCCEEDED}`;
      }
    } catch (error) {
      console.log(error)
      toast.error("Erro a efectuar a compra:" + error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUnregisteredUserDetails(prev => ({ ...prev, [name]: value }));
    setValidationErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validateZipCode = (zipcode) => {
    const re = /^\d{4}-\d{3}$/;
    return re.test(String(zipcode));
  };

  const validateForm = () => {
    let errors = {};
    if (!validateEmail(unregisteredUserDetails.email)) {
      errors.email = 'Por favor insira um email válido.';
    }
    if (!validateZipCode(cleanZipCodeValue)) {
      errors.zipcode = 'O código postal deve estar no formato XXXX-YYY.';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const pushOrderRefToBackend = async (orderRef, paymentIntent) => {
    const dataToSend = {
      ...orderRef,
      intent_id: paymentIntent.id
    };
    try {
      await axios.post(API_ENDPOINTS.CREATE_ORDER, dataToSend);
      toast.success("Ordem de compra criada com sucesso!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (error) {
      toast.error("Erro a criar ordem de compra", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  // if (!currentProfile || !currentProfile.email) {
  //   return;
  // }

  return (
    <>
      <hr className="bg-gray-lighter" />
      <hr className="hr-ml" />
      <form id="payment-form" className="light-bg" onSubmit={handleSubmit}>
        <h5>Morada de Entrega</h5>
        <hr className="hr-xxs" />
        {isLoggedIn ? (
          <AddressComponent initialAddresses={currentProfile.addresses || []} token={currentProfile.token} onAddressSelect={handleAddressSelect}></AddressComponent>
        ) : (
          <>
            <hr className="hr-m" />
            <div className="grid-8 grid-1-on-breakpoint-sm gap-xs gap-row-xxs">
              <fieldset className="col-3 col-8-on-breakpoint-ml col-3-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Nome</label>
                <input type="text" id="name" name="name" placeholder="Primeiro e último nome" onChange={handleInputChange} />
              </fieldset>
              <fieldset className="col-3 col-8-on-breakpoint-ml col-3-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Email</label>
                <input type="email" id="email" name="email" placeholder="example@email.com" onChange={handleInputChange} value={unregisteredUserDetails.email} />
                {validationErrors.email && <span style={{ color: 'red' }}>{validationErrors.email}</span>}
              </fieldset>
              <fieldset className="col-2 col-3-on-breakpoint-ml col-2-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Número de telefone</label>
                <input type="text" id="phone" name="phone" placeholder="XXX XXX XXX" onChange={(e) => handlePhoneInputChange(e, setcleanPhoneValue)} value={cleanPhoneValue} />
              </fieldset>
              <fieldset className="col-8 col-5-on-breakpoint-ml col-8-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Morada completa</label>
                <input type="text" id="address" name="address" placeholder="Insere a morada completa" onChange={handleInputChange} />
              </fieldset>
              <fieldset className="col-2 col-3-on-breakpoint-ml col-2-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Código Postal</label>
                <input type="text" id="zipcode" name="zipcode" placeholder="0000-000" onChange={(e) => handleZipCodeInputChange(e, setcleanZipCodeValue)} value={cleanZipCodeValue} />
                {validationErrors.zipcode && <span style={{ color: 'red' }}>{validationErrors.zipcode}</span>}
              </fieldset>
              <fieldset className="col-2 col-5-on-breakpoint-ml col-2-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>Cidade</label>
                <input type="text" id="location" name="location" placeholder="Concelho" onChange={handleInputChange} />
              </fieldset>
              <fieldset className="col-4 col-8-on-breakpoint-ml col-4-on-breakpoint-m col-1-on-breakpoint-sm">
                <label>NIF (opcional)</label>
                <input type="text" id="nif" name="nif" placeholder="000 000 000" onChange={(e) => handleNIFInputChange(e, setcleanNIFValue)} value={cleanNIFValue} />
              </fieldset>
            </div>
          </>
        )}
        <hr className="hr-l" />
        <div className="flex-row justify-between gap-m">
          <h5>Dados de Pagamento</h5>
          <span className="flex-row align-center gap-xxs text-s">Assegurado por Stripe <i className="icon-lock"></i></span>
        </div>
        <hr className="hr-m" />

        <div className="border-gray-lighter p-ml">
          <PaymentElement id="payment-element" />
        </div>
        <hr className="hr-l" />
        <button disabled={isProcessing || !stripe || !elements} id="submit" className='btn-l btn-primary-fill'>
          <span id="button-text">
            {isProcessing ? "A processar ... " : "Pagar"}
          </span>
        </button>
        <hr className="hr-l" />
      </form>
    </>
  );
};

export default CheckoutForm;