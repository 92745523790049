import React from "react";
import SwipperComponent from "render/views/components/SwipperComponent";
import { SwiperSlide } from "swiper/react";

function StoreBanner() {
    // Fixed array 5 images
    const bannerImages = Array.from({ length: 5 }, (_, index) => index);

    return (

        <SwipperComponent settings={{ slidesPerView: 1, spaceBetween: 0, autoplay: {delay: 5000, pauseOnMouseEnter: false} }}>
            {bannerImages.map(index => (
                <SwiperSlide key={index}>
                <div className="store-banner">
                    <img className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/store-banner-${index + 1}.jpg`} alt='' />
                </div>
                </SwiperSlide>
            ))}
        </SwipperComponent>
  );
}

export default StoreBanner;
