import React from "react"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useLoading } from 'controller/hooks/hooks';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ROUTES } from "controller/routes/routes";
import * as api from 'api/SthenosAPI'
import CheckoutForm from './CheckoutForm';

import { useSelector, useDispatch } from "react-redux"
import { selectTotalQuantity, selectTotalValue } from "controller/redux/StoreSlice";

export default function CheckoutPage() {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [loading, setLoading] = useLoading();
    const totalValue = useSelector(selectTotalValue);
    const nrItems = useSelector(selectTotalQuantity);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        async function fetchPaymentConfig() {
            await api.getData(api.API_ENDPOINTS.PAYMENT, setLoading,
                (response) => {
                    setStripePromise(loadStripe(response.publishableKey))
                },
                (error) => {
                    console.error(error);
                }
            );
        }
        fetchPaymentConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const payment_data = {
            payment: {
                value: totalValue,
            },
        };
        async function postPaymentIntent() {
            await api.postData(api.API_ENDPOINTS.PAYMENT, payment_data, setLoading,
                (response) => {
                    setClientSecret(response.clientSecret)
                },
                (error) => {
                    console.error(error);
                }
            );
        }
        postPaymentIntent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProcessingState = (processing) => {
        setIsProcessing(processing);
    };

    const handleFinalizePurchase = () => {
        const submitButton = document.getElementById('submit');

        if (submitButton) {
            submitButton.click();
        }
    };


    const customStripeAppearance = {
        theme: 'flat',
        variables: {
            borderRadius: '0',
            colorPrimary: '#D6B56B',
            colorBackground: '#ffffff',
            colorBorder: '#BFBFBF',
            buttonPrimaryColorBorder: '#BFBFBF',
            buttonSecondaryColorBorder: '#BFBFBF',
            badgeNeutralColorBorder: '#BFBFBF',
            fontFamily: 'Roboto, sans-serif',
            fontSizeBase: '13px'
        },
    };

    const options = {
        clientSecret: clientSecret,
        appearance: customStripeAppearance,
    };

    return (
        <div id="page-container" page="cart">
            <Helmet>
                <title>Sthenos | Checkout</title>
            </Helmet>

            <section className="container-m fullheight">
                <div className="page-header-no-image">
                    <h1 className="uppercase text-gray-offset">A tua encomenda</h1>
                    <hr className="hr-s" />
                    <div className="breadcrumb">
                        <Link to={ROUTES.SHOPPING_CART}><h5 className="text-gray-light">Resumo</h5></Link>
                        <h5 className="text-accent">Morada e Pagamento</h5>
                    </div>
                </div>
                <hr className="hr-xl" />

                <div id="checkout-grid">
                    <div>
                        {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm onProcessingStateChange={handleProcessingState} />
                            </Elements>
                        )}
                    </div>

                    <div>
                        <div id="checkout-action">
                            <h5>Resumo da encomenda</h5>
                            <ul className="flex-column">
                                <li className="list-item">
                                    <p className="text-gray-light">Subtotal ({nrItems} {nrItems === 1 ? 'artigo' : 'artigos'})</p>
                                    <p>{totalValue / 100 - 5}€</p>
                                </li>
                                <li className="list-item">
                                    <p className="text-gray-light">Entrega ao Domicílio</p>
                                    <p>5€</p>
                                </li>
                            </ul>

                            <div className="flex-row align-center justify-end gap-m">
                                <p className="gray-light">Total</p>
                                <h3>{totalValue / 100}€</h3>
                            </div>

                            <hr className="hr-xs" />

                            <button onClick={handleFinalizePurchase} to={ROUTES.CHECKOUT} className="w-100 btn-l btn-primary-fill"> {isProcessing ? "A processar ..." : "Confirmar Encomenda"}</button>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="hr-xxl" />
        </div>
    );
};