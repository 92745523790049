import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCJ0X9vKs7aca2EQywul0XF9DBA8lZSSMw",
    authDomain: "sthenos.firebaseapp.com",
    projectId: "sthenos",
    storageBucket: "sthenos.appspot.com",
    messagingSenderId: "106451724342",
    appId: "1:106451724342:web:56c9e52ae5ed5c817d9097",
    measurementId: "G-JGW12K5NDQ"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };