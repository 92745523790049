import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api/SthenosAPI';
import { fetchData, fetchDataLesson } from 'scripts/utils/Utils';
import { groupAndSortSchedule, generateFetchActionReducers, generateExtraReducers } from 'scripts/utils/Utils';

const initialState = {
    courses: [],
    course: {},
    testimonials: [],
    ebooks: [],
    compains: [],
    schedule: [],
    boxes: [],
    box: {},
    exercises: [],
    trainers: [],
    subscriptions: [],
    online_subscriptions: [],
    lessons: [],
    lesson: {},
    training_plans: [],
    training_plan: {},
    isDataFetched: false,
    status: 'idle',
    error: null,
};

const HOME_ACTIONS = {
    FETCH_COURSE: 'home/fetchCourse',
    FETCH_LESSON: 'home/fetchLesson',
    FETCH_EXERCISES: 'home/fetchExercises',
    FETCH_BOXES: 'home/fetchBoxes',
    FETCH_BOX: 'home/fetchBox',
    FETCH_SCHEDULE: 'home/fetchSchedule',
    FETCH_TRAINERS: 'home/fetchTreiners',
    FETCH_COURSES: 'home/fetchCourses',
    FETCH_CAMPAINS: 'home/fetchCampains',
    FETCH_LESSONS: 'home/fetchLessons',
    FETCH_TESTIMONIALS: 'home/fetchTestimonials',
    FETCH_EBOOKS: 'home/fetchEbooks',
    FETCH_SUBSCRIPTIONS: 'home/fetchSubscriptions',
    FETCH_ONLINE_SUBSCRIPTIONS: 'home/fetchOnlineSubscriptions',
    FETCH_TRAINING_PLANS: 'home/fetchTrainingPlans',
    FETCH_TRAINING_PLAN: 'home/fetchTrainingPlan',
};

export const fetchCampains = createAsyncThunk(
    HOME_ACTIONS.FETCH_CAMPAINS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.CAMPAIN)
    }
);

export const fetchCourse = createAsyncThunk(
    HOME_ACTIONS.FETCH_COURSE,
    async (slug) => {
        return fetchData(api, api.API_ENDPOINTS.COURSE_DETAILED, slug)
    }
);

export const fetchLesson = createAsyncThunk(
    HOME_ACTIONS.FETCH_LESSON,
    async (slug) => {
        return fetchDataLesson(api, slug)
    }
);

export const fetchBox = createAsyncThunk(
    HOME_ACTIONS.FETCH_BOX,
    async (slug) => {
        return fetchData(api, api.API_ENDPOINTS.BOX_DETAILED, slug)
    }
);

export const fetchExercises = createAsyncThunk(
    HOME_ACTIONS.FETCH_EXERCISES,
    async (slug) => {
        return fetchData(api, api.API_ENDPOINTS.EXERCISES)
    }
);

export const fetchBoxes = createAsyncThunk(
    HOME_ACTIONS.FETCH_BOXES,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.BOXES)
    }
);

export const fetchSchedule = createAsyncThunk(
    HOME_ACTIONS.FETCH_SCHEDULE,
    async () => {
        const response = await fetchData(api, api.API_ENDPOINTS.SCHEDULE)
        const groupedSchedule = groupAndSortSchedule(response);
        return groupedSchedule;
    }
);

export const fetchTrainers = createAsyncThunk(
    HOME_ACTIONS.FETCH_TRAINERS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.TRAINERS)
    }
);

export const fetchCourses = createAsyncThunk(
    HOME_ACTIONS.FETCH_COURSES,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.COURSES)
    }
);

export const fetchLessons = createAsyncThunk(
    HOME_ACTIONS.FETCH_LESSONS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.LESSONS)
    }
);

export const fetchEbooks = createAsyncThunk(
    HOME_ACTIONS.FETCH_EBOOKS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.EBOOKS)
    }
);

export const fetchTestimonials = createAsyncThunk(
    HOME_ACTIONS.FETCH_TESTIMONIALS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.TESTIMONIALS)
    }
);

export const fetchTrainingPlans = createAsyncThunk(
    HOME_ACTIONS.FETCH_TRAINING_PLANS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.TRAINING_PLANS)
    });

export const fetchTrainingPlan = createAsyncThunk(
    HOME_ACTIONS.FETCH_TRAINING_PLAN,
    async (slug) => {
        return fetchData(api, api.API_ENDPOINTS.TRAINING_PLAN, slug)
    }
);

export const fetchSubscriptions = createAsyncThunk(
    HOME_ACTIONS.FETCH_SUBSCRIPTIONS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.SUBSCRIPTIONS)
    }
);

export const fetchOnlineSubscriptions = createAsyncThunk(
    HOME_ACTIONS.FETCH_ONLINE_SUBSCRIPTIONS,
    async () => {
        return fetchData(api, api.API_ENDPOINTS.ONLINE_SUBSCRIPTIONS)
    }
);

const fetchActions = [
    { action: fetchCourses, reducers: generateFetchActionReducers('courses') },
    { action: fetchLessons, reducers: generateFetchActionReducers('lessons') },
    { action: fetchTestimonials, reducers: generateFetchActionReducers('testimonials') },
    { action: fetchSubscriptions, reducers: generateFetchActionReducers('subscriptions') },
    { action: fetchOnlineSubscriptions, reducers: generateFetchActionReducers('online_subscriptions') },
    { action: fetchTrainers, reducers: generateFetchActionReducers('trainers') },
    { action: fetchSchedule, reducers: generateFetchActionReducers('schedule') },
    { action: fetchExercises, reducers: generateFetchActionReducers('exercises') },
    { action: fetchBoxes, reducers: generateFetchActionReducers('boxes') },
    { action: fetchBox, reducers: generateFetchActionReducers('box') },
    { action: fetchCourse, reducers: generateFetchActionReducers('course') },
    { action: fetchLesson, reducers: generateFetchActionReducers('lesson') },
    { action: fetchTrainingPlans, reducers: generateFetchActionReducers('training_plans') },
    { action: fetchTrainingPlan, reducers: generateFetchActionReducers('training_plan') },
    { action: fetchEbooks, reducers: generateFetchActionReducers('ebooks') },
    { action: fetchCampains, reducers: generateFetchActionReducers('campains') },
];

const HomeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setDataFetched: (state, action) => {
            state.isDataFetched = action.payload;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setDataFetched } = HomeSlice.actions;

export default HomeSlice.reducer;