import React, { useEffect } from "react"
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useWindowDimensions, useDelayedLoading } from 'controller/hooks/hooks';

import { getDataBySlug } from "scripts/utils/Utils";

import { useSelector, useDispatch } from "react-redux"
import { fetchEbooks } from "controller/redux/HomeSlice";
import { MODAL_TYPES, useModal } from "controller/providers/ModalContext";

function EbooksDetails() {
  const dimensions = useWindowDimensions();

  const { slug } = useParams();
  const { ebooks, isDataFetched } = useSelector((state) => state.home)
  const error = useSelector((state) => state.home.error);
  const { handleShow, handleClose } = useModal();

  const dispatch = useDispatch();

  const ebook = getDataBySlug(ebooks, slug);

  const handleAddToCart = () => {
    handleShow(MODAL_TYPES.EXTERNAL_SITE, { handleClose, url: ebook.url })
  };

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(fetchEbooks())
    }
  }, [dispatch, isDataFetched]);

  return useDelayedLoading(ebook)(() => {
    return (
      <div id="ebook-detail" page="ebook-detail">
        <Helmet>
          <title>Sthenos | {slug.charAt(0).toUpperCase() + slug.slice(1)}</title>
        </Helmet>
        {((ebook && ebook.slug === slug) && Object.keys(ebook).length !== 0 && ebooks && ebooks.length !== 0) ? (
          <div className="container grid-2 gap-xxl gap-xl-on-breakpoint-xxl gap-l-on-breakpoint-m grid-1-on-breakpoint-sm">
            <div className="page-header-no-image container">
              <div className="image-container" ratio="1:1">
                <img
                  className="img-cover"
                  src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/ebooks/sthenos-fit-ebook-${ebook.slug}.jpg`}
                  alt="Ebook"
                />
              </div>
            </div>

            <div className="page-header-no-image container">
              <hr className="hr-s" />
              <h2 className="uppercase text-gray-offset">Ebook {ebook.name}</h2>

              <hr className="hr-m" />

              {ebook.discount == null || ebook.discount == 0 ? (
                <h3 className="text-accent">{ebook.price}€</h3>
              ) : (
                <div className="flex-column">
                  <h5 className="text-gray line-through">{ebook.price}€</h5>
                  <h3 className="text-accent">{ebook.price - ebook.discount}€</h3>
                </div>
              )}

              <hr className="hr-m" />

              <p className="text-description-xxl">
                {ebook.short_description_pt}
              </p>

              <hr className="hr-m" />

              <p>
                {ebook.description_pt}
              </p>

              <hr className="hr-l" />

              <button onClick={handleAddToCart} type="button" className="btn-l btn-primary-fill">
                <span>Comprar Ebook</span>
              </button>

            </div>

            {/* <section>
              <div className="container">
                <h1>{ebook.name}</h1>
                <h1>{ebook.short_description_pt}</h1>
                <p>{ebook.description_pt}</p>
              </div>
            </section> */}

          </div>) :
            <div>No data found</div>
        }
        <hr className="hr-xxl" />
      </div>
    );
  });
}


export default EbooksDetails;