import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api/SthenosAPI';
import { LOCAL_STORAGE_ROUTES } from 'scripts/Constants';
import { generateFetchActionReducers, generateExtraReducers } from 'scripts/utils/Utils';
import { getLocalData, setLocalData, removeLocalData } from 'scripts/utils/Utils';

const initialState = {
    storeItems: [],
    storeItem: {},
    shoppingCart: [],
    status: 'idle',
    error: null,
};

const STORE_ACTIONS = {
    FETCH_STORE_ITEMS: 'store/fetchStoreItems',
    FETCH_STORE_ITEM: 'store/fetchStoreItem',
};

export const fetchStoreItems = createAsyncThunk(
    'store/fetchStoreItems', // Assuming 'store/fetchStoreItems' is the action string
    async () => {
        const storeItemsResponse = await api.getReturnData(api.API_ENDPOINTS.STORE_ITEMS);
        const storeItemsWithInventory = await Promise.all(storeItemsResponse.map(async (item) => {
            const inventoryResponse = await api.getReturnData(api.API_ENDPOINTS.STORE_ITEM_DETAILS + item.slug);
            return {
                ...item,
                inventory: inventoryResponse.inventory
            };
        }));
        return storeItemsWithInventory;
    }
);

export const fetchStoreItem = createAsyncThunk(
    STORE_ACTIONS.FETCH_STORE_ITEM,
    async (itemId) => {
        const response = await api.getReturnData(api.API_ENDPOINTS.STORE_ITEM_DETAILS + itemId);
        return response; // ALTERAR ISTO PORQUE NAO DEVE DEVOLVER ARRAY
    }
);

const fetchActions = [
    { action: fetchStoreItems, reducers: generateFetchActionReducers('storeItems') },
    { action: fetchStoreItem, reducers: generateFetchActionReducers('storeItem') },
];

const loadShoppingCart = () => {
    const shoppingCart = Object.values(getLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART)) || [];
    return shoppingCart;
};

const StoreSlice = createSlice({
    name: 'store',
    initialState: {
        ...initialState,
        shoppingCart: loadShoppingCart(),
    },
    reducers: {
        setStoreItem: (state, action) => {
            state.storeItem = action.payload;
        },
        addItemToCart: (state, action) => {
            const storeItem = action.payload;
            const cartItemIndex = state.shoppingCart.findIndex(
                (item) => item.storeItem.slug === storeItem.slug && item.storeItem.selectedColor === storeItem.selectedColor && item.storeItem.selectedSize === storeItem.selectedSize
            );
            if (cartItemIndex >= 0) {
                state.shoppingCart[cartItemIndex].quantity += 1;
            } else {
                state.shoppingCart.push({
                    storeItem,
                    quantity: 1,
                });
            }
            setLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART, state.shoppingCart);
        },
        increaseQuantity(state, action) {
            const storeItem = action.payload;
            const cartItemIndex = state.shoppingCart.findIndex(
                (item) => item.storeItem.slug === storeItem.slug && item.storeItem.selectedColor === storeItem.selectedColor && item.storeItem.selectedSize === storeItem.selectedSize
            );
            if (state.shoppingCart[cartItemIndex]) {
                state.shoppingCart[cartItemIndex].quantity++;
                setLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART, state.shoppingCart);
            }
        },
        decreaseQuantity(state, action) {
            const storeItem = action.payload;
            const cartItemIndex = state.shoppingCart.findIndex(
                (item) => item.storeItem.slug === storeItem.slug && item.storeItem.selectedColor === storeItem.selectedColor && item.storeItem.selectedSize === storeItem.selectedSize
            );
            if (state.shoppingCart[cartItemIndex]) {
                state.shoppingCart[cartItemIndex].quantity--;
                if (state.shoppingCart[cartItemIndex].quantity === 0) {
                    state.shoppingCart.splice(cartItemIndex, 1);
                    removeLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART);
                }
                setLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART, state.shoppingCart);
            }
        },
        deleteItem(state, action) {
            const storeItem = action.payload;
            const cartItemIndex = state.shoppingCart.findIndex(
                (item) => item.storeItem.slug === storeItem.slug && item.storeItem.selectedColor === storeItem.selectedColor && item.storeItem.selectedSize === storeItem.selectedSize
            );
            if (state.shoppingCart[cartItemIndex]) {
                state.shoppingCart.splice(cartItemIndex, 1);
                removeLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART);
                setLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART, state.shoppingCart);
            }
        },
        clearShoppingCart: (state) => {
            state.shoppingCart = [];
            removeLocalData(LOCAL_STORAGE_ROUTES.SHOPPING_CART);
        }
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const selectTotalValue = state =>
    (state.store.shoppingCart.reduce((total, cartItem) => total + ((cartItem.storeItem.price - cartItem.storeItem.discount) * cartItem.quantity), 0) * 100) + 500; // + 500 (5€ de portes de envio)

export const selectTotalQuantity = state =>
    state.store.shoppingCart.reduce((total, cartItem) => total + cartItem.quantity, 0);


export const { setStoreItem, addItemToCart, clearShoppingCart, increaseQuantity, decreaseQuantity, deleteItem } = StoreSlice.actions;

export default StoreSlice.reducer;