import React, { useState, useEffect } from 'react';
import { BODYPARTS_GROUPS } from 'scripts/Constants';

const FilterModal = ({ props }) => {

    const {
        selectedBodyPartGroups, setSelectedBodyPartGroups,
        selectedDifficulties, setSelectedDifficulties,
        selectedGears, setSelectedGears,
        selectedCategories, setSelectedCategories,
        type, clearAllFilters, isFilterApplied, handleClose, list
    } = props;

    const [tempSelectedBodyPartGroups, setTempSelectedBodyPartGroups] = useState(selectedBodyPartGroups);
    const [tempSelectedDifficulties, setTempSelectedDifficulties] = useState(selectedDifficulties);
    const [tempSelectedGears, setTempSelectedGears] = useState(selectedGears);
    const [tempSelectedCategories, setTempSelectedCategories] = useState(selectedCategories);

    const uniqueDifficulties = [...new Set(list.map(item => item.difficulty))];
    const uniqueGears = [...new Set(list.flatMap(item => item.gear))];
    const uniqueCategories = [...new Set(list.map(item => item.category))];
    const uniqueBodyParts = [...new Set(list.flatMap(item => item.bodyParts.map(bodyPart => bodyPart.name)))];

    let uniqueGroups = [];

    for (let group in BODYPARTS_GROUPS) {
        if (uniqueBodyParts.some(part => BODYPARTS_GROUPS[group].includes(part))) {
            uniqueGroups.push(group);
        }
    }

    useEffect(() => {
        setTempSelectedBodyPartGroups(selectedBodyPartGroups);
        setTempSelectedDifficulties(selectedDifficulties);
        setTempSelectedGears(selectedGears);
        setTempSelectedCategories(selectedCategories);
    }, [selectedBodyPartGroups, selectedDifficulties, selectedGears, selectedCategories]);

    const handleTempFilterClick = (setSelected, item) => {
        setSelected(prevItems =>
            prevItems.includes(item)
                ? prevItems.filter(i => i !== item)
                : [...prevItems, item]
        );
    }

    return (
        <>
            <div className="flex-row align-end gap-m">
                <h3 className="line-height-1">{type === 'exercise' ? `Filtrar exercícios` : `Filtrar aulas`}</h3>
                <div>
                    {isFilterApplied() &&
                        <button
                            className='btn-s btn-primary-ghost'
                            onClick={() => {
                                clearAllFilters();
                                setTempSelectedBodyPartGroups([]);
                                setTempSelectedDifficulties([]);
                                setTempSelectedGears([]);
                                setTempSelectedCategories([]);
                            }}
                        >
                            <i className="icon-close" ></i><span>Limpar filtros</span>
                        </button>
                    }
                </div>
            </div>
            <hr className="hr-l" />
            <div className="content flex-column gap-s">
                {uniqueGroups.length > 1 && (
                    <div className="filter-group">
                        <p className="filter-group--label">por Grupos Musculares:</p>
                        <div className="filter-group--buttons">
                            {uniqueGroups.map(group => (
                                <button className={`btn-ml btn-secondary-ghost ${tempSelectedBodyPartGroups.includes(group) ? 'active' : ''}`}
                                    key={group}
                                    onClick={() => handleTempFilterClick(setTempSelectedBodyPartGroups, group)}
                                >
                                    {group}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                {type === 'exercise' && (
                    <>
                        {uniqueDifficulties.length > 1 && (
                            <div className="filter-group">
                                <p className="filter-group--label">por Dificuldade:</p>
                                <div className="filter-group--buttons">
                                    {uniqueDifficulties.map(difficulty => (
                                        <button
                                            className={`btn-ml btn-secondary-ghost ${tempSelectedDifficulties.includes(difficulty) ? 'active' : ''}`}
                                            key={difficulty}
                                            onClick={() => handleTempFilterClick(setTempSelectedDifficulties, difficulty)}
                                        >
                                            {difficulty}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                        {uniqueGears.length > 1 && (
                            <div className="filter-group">
                                <p className="filter-group--label">por Equipamento:</p>
                                <div className="filter-group--buttons">
                                    {uniqueGears.map(gear => (
                                        <button
                                            className={`btn-ml btn-secondary-ghost ${tempSelectedGears.includes(gear) ? 'active' : ''}`}
                                            key={gear}
                                            onClick={() => handleTempFilterClick(setTempSelectedGears, gear)}
                                        >
                                            {gear}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                        {uniqueCategories.length > 1 && (
                            <div className="filter-group">
                                <p className="filter-group--label">por Categoria:</p>
                                <div className="filter-group--buttons">
                                    {uniqueCategories.map(category => (
                                        <button
                                            className={`btn-ml btn-secondary-ghost ${tempSelectedCategories.includes(category) ? 'active' : ''}`}
                                            key={category}
                                            onClick={() => handleTempFilterClick(setTempSelectedCategories, category)}
                                        >
                                            {category}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <button className="btn inline-link text-gray-dark" type="reset" onClick={handleClose} >Cancelar</button>
                <button
                    className='btn btn-primary-fill'
                    onClick={() => {
                        setSelectedBodyPartGroups(tempSelectedBodyPartGroups);
                        setSelectedDifficulties(tempSelectedDifficulties);
                        setSelectedGears(tempSelectedGears);
                        setSelectedCategories(tempSelectedCategories);
                        handleClose();
                    }}
                >
                    <span>Filtrar</span>
                </button>
            </div>
        </>
    );
};

export default FilterModal;
