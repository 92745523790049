import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';

import { useWindowDimensions } from 'controller/hooks/hooks';

import { HOME_MIN_WIDTH_SLIDER } from 'scripts/Constants';

import LessonComponent from 'render/views/pages/Lessons/LessonComponent';
import ScheduleComponent from 'render/views/components/ScheduleComponent';
import LoadingPageComponent from 'render/views/components/LoadingPageComponent';

import { useTranslation } from 'react-i18next';

import { fetchLessons, fetchSchedule } from "controller/redux/HomeSlice";
import { useSelector, useDispatch } from "react-redux"
import FilterComponent from "render/views/components/FilterComponent";
import ScheduleSlider from 'render/views/components/ScheduleSlider';


function LessonsPage() {

  const dimensions = useWindowDimensions();

  const { t } = useTranslation();

  const { lessons, schedule, isDataFetched } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [filteredLessons,setFilteredLessons] = useState(lessons)

  function handleFilter(filtered) {
    setFilteredLessons(filtered)
  }

  const [selectedBox_schedule, setSelectedBoxSchedule] = useState('carnaxide');  

  useEffect(() => {
    if (!isDataFetched) {
      Promise.all([
        dispatch(fetchLessons()),
        dispatch(fetchSchedule())]).then(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, isDataFetched]);

  if (isLoading) {
    return (
      <LoadingPageComponent />
    )
  }

  return (
    <div id="page-container" page="lessons-list">
      <Helmet>
        <title>Sthenos | Aulas </title>
        
        <meta name="description" content="Vem conhecer as nossas aulas!" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
        </Helmet>
      
      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>

      <section className="container-m p-0">
        <section className="container-m">
          <h1 className="page-title">{t('lessons_page_label')}</h1>
          <hr className="hr-m" />
        </section>
        <section className="container-xs">
          <p className="text-l text-white-offset text-center">
            {t('lessons_page_desc')}
          </p>
        </section>
        <hr className="hr-xxl" />

        <section className="container-m" id="section-home-schedule">
          <div className="filter-group--buttons justify-center gap-0">
            <button className={`btn-ml btn-secondary-ghost ${selectedBox_schedule.includes('carnaxide') ? 'active' : ''}`} onClick={() => setSelectedBoxSchedule('carnaxide')}> Carnaxide</button>
            <button className={`btn-ml btn-secondary-ghost ${selectedBox_schedule.includes('benfica') ? 'active' : ''}`} onClick={() => setSelectedBoxSchedule('benfica')}> Benfica</button>
          </div>
          <hr className="hr-l" />
          {dimensions.width < HOME_MIN_WIDTH_SLIDER ? <ScheduleSlider box={selectedBox_schedule} schedule={schedule} /> : <ScheduleComponent box={selectedBox_schedule} schedule={schedule} />}
        </section>

        <hr className="hr-m" />
        <hr className="hr-xxl vertical-line" />
        <hr className="hr-l" />

        <section className="container-m">

        {lessons && filteredLessons &&
          <FilterComponent
            list={lessons}
            onFilter={handleFilter}
            type="lesson"
          />
          }
            {lessons && filteredLessons &&
              <LessonComponent lessons={filteredLessons} classStyle="grid-4 gap-l-on-breakpoint-l grid-3-on-breakpoint-ml grid-2-on-breakpoint-m gap-s-on-breakpoint-xs grid-1-on-breakpoint-xxs gap-l-on-breakpoint-xxs" />
            }
        </section>

        <hr className="hr-xxl" />
        <hr className="hr-xxl" />
      </section>
    </div>
  );
};

export default LessonsPage;