import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from 'controller/routes/routes';

const PopupModal = ({ props }) => {

    const { handleClose } = props;

    return (
        <>
            <Link to={ROUTES.STORE}>
                <img
                    src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/popup-loja.jpg`}
                    onClick={handleClose}
                    alt="Loja Online Aproveita já disponível com descontos incríveis"
                    style={{ cursor: 'pointer' }}
                />
            </Link>
        </>
    );
};

export default PopupModal;
