import React from "react";

function LoadingPageComponent() {

    return (
        
        <div id="page-loading-container">
            <div className="content">
                <div className="image-container" ratio="2:3">
                    <img alt="" className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-loading-frames-1.jpg`}/>
                    <img alt="" className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-loading-frames-2.jpg`}/>
                    <img alt="" className="img-cover" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-loading-frames-1.jpg`}/>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{enableBackground:"new 0 0 500 500"}} version="1.1" viewBox="0 0 500 500">
                <polygon points="279.81,0 279.81,89.44 410.56,89.44 410.56,220.19 500,220.19 500,31.06 468.94,0"/>
                <polygon points="309.63,369.26 409.48,369.26 440.37,338.36 440.37,279.81 220.19,279.81 220.19,500 278.73,500 309.63,469.1"/>
                <polygon points="161.64,59.63 130.74,90.52 130.74,190.37 30.9,190.37 0,221.27 0,279.81 220.19,279.81 220.19,59.63"/>
                </svg>
            </div>
            <div className="footer">
                <span>a carregar<span>...</span></span>
                <div className="loading--animation"></div>
            </div>
        </div>
    );

}

export default LoadingPageComponent;