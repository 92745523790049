import axios from 'axios'

export const API_ENDPOINTS = {
    BOX_DETAILED: `${process.env.REACT_APP_API_SERVER}/boxs/getBoxDetails/`,
    BOXES: `${process.env.REACT_APP_API_SERVER}/box`,
    COURSE_DETAILED: `${process.env.REACT_APP_API_SERVER}/course/`,
    CREATE_PROFILE: `${process.env.REACT_APP_API_SERVER}/profile`,
    COURSES: `${process.env.REACT_APP_API_SERVER}/course`,
    EMAIL_CONFIRM_NEWSLETTER: `${process.env.REACT_APP_API_SERVER}/email/confirm-newsletter`,
    EXERCISES: `${process.env.REACT_APP_API_SERVER}/exercise`,
    FETCH_PROFILE: `${process.env.REACT_APP_API_SERVER}/profile/`,
    LESSONS: `${process.env.REACT_APP_API_SERVER}/lesson`,
    LESSONS_DETAILED: `${process.env.REACT_APP_API_SERVER}/lesson/`, //Expecting a slug as input
    LOGIN: `${process.env.REACT_APP_API_SERVER}/auth/login`,
    ONLINE_SUBSCRIPTIONS: `${process.env.REACT_APP_API_SERVER}/online_subscription/all`,
    ORDER: `${process.env.REACT_APP_API_SERVER}/online_subscription/all`,
    PAYMENT: `${process.env.REACT_APP_API_SERVER}/payment`,
    CREATE_ORDER: `${process.env.REACT_APP_API_SERVER}/payment/order`,
    REGISTER: `${process.env.REACT_APP_API_SERVER}/auth/registerNoVerification`,
    SCHEDULE: `${process.env.REACT_APP_API_SERVER}/schedule`,
    STORE_ITEM_DETAILS: `${process.env.REACT_APP_API_SERVER}/shop/`,
    STORE_ITEMS: `${process.env.REACT_APP_API_SERVER}/shop`,
    STHENOS_AI: `${process.env.REACT_APP_API_SERVER}/sthenos-ai-chat`,
    SUBSCRIBE_EMAIL: `${process.env.REACT_APP_API_SERVER}/newsletter/subscribe`,
    SUBSCRIPTIONS: `${process.env.REACT_APP_API_SERVER}/subscription`,
    TESTIMONIALS: `${process.env.REACT_APP_API_SERVER}/testimonial`,
    EBOOKS: `${process.env.REACT_APP_API_SERVER}/ebook`,
    CAMPAIN: `${process.env.REACT_APP_API_SERVER}/campaign`,
    TRAINERS: `${process.env.REACT_APP_API_SERVER}/trainer`,
    TRAINING_PLAN: `${process.env.REACT_APP_API_SERVER}/training_plans/details/`, //Expecting a slug as input
    TRAINING_PLANS: `${process.env.REACT_APP_API_SERVER}/training_plans/all`,
};

export const API_ERROR_CODES = {
    EMAIL_NOT_FOUND: 404,
    WRONG_PASSWORD: 401,
};

/**
 * 
 * @param {*} endpoint Backend endpoint URL based on API_ENDPOINTS
 * @param {*} setLoading Loading useState to be handle the loading of the GET
 * @param {*} successCallback sucess callback to handle the response
 * @param {*} errorCallback error callback to handle the response
 * @param {*} timeout timeout to make the loading not instantaneos, this will be 0 if not defined
 */
export const getData = async (endpoint, setLoading, successCallback, errorCallback, timeout = 0) => {
    if (setLoading) setLoading(true);
    try {
        const response = await axios.get(endpoint);
        await new Promise(resolve => setTimeout(resolve, timeout));
        if (setLoading) setLoading(false);
        if (!response.data.hasOwnProperty('statusCode')) {
            successCallback(response.data);
        } else {
            errorCallback(response);
        }
    } catch (error) {
        console.log(error)
        await new Promise(resolve => setTimeout(resolve, timeout));
        if (setLoading) setLoading(false);
    }
};

export const getReturnData = async (endpoint) => {
    try {
        const response = await axios.get(endpoint);
        if (!response.data.hasOwnProperty('statusCode')) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error)
    }
};

/**
 * 
 * @param {*} endpoint 
 * @param {*} data 
 * @param {*} setLoading 
 * @param {*} successCallback 
 * @param {*} errorCallback 
 * @param {*} timeout 
 */
export const postData = async (endpoint, data, setLoading, successCallback, errorCallback, timeout = 0) => {
    if (setLoading) setLoading(true);
    try {
        const response = await axios.post(endpoint, data);
        await new Promise(resolve => setTimeout(resolve, timeout));
        if (setLoading) setLoading(false);
        if (!response.data.hasOwnProperty('statusCode')) {
            successCallback(response.data);
        } else {
            errorCallback(response.data);
        }
    } catch (error) {
        console.log(error)
        await new Promise(resolve => setTimeout(resolve, timeout));
        if (setLoading) setLoading(false);
    }
};

export const postReturnData = async (endpoint, data) => {
    try {
        const response = await axios.post(endpoint, data);
        if (!response.data.hasOwnProperty('statusCode')) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error)
    }
};







