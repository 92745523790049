import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from "./render/views/components/HeaderComponent";
import Footer from "./render/views/components/FooterComponent";
import RoutesFile from './controller/routes/routes';
import { MainProvider } from './controller/providers/MainProvider';
import PopupProvider from 'controller/providers/PopupProvider';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <MainProvider>
      <PopupProvider />
      <main>
        <Header />
        <RoutesFile />
        <Footer />
      </main>
    </MainProvider>
  );
}

export default App;