import React, { useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

import { ROUTES } from 'controller/routes/routes';
import { useLocation } from "react-router-dom";
import { LAZY_LOADING_ITEMS } from "scripts/Constants";
import InfiniteLoaderComponent from "render/views/components/InfiniteLoaderComponent";
import { getColorValue } from "scripts/utils/Utils";

function StoreItemComponent({ storeItems, featured, nr_max_items }) {
  const [count, setCount] = useState(LAZY_LOADING_ITEMS);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setCount(count => count + LAZY_LOADING_ITEMS);
      setIsLoading(false);
    }, 500);
  };

  const location = useLocation();

  const filteredStoreItems = featured ? storeItems.filter(item => item.featured === true).slice(0, nr_max_items) : storeItems;

  return (
    <>
      <InfiniteScroll
        dataLength={count}
        next={loadMore}
        scrollThreshold={0.5}
        hasMore={count < filteredStoreItems.length}
        style={{ overflow: 'hidden' }}
      >
        <div className={location.pathname === "/" ? ("start-as-flex flex-row justify-end gap-l grid-3-on-breakpoint-ml gap-m-on-breakpoint-sm grid-2-on-breakpoint-m gap-s-on-breakpoint-xs") : ("grid-5 gap-l gap-row-xl grid-4-on-breakpoint-xl grid-3-on-breakpoint-l gap-m-on-breakpoint-sm gap-row-l-on-breakpoint-sm grid-2-on-breakpoint-m gap-s-on-breakpoint-xs gap-row-m-on-breakpoint-xs")}>
          {filteredStoreItems.slice(0, count).map((storeItem, index) => (
            <Link to={`${ROUTES.STORE}/${storeItem.slug}`} className="store-item" key={index} stock={storeItem.inventory && storeItem.inventory.length > 0 ? "true" : "false"}>
              <figure>
                <div className={`bg-gray image-container ${!storeItem.inventory || storeItem.inventory.length === 0 ? 'out-of-stock' : ''}`} ratio="2:3">
                  <img className="img-cover" img-type="standard" src={`${process.env.REACT_APP_MEDIA_BASE_PATH}/shop/${storeItem.photo}`} alt={storeItem.name} />
                  {storeItem.inventory && storeItem.inventory.length > 0 ? (
                    <div className="store-item--image-info-overlay">
                      {
                        // Convert inventory to an array of colors, ensuring each color is unique
                        [...new Set(storeItem.inventory.map(item => item.color))]
                          // Map over the unique colors to render the divs
                          .filter(color => color !== 'no-color')
                          .map((color, index) => (
                            <div key={index} style={{ backgroundColor: getColorValue(color) }}></div>
                          ))
                      }
                    </div>
                  ) : (
                    <div className="store-item--image-info-overlay">
                      <p>Esgotado</p>
                    </div>
                  )}
                  <div className="store-item--image-overlay">
                    <span></span>
                    <p className="store-item--image-overlay-cta"><span><i className="icon-close"></i></span></p>
                  </div>
                </div>
                <figcaption>
                  <h5 className="store-item--title">{storeItem.name}</h5>
                  {storeItem.discount == null || storeItem.discount == 0 ? (
                    <h5 className="store-item--price">{storeItem.price}€</h5>
                  ) : (
                    <div className="flex-column">
                      <h5 className="text-s text-gray-light line-through">{storeItem.price}€</h5>
                      <h5 className="store-item--price">{storeItem.price - storeItem.discount}€</h5>
                    </div>
                  )}
                </figcaption>
              </figure>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      {isLoading && (
        <InfiniteLoaderComponent></InfiniteLoaderComponent>)
      }
    </>
  );
}

export default StoreItemComponent;